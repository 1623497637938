import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";
import ReactGrid from "../../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ToastAlert from "../../OtherComponent/ToastContainer/ToastAlert";
import axios from "axios";
import Months from "./Months";

const AppointmentRequestList = () => {
  const dispatchvalue = useDispatch();
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  const UserData = useSelector((state) => state.userRecord?.UserData);
  // const userRecord = useSelector((state) => state.userRecord?.UserData);
  const toast = useSelector((state) => state.userRecord?.toast);
  const [SelectedLocations, setSelectedLocations] = useState(
    UserData?.location
  );
  const prevSelectedTimeRef = useRef();

  const formatLabel = (label) => {
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    } else {
      return label;
    }
  };
  // Function to format date as MM/DD/YYYY
  const formatDate = (date) => {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [AppointmentRequestList, setAppointmentRequestList] = useState({
    AppointmentID: "",
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    PhoneNumber: "",
    Email: "",
    Specialization: "",
    DoctorName: "",
    RequestDate: "",
    RequestTime: "",
    AppointmentType: "",
    VisitPurpose: "",
    Status: "ACTIVE",
  });

  const [AppointmentID, setAppointmentID] = useState(null);
  const [AppointmentRequestData, setAppointmentRequestData] = useState([]);
  const [DoctorNameData, setDoctorNameData] = useState([]);
  const [SpecializationData, setSpecializationData] = useState([]);
  const [AppointmentRequestGet, setAppointmentRequestGet] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setfilteredRows] = useState([]); // State to hold the data
  const [FilterbyPatientId, setFilterbyPatientId] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedTime, setSelectedTime] = useState("");
  const [TodayDate, setTodayDate] = useState(formatDate(new Date()));
  const [selectedDate, setselectedDate] = useState(TodayDate); // Set initial state to today's date
  const [selectedDoctor, setSelectedDoctor] = useState("");

  const [DoctorDayData, setDoctorDayData] = useState([]);

  const [openModal2, setOpenModal2] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  console.log("Aptieeeeee", FilterbyPatientId);
  console.log("appppoooinnntttt", AppointmentRequestList);
  // console.log("AppointmentRequestData", AppointmentRequestData);
  console.log("selectedTimeeeeee", selectedTime);
  console.log("selectedDateeee", selectedDate);
  console.log("SelectedLocationsssss", SelectedLocations);
  console.log("Userdataaaa", UserData?.location);
  console.log("DoctorDayData", DoctorDayData);
  console.log("selectedDoctor", selectedDoctor);
  console.log("TodayDateee", TodayDate);

  const HandleOnchange = (e) => {
    const { name, value, pattern } = e.target;
    const formattedValue = ["FirstName", "MiddleName", "LastName"].includes(
      name
    )
      ? `${value.charAt(0).toUpperCase()}${value.slice(1)}`
      : value;

    if (name === "DOB") {
      let newDate = new Date();

      let oldDate = new Date(value);
      let age = differenceInYears(newDate, oldDate);
      setAppointmentRequestList((prevFormData) => ({
        ...prevFormData,
        [name]: formattedValue,
        Age: age,
      }));
    } else if (name === "PhoneNumber") {
      if (formattedValue.includes("|")) {
        const convert = formattedValue.split(" | ");

        if (convert.length <= 10) {
          setAppointmentRequestList((prev) => ({
            ...prev,
            [name]: convert[1].trim(),
            // PatientId: convert[0].trim(),
            FirstName: convert[0].trim(),
          }));
        }
      } else {
        if (formattedValue.length <= 10) {
          setAppointmentRequestList((prev) => ({
            ...prev,
            [name]: formattedValue,
          }));
        }
      }
    } else if (name === "FirstName") {
      if (formattedValue.includes("|")) {
        const convert = formattedValue.split(" | ");

        setAppointmentRequestList((prev) => ({
          ...prev,
          [name]: convert[0].trim(),
          // PatientId: convert[0].trim(),
          PhoneNumber: convert[1].trim(),
        }));
      } else {
        setAppointmentRequestList((prev) => ({
          ...prev,
          [name]: formattedValue,
        }));
      }
    } else if (name === "RequestTime") {
      setAppointmentRequestList((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
      setSelectedTime(formattedValue);
    } else if (name === "RequestDate") {
      setAppointmentRequestList((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
      setselectedDate(formattedValue);
    } else if (name === "DoctorName") {
      setAppointmentRequestList((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
      setSelectedDoctor(formattedValue);
    } else {
      setAppointmentRequestList((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }
    const validateField = (value, pattern) => {
      if (!value) {
        return "Required";
      }
      if (pattern && !new RegExp(pattern).test(value)) {
        return "Invalid";
      } else {
        return "Valid";
      }
    };

    const error = validateField(value, pattern);
    console.log(error, "error");

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  useEffect(() => {
    const today = formatDate(new Date());
    console.log("TodayDatee", today);

    if (!AppointmentRequestList.RequestDate) {
      setAppointmentRequestList((prev) => ({
        ...prev,
        RequestDate: today,
      }));
      // setselectedDate(today); // Set selectedDate to today's date
      console.log("RequestDate was empty, setting to today:", today);
    }

    setTodayDate(today);
  }, [AppointmentRequestList.RequestDate]);

  useEffect(() => {
    const postdata = {
      PhoneNumber: AppointmentRequestList.PhoneNumber,
      FirstName: AppointmentRequestList.FirstName,
    };
    axios
      .get(`${UrlLink}Frontoffice/Filter_Patient_by_Multiple_Criteria`, {
        params: postdata,
      })
      .then((res) => {
        const data = res.data;
        setFilterbyPatientId(data);
        axios
          .get(`${UrlLink}Frontoffice/get_patient_visit_details`, {
            params: postdata,
          })
          .then((res) => {
            const visit = res.data?.VisitPurpose;
            setAppointmentRequestList((prev) => ({
              ...prev,
              VisitPurpose: visit,
            }));
            if (UserData && UserData.location) {
              setSelectedLocations(UserData.location);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    UrlLink,
    AppointmentRequestList.PhoneNumber,
    AppointmentRequestList.FirstName,
  ]);

  useEffect(() => {
    if (UserData && UserData.location && selectedDoctor && selectedDate) {
      axios
        .get(`${UrlLink}Frontoffice/calender_modal_display_data_by_day`, {
          params: {
            DoctorId: selectedDoctor,
            LocationId: UserData.location,
            Date: selectedDate,
          },
        })
        .then((response) => {
          const res = response.data;
          setDoctorDayData(res);
        })
        .catch((err) => {
          console.error("Error fetching filtered data:", err);
        });
    }
  }, [UrlLink, UserData.location, selectedDate, selectedDoctor]);

  useEffect(() => {
    let doctor_schedule = DoctorDayData?.schedule?.[0];
    const prevSelectedTime = prevSelectedTimeRef.current;

    // Update the previous selected time reference
    prevSelectedTimeRef.current = selectedTime;

    // If selectedTime hasn't changed, do nothing
    if (prevSelectedTime === selectedTime) {
      console.log("time is not changes");
    }
    if (
      doctor_schedule?.working === "yes" &&
      doctor_schedule?.shift === "Single" &&
      selectedTime
    ) {
      const startTime = doctor_schedule.starting_time;
      const endTime = doctor_schedule.ending_time;

      // Convert times to Date objects
      const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
      const endTimeDate = new Date(`1970-01-01T${endTime}Z`);
      const selectedTimeDate = new Date(`1970-01-01T${selectedTime}Z`); // Assuming selectedTime is in "HH:mm:ss" format

      // Check if selectedTime is within the range
      if (
        selectedTimeDate >= startTimeDate &&
        selectedTimeDate <= endTimeDate
      ) {
        const tdata = {
          message: `The Doctor is Available in the Selected Requested Time`,
          type: "success",
        };
        dispatchvalue({ type: "toast", value: tdata });
      } else {
        const tdata = {
          message: `The Doctor is not Available in the Selected Requested Time, Available in ${startTime} to ${endTime}`,
          type: "warn",
        };
        dispatchvalue({ type: "toast", value: tdata });
      }
    } else if (
      doctor_schedule?.working === "yes" &&
      doctor_schedule?.shift === "Double" &&
      selectedTime
    ) {
      const startTime_f = doctor_schedule.starting_time_f;
      const endTime_f = doctor_schedule.ending_time_f;
      const startTime_a = doctor_schedule.starting_time_a;
      const endTime_a = doctor_schedule.ending_time_a;

      // Convert times to Date objects
      const startTimeDate_f = new Date(`1970-01-01T${startTime_f}Z`);
      const endTimeDate_f = new Date(`1970-01-01T${endTime_f}Z`);
      const startTimeDate_a = new Date(`1970-01-01T${startTime_a}Z`);
      const endTimeDate_a = new Date(`1970-01-01T${endTime_a}Z`);
      const selectedTimeDate = new Date(`1970-01-01T${selectedTime}Z`); // Assuming selectedTime is in "HH:mm:ss" format
      if (
        (selectedTimeDate >= startTimeDate_f &&
          selectedTimeDate <= endTimeDate_f) ||
        (selectedTimeDate >= startTimeDate_a &&
          selectedTimeDate <= endTimeDate_a)
      ) {
        const tdata = {
          message: `The Doctor is Available in the Selected Requested Time`,
          type: "success",
        };
        dispatchvalue({ type: "toast", value: tdata });
      } else {
        const tdata = {
          message: `The Doctor is not Available in the Selected Requested Time, Available in FN : ${startTime_f} to ${endTime_f} or AN  ${startTime_a} to ${endTime_a}`,
          type: "warn",
        };
        dispatchvalue({ type: "toast", value: tdata });
      }
    } else if (doctor_schedule?.working === "no") {
      const tdata = {
        message: `The Doctor is not Available in the Selected Date`,
        type: "warn",
      };
      dispatchvalue({ type: "toast", value: tdata });
    }
  }, [DoctorDayData, selectedTime, dispatchvalue]);

  const AppointmentRequestColumns = [
    {
      key: "id",
      name: "Appointment ID",
      frozen: true,
    },
    {
      key: "FirstName",
      name: "First Name",
      frozen: true,
    },
    {
      key: "MiddleName",
      name: "Middle Name",
    },
    {
      key: "LastName",
      name: "Last Name",
    },
    {
      key: "PhoneNumber",
      name: "Phone Number",
      frozen: true,
    },
    {
      key: "Email",
      name: "Email",
    },
    {
      key: "RequestDate",
      name: "Request Date",
    },
    {
      key: "RequestTime",
      name: "Request Time",
    },
    {
      key: "AppointmentType",
      name: "Appointment Type",
    },
    {
      key: "VisitPurpose",
      name: "Appointment Purpose",
    },
    {
      key: "SpecializationName",
      name: "Specialization",
    },
    {
      key: "DoctorName",
      name: "Doctor Name",
    },
    {
      key: "Action",
      name: "Action",
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleeditAppointmentRequest(params.row)}
          >
            <EditIcon className="check_box_clrr_cancell" />
          </Button>
        </>
      ),
    },
    {
      key: "Cancel",
      name: "Cancel",
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleCancelAppointmentRequest(params.row)}
          >
            <CancelIcon className="check_box_clrr_cancell" />
          </Button>
        </>
      ),
    },
  ];

  const handleeditAppointmentRequest = (params) => {
    const { id, DoctorID, SpecializationId, ...rest } = params;

    setAppointmentRequestList((prev) => ({
      ...prev,
      AppointmentID: id,
      DoctorName: DoctorID,
      Specialization: SpecializationId, // Ensure this is populated with the Specialization ID or Name correctly
      Title: rest.Title,
      FirstName: rest.FirstName,
      MiddleName: rest.MiddleName,
      LastName: rest.LastName,
      PhoneNumber: rest.PhoneNumber,
      Email: rest.Email,
      RequestDate: rest.RequestDate,
      AppointmentType: rest.AppointmentType,
      RequestTime: rest.RequestTime,
      VisitPurpose: rest.VisitPurpose,
      Status: rest.Status,
    }));
    setSelectedDoctor(DoctorID);
  };
  const handleCancelAppointmentRequest = (params) => {
    const { id } = params;
    const updatedAppRequest = {
      AppointmentID: id,
    };
    axios
      .post(
        `${UrlLink}Frontoffice/Appointment_Request_List_Delete_Links`,
        updatedAppRequest
      )
      .then((response) => {
        const resData = response.data;
        const mess = Object.values(resData)[0];
        const typp = Object.keys(resData)[0];
        console.log("Appointment request deleted successfully:", response.data);
        const tdata = {
          message: mess,
          type: typp,
        };
        dispatchvalue({ type: "toast", value: tdata });
        setAppointmentRequestData((prev) => prev.filter((f) => f.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAppointmentRequestSubmit = () => {
    const exist = Object.keys(AppointmentRequestList)
      .filter((p) => p !== "AppointmentID", "")
      .filter((p) => !AppointmentRequestList[p]);
    if (exist.length === 0) {
      axios
        .post(
          `${UrlLink}Frontoffice/Appointment_Request_List_Link`,
          AppointmentRequestList
        )
        .then((response) => {
          const resData = response.data;
          const mess = Object.values(resData)[0];
          const typp = Object.keys(resData)[0];
          console.log(
            "Appointment request submitted successfully:",
            response.data
          );
          const tdata = {
            message: mess,
            type: typp,
          };

          dispatchvalue({ type: "toast", value: tdata });
          setAppointmentRequestGet((prev) => !prev);
          setAppointmentRequestList({
            AppointmentID: "",
            Title: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            PhoneNumber: "",
            Email: "",
            Specialization: "",
            DoctorName: "",
            RequestDate: "",
            RequestTime: "",
            AppointmentType: "",
            VisitPurpose: "",
            Status: "ACTIVE",
          });
          setselectedDate("");
          setSelectedTime("");
          setErrors({});
          fetchAppointmentIDCount();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const tdata = {
        message: `Please provide ${exist.join(",")}.`,
        type: "warn",
      };
      dispatchvalue({ type: "toast", value: tdata });
    }
  };

  useEffect(() => {
    axios
      .get(`${UrlLink}Frontoffice/Appointment_Request_List_Link`)
      .then((res) => {
        const ress = res.data;
        console.log("ressss", ress);

        setAppointmentRequestData(ress);
        // setselectedDate(ress?.[0]?.RequestDate);
        // setSelectedDoctor(ress?.[0]?.DoctorID);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [AppointmentRequestGet, UrlLink]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [specializationResponse] = await Promise.all([
          axios.get(`${UrlLink}Masters/Speciality_Detials_link`),
        ]);
        setSpecializationData(specializationResponse.data);
        console.log(specializationResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [UrlLink]);

  useEffect(() => {
    if (AppointmentRequestList?.Specialization && UrlLink) {
      axios
        .get(
          `${UrlLink}Masters/get_Doctor_by_Speciality_Detials?Speciality=${AppointmentRequestList.Specialization}`
        )
        .then((reponse) => {
          let data = reponse.data;
          setDoctorNameData(data);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [UrlLink, AppointmentRequestList.Specialization]);
  useEffect(() => {
    if (AppointmentRequestList?.Specialization && UrlLink) {
      axios
        .get(
          `${UrlLink}Masters/get_Doctor_by_Speciality_Detials?Speciality=${AppointmentRequestList.Specialization}`
        )
        .then((reponse) => {
          let data = reponse.data;
          setDoctorNameData(data);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [UrlLink, AppointmentRequestList.Specialization]);

  const fetchAppointmentIDCount = useCallback(() => {
    axios
      .get(`${UrlLink}Frontoffice/appointment_request_count_today`)
      .then((response) => {
        const count = response.data.count;
        setAppointmentID(count);
      })
      .catch((error) => {
        console.error("Error fetching appointment ID count:", error);
      });
  }, [UrlLink]);
  useEffect(() => {
    fetchAppointmentIDCount();
  }, [fetchAppointmentIDCount, AppointmentID]);

  useEffect(() => {
    const filteredData =
      AppointmentRequestData &&
      AppointmentRequestData.filter((row) => {
        const lowerCaseFirstName = row.FirstName.toLowerCase();
        const lowerCasePhoneNo = row.PhoneNumber.toString().toLowerCase();
        const lowerCaseQuery = searchQuery.toLowerCase();

        // Check if searchQuery matches either FirstName or PhoneNumber
        const matchesFirstName =
          lowerCaseFirstName && lowerCaseFirstName.includes(lowerCaseQuery);
        const matchesPhoneNo =
          lowerCasePhoneNo && lowerCasePhoneNo.includes(lowerCaseQuery);

        // Return true if either FirstName or PhoneNumber matches searchQuery
        return matchesFirstName || matchesPhoneNo;
      });

    setfilteredRows(filteredData);
  }, [searchQuery, AppointmentRequestData]);

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
    setOpenModal2(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div className="Main_container_app">
        <h3 style={{ display: "flex", justifyContent: "space-between" }}>
          Appointment Request
          <Button className="cell_btn" onClick={() => openModal("calendar")}>
            <CalendarMonthIcon />
          </Button>
        </h3>
        <div className="RegisFormcon" id="RegisFormcon_11">
          {Object.keys(AppointmentRequestList)
            .filter(
              (fields) =>
                fields !== "AppointmentID" &&
                fields !== "DoctorID" &&
                fields !== "Status"
            )
            .map((field, index) => (
              <div className="RegisForm_1" key={index}>
                <label htmlFor={`${field}_${index}`}>
                  {formatLabel(field)}
                  <span>:</span>
                </label>
                {[
                  "Title",
                  "AppointmentType",
                  "Specialization",
                  "DoctorName",
                ].includes(field) ? (
                  <select
                    id={`${field}_${index}`}
                    name={field}
                    value={AppointmentRequestList[field]}
                    onChange={HandleOnchange}
                  >
                    <option value="">Select</option>
                    {field === "Title" &&
                      ["Ms", "Mr", "Mrs", "Others"].map((row, indx) => (
                        <option key={indx} value={row}>
                          {row}
                        </option>
                      ))}
                    {field === "AppointmentType" &&
                      ["Call", "Walk In"].map((row, indx) => (
                        <option key={indx} value={row}>
                          {row}
                        </option>
                      ))}
                    {field === "VisitPurpose" &&
                      ["NewConsultation", "FollowUp"].map((row, indx) => (
                        <option key={indx} value={row}>
                          {row}
                        </option>
                      ))}
                    {field === "Specialization" &&
                      SpecializationData.filter(
                        (p) => p.Status === "Active"
                      ).map((p, indx) => (
                        <option key={indx} value={p.id}>
                          {p.SpecialityName}
                        </option>
                      ))}
                    {field === "DoctorName" &&
                      Array.isArray(DoctorNameData) &&
                      DoctorNameData.map((p, indx) => (
                        <option key={indx} value={p.id}>
                          {p.ShortName}
                        </option>
                      ))}
                  </select>
                ) : [
                    "FirstName",
                    "MiddleName",
                    "LastName",
                    "PhoneNumber",
                    "VisitPurpose",
                    "Email",
                  ].includes(field) ? (
                  <div className="Search_patient_icons">
                    <input
                      id={`${field}_${index}`}
                      type={"text"}
                      list={`${field}_iddd`}
                      autoComplete="off"
                      name={field}
                      pattern={
                        field === "Email"
                          ? "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
                          : field === "PhoneNumber"
                          ? "\\d{10}"
                          : "[A-Za-z]+"
                      }
                      className={
                        errors[field] === "Invalid"
                          ? "invalid"
                          : errors[field] === "Valid"
                          ? "valid"
                          : ""
                      }
                      onKeyDown={(e) =>
                        field === "PhoneNumber" &&
                        ![
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                          "Tab",
                        ].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={AppointmentRequestList[field]}
                      onChange={HandleOnchange}
                    />
                    <datalist id={`${field}_iddd`}>
                      {field === "PhoneNumber" &&
                        FilterbyPatientId.map((row, indx) => (
                          <option
                            key={indx}
                            value={`${row.FirstName} | ${row.PhoneNo}`}
                          />
                        ))}
                      {field === "FirstName" &&
                        FilterbyPatientId.map((row, indx) => (
                          <option
                            key={indx}
                            value={` ${row.FirstName} | ${row.PhoneNo}`}
                          />
                        ))}
                    </datalist>
                  </div>
                ) : (
                  <input
                    id={`${field}_${index}`}
                    autoComplete="off"
                    type={field === "RequestDate" ? "date" : "time"}
                    name={field}
                    value={AppointmentRequestList[field]}
                    onChange={HandleOnchange}
                  />
                )}
              </div>
            ))}
        </div>
        <div className="Main_container_Btn">
          <button onClick={handleAppointmentRequestSubmit}>
            {AppointmentRequestList.AppointmentID ? "Update" : "Add"}
          </button>
        </div>
        <h3>Appointment Request List</h3>
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="input">
              Search by<span>:</span>
            </label>
            <input
              type="text"
              id="SearchQuery"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Enter First Name or Phone Number"
            />
          </div>
        </div>

        {filteredRows.length >= 0 && (
          <ReactGrid
            columns={AppointmentRequestColumns}
            RowData={filteredRows}
          />
        )}
      </div>
      {openModal2 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal2(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <Months />
          </div>
        </div>
      )}
      <ToastAlert Message={toast.message} Type={toast.type} />
    </>
  );
};

export default AppointmentRequestList;
