import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import ModelContainer from '../../OtherComponent/ModelContainer/ModelContainer';

const QuickGoodsRecieptNote = () => {



    const dispatchvalue = useDispatch();
    const navigate = useNavigate();
    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const [GrnSupplierDetials, setGrnSupplierDetials] = useState({
        IsOldGRN: false,
        GRNDate: '',
        SupplierId: '',
        SupplierName: '',
        SupplierBillNumber: '',
        SupplierBillDate: '',
        SupplierBillDueDate: '',
        SupplierBillAmount: '',
        StoreLocation: '',
        ReceivingPersonName: '',
        FileAttachment: null

    })
    const [GrnItemDetials, setGrnItemDetials] = useState({
        ProductCategory: "",
        ItemId: '',
        ItemName: '',
        SubCategory: "",

        // ----
        TaxType: '',
        ReceivedQty: '',
        ReceivedQty: '',


    })
    const [StoreLocationData, setStoreLocationData] = useState([]);

    const currentDate = format(new Date(), 'yyyy-MM-dd');

    useEffect(() => {
        setGrnSupplierDetials((prev) => ({
            ...prev,
            GRNDate: currentDate,
            SupplierBillDate: currentDate
        }))
    }, [GrnSupplierDetials.IsOldGRN])

    const formatLabel = (label) => {

        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
        } else {
            return label;
        }
    };

    const HandeleOnchange = (e) => {
        const { name, value } = e.target
        setGrnSupplierDetials((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const HandeleOnchangeItemDetials = (e) => {
        const { name, value } = e.target
        setGrnItemDetials((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const handlefileOnchange = (e) => {

        const { files } = e.target


        if (files && files.length > 0) {
            let formattedValue = files[0];

            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            const maxSize = 5 * 1024 * 1024; // Example max size of 5MB
            if (!allowedTypes.includes(formattedValue.type) || formattedValue.type === '') {

                const tdata = {
                    message: 'Invalid file type. Please upload a PDF, JPEG, or PNG file.',
                    type: 'warn'
                };
                dispatchvalue({ type: 'toast', value: tdata });

            } else {
                if (formattedValue.size > maxSize) {
                    const tdata = {
                        message: 'File size exceeds the limit of 5MB.',
                        type: 'warn'
                    };
                    dispatchvalue({ type: 'toast', value: tdata });

                } else {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setGrnSupplierDetials((prev) => ({
                            ...prev,
                            FileAttachment: reader.result
                        }))

                    };
                    reader.readAsDataURL(formattedValue);




                }
            }

        } else {
            const tdata = {
                message: 'No file selected. Please choose a file to upload.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });
        }

    }



    const Selectedfileview = (fileval) => {
        console.log('fileval', fileval);

        if (fileval) {
            let tdata = {
                Isopen: false,
                content: null,
                type: 'image/jpg'
            };
            if (['data:image/jpeg;base64', 'data:image/jpg;base64'].includes(fileval?.split(',')[0])) {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'image/jpeg'
                };
            } else if (fileval?.split(',')[0] === 'data:image/png;base64') {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'image/png'
                };
            } else if (fileval?.split(',')[0] === 'data:application/pdf;base64') {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'application/pdf'
                };
            }

            dispatchvalue({ type: 'modelcon', value: tdata });
        } else {
            const tdata = {
                message: 'There is no file to view.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });
        }
    }

    useEffect(() => {
        if (userRecord?.location) {
            axios.get(`${UrlLink}/Masters/Inventory_Master_Detials_link?SearchLocation=${userRecord?.location}&forquickgrn=${false}`)
                .then((res) => {
                    const data = res.data
                    setStoreLocationData(Array.isArray(data) ? data : [])
                })
                .catch((err) => {
                    console.log(err);

                })

        }
    }, [UrlLink, userRecord?.location])

    return (
        <>
            <div className="Main_container_app">
                <h3>Quick Goods Receipt Note</h3>
                <br />
                <div className="RegisFormcon_1">
                    {
                        Object.keys(GrnSupplierDetials).map((field, Index) => {
                            return (
                                <div className="RegisForm_1" key={Index + 'key'}>

                                    <label htmlFor={field}>
                                        {
                                            field === 'GRNDate' ?
                                                'GRN Date' : formatLabel(field)
                                        }
                                        <span>:</span>
                                    </label>


                                    {
                                        field === 'GRNLocation' || field === 'StoreLocation' ?

                                            <select
                                                id={field}
                                                name={field}
                                                value={GrnSupplierDetials[field]}
                                                onChange={HandeleOnchange}

                                            >
                                                <option value=''>Select</option>
                                                {
                                                    StoreLocationData.map((ele, ind) => (
                                                        <option key={ind + 'key'} value={ele.id} >{ele.StoreName}</option>
                                                    ))
                                                }
                                            </select>
                                            : field === 'IsOldGRN' ?
                                                (<div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', width: '150px' }}>
                                                    <label style={{ width: 'auto' }} htmlFor={`${field}_yes`}>
                                                        <input
                                                            required
                                                            id={`${field}_yes`}
                                                            type="radio"
                                                            name={field}
                                                            style={{ width: '15px' }}
                                                            checked={GrnSupplierDetials[field]}
                                                            onChange={(e) => {
                                                                setGrnSupplierDetials(prev => ({
                                                                    ...prev,
                                                                    [field]: true
                                                                }))
                                                            }}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label style={{ width: 'auto' }} htmlFor={`${field}_No`}>
                                                        <input
                                                            required
                                                            id={`${field}_No`}
                                                            type="radio"
                                                            name={field}
                                                            style={{ width: '15px' }}
                                                            checked={!GrnSupplierDetials[field]}
                                                            onChange={(e) => {
                                                                setGrnSupplierDetials(prev => ({
                                                                    ...prev,
                                                                    [field]: false
                                                                }))
                                                            }}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                                ) : field === 'FileAttachment' ?
                                                    <>
                                                        <input
                                                            type='file'
                                                            name={field}
                                                            accept='image/jpeg,image/png,application/pdf'
                                                            required
                                                            id={`${field}_Grn`}
                                                            autoComplete='off'
                                                            onChange={handlefileOnchange}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <div style={{ width: '150px', display: 'flex', justifyContent: 'space-around' }}>
                                                            <label
                                                                htmlFor={`${field}_Grn`}
                                                                className="RegisterForm_1_btns choose_file_update"
                                                            >
                                                                Choose File
                                                            </label>
                                                            <button className='fileviewbtn'
                                                                onClick={() => Selectedfileview(GrnSupplierDetials.FileAttachment)}
                                                            >view</button>

                                                        </div>
                                                    </>
                                                    :

                                                    <input
                                                        type={['GRNDate', 'SupplierBillDate', 'SupplierBillDueDate'].includes(field) ? 'date' : 'text'}
                                                        id={field}
                                                        name={field}
                                                        value={GrnSupplierDetials[field]}
                                                        onChange={HandeleOnchange}
                                                        min={field === 'SupplierBillDueDate' ? GrnSupplierDetials.SupplierBillDate : ''}
                                                        max={['SupplierBillDate', 'GRNDate'].includes(field) ? currentDate : ''}
                                                        disabled={
                                                            ['SupplierBillDueDate', 'GRNDate', 'SupplierCode', 'SupplierName'].filter(f => GrnSupplierDetials.IsOldGRN ? !['GRNDate', 'SupplierBillDueDate'].includes(f) : f).includes(field)
                                                        }
                                                    />
                                    }
                                </div>

                            )
                        })
                    }
                </div>
                <br />
                <div className="common_center_tag">
                    <span>Product Entry Details</span>
                </div>
                <br />
                <div className="RegisFormcon_1">
                    {
                        Object.keys(GrnItemDetials).map((field, Index) => (
                            <div className="RegisForm_1" key={Index + 'key'}>
                                <label htmlFor={field}>
                                    {
                                        formatLabel(field)
                                    }
                                    <span>:</span>
                                </label>
                                {
                                    ['TaxType'].includes(field) ?

                                        <select
                                            id={field}
                                            name={field}
                                            value={GrnItemDetials[field]}
                                            onChange={HandeleOnchangeItemDetials}

                                        >
                                            <option value=''>Select</option>
                                            {field === 'TaxType' &&
                                                ['GST','IGST'].map((ele, ind) => (
                                                    <option key={ind + 'key'} value={ele} >{ele}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <input
                                            type={'text'}
                                            id={field}
                                            name={field}
                                            value={GrnItemDetials[field]}
                                            onChange={HandeleOnchangeItemDetials}

                                        />
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <ToastAlert Message={toast.message} Type={toast.type} />

            <ModelContainer />
        </>
    )
}

export default QuickGoodsRecieptNote