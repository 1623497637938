import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import DocwithEarth from "../../Assets/DocwithEarth.jpg";
import { FaUser, FaLock, FaEyeSlash } from "react-icons/fa";
import { FaEye, FaLocationDot } from "react-icons/fa6";
import './LoginPage.css';

const LoginPage = () => {


  const [ClinicDetails, setClinicDetails] = useState(null);
  const [UserValues, setUserValues] = useState({
    UserName: '',
    Password: '',
    Location: '',
  });
  const [Locationoptions, setLocationOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const UrlLink = useSelector(state => state.userRecord?.UrlLink)
  const navigate = useNavigate();
  const toast = useSelector(state => state.userRecord?.toast);
  const dispatchvalue = useDispatch();

  const handlechange = (e) => {
    const { name, value } = e.target;
    setUserValues((prev) => ({
      ...prev,
      [name]: value.trim()
    }))
  }

  useEffect(() => {

    axios
      .get(`${UrlLink}Masters/Hospital_Detials_link`)
      .then((response) => {
        if (response.data) {
          const { hospitalName, hospitalLogo, HospitalId } = response.data;
          if (hospitalName && hospitalLogo && HospitalId) {
            const datass = {
              id: HospitalId,
              Cname: hospitalName,
              Clogo: `data:image/*;base64,${hospitalLogo}`
            };
            console.log(datass);
            setClinicDetails(datass)
          } else {
            console.log('Data is null or incomplete');
            setClinicDetails(null)
          }
        } else {
          setClinicDetails(null)
        }
      })
      .catch((error) => console.error("Error fetching data", error));
  }, [UrlLink]);

  useEffect(() => {
    if (UserValues?.UserName.trim() !== '') {
      let timer = setTimeout(() => {
        axios.get(`${UrlLink}Masters/get_Location_data_for_login?username=${UserValues?.UserName}`)
          .then(response => {
            const data = response.data;
            console.log(data, 'API response data'); // Log the response data
            if (Array.isArray(data)) {
              if(data.length===1){
                console.log('data[0]',data[0]);
                const locat =data[0]
                setUserValues((prev)=>({
                  ...prev,
                  Location:data[0].id
                }))
              }
              setLocationOptions(data);
            } else if (data.message) {
              const tdata = {
                message: data.message,
                type: 'warn'
              }
              dispatchvalue({ type: 'toast', value: tdata });

              setLocationOptions([]); // Reset Location options
            }
          })
          .catch(error => {
            console.error("Error fetching Location options:", error);
            setLocationOptions([]); // Reset Location options in case of error
          });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [UserValues?.UserName, UrlLink,dispatchvalue]);


  const handleLoginFormSubmit = () => {
    
    const exist = Object.keys(UserValues).filter(p => !UserValues[p])
    if (exist.length === 0) {
      console.log(UserValues);
      axios.post(`${UrlLink}Masters/login_logic`, UserValues)
        .then(response => {
          const { success, message, token } = response.data;
          if (success) {
            localStorage.setItem('Chrrtoken', token)
            
            const tdata = {
              message: `Login Successfull`,
              type: 'success'
            }
            dispatchvalue({ type: 'toast', value: tdata });

            setTimeout(() => {
              navigate('/Home/DashBoardDetails')
            }, 1000);

          } else {
            console.error(message);
          }
        })
        .catch(error => console.error("Login error:", error));
    } else {

      const tdata = {
        message: `Please provide ${exist.join(',')}.`,
        type: 'warn'
      }
      dispatchvalue({ type: 'toast', value: tdata });


    }




  };

  return (
    <>



      <div className="login-page">
        <img
          src={DocwithEarth}
          alt="Background"
          className="background-img_login"
        />
        <div className="box_login">
          <div className="form_login">
            <div className="login_logo_plo">
              {ClinicDetails?.Clogo &&

                <img
                  src={ClinicDetails?.Clogo}
                  alt={ClinicDetails?.Cname}
                  className="rounded-logo-img"
                />

              }

            </div>
            <div className="inputBox_login">
              <input
                type="text"
                id="UserName"
                name="UserName"
                onChange={handlechange}
                required
                autoFocus
                value={UserValues.UserName}
              />
              <span>   <FaUser className="input_oicon_8" />Username</span>
              <i></i>
            </div>
            <div className="inputBox_login">

              <input
                type={showPassword ? 'text' : 'password'}
                id="Password"
                name="Password"
                value={UserValues.Password}
                onChange={handlechange}
                required
              />
              <p className="toggle_password22_" onClick={() => setShowPassword((prev => !prev))}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </p>
              <span> <FaLock className="input_oicon_8" />Password</span>
              <i></i>
            </div>
            <div className="inputBox_login">
              <select
                id="Location"
                name="Location"
                value={UserValues.Location}
                onChange={handlechange}
                required
               
              >
                <option value=''>Select</option>
                {Locationoptions.map((loc,indx) => (
                  <option key={indx} value={loc.id}>
                    {loc.name}
                  </option>

                ))}



              </select>
              <span>  <FaLocationDot className="input_oicon_8" />Location</span>
              <i></i>
            </div>
            <div className="links">
              <p>Forgot Password</p>
              {/* <a href="#">Signup</a> */}
            </div>
            <div className="decjjwed8">
              <button type='submit' value="Login" onClick={handleLoginFormSubmit}>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastAlert Message={toast.message} Type={toast.type} />
    </>
  )
}

export default LoginPage;
