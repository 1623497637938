import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import Button from "@mui/material/Button";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModelContainer from '../../OtherComponent/ModelContainer/ModelContainer';
import ListIcon from '@mui/icons-material/List';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from 'react-router-dom';



const GoodsReceiptNoteList = () => {


    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];

    const dispatchvalue = useDispatch();

    const navigate = useNavigate();

    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);

    const[supplierArray,setsupplierArray]=useState([])
  
    const [LocationData, setLocationData] = useState([]);

    const[GRNOrderList,setGRNOrderList] = useState([])

    const [selectRoom, setselectRoom] = useState(false);

    const[Itemlist,setItemlist]=useState([])


    const [SerchOptions,setSerchOptions] =useState({
        StatusCheck:'',
        DateType:'',
        CurrentDate:currentDate,
        FromDate:'',
        ToDate:'',
        SupplierId:'',
        SupplierName:'',
        BillingLocation:'',
        deliveredLocation:'',
      })



      useEffect(()=>{

        axios.get(`${UrlLink}Masters/Supplier_Master_Link`)
        .then((res)=>{
            console.log('pppp',res.data);
            let Rdata=res.data
            if(Array.isArray(Rdata) && Rdata.length !==0){                
                setsupplierArray(Rdata)
            }else
            {
                setsupplierArray([])
            }
        })
        .catch((err)=>{
            console.log(err);
            
        })
    
    
        axios.get(`${UrlLink}Masters/Location_Detials_link`)
            .then((res) => {
                const ress = res.data
                setLocationData(ress)
            })
            .catch((err) => {
                console.log(err);
            })
    
    },[UrlLink])


      const Handeleonchange =(e)=>{

        const {name,value}=e.target
    
        if(name === 'SupplierId'){
    
        let Finddata=supplierArray.find((ele)=>ele.id === value)
        if(Finddata){
          setSerchOptions((prev)=>({
            ...prev,
            [name]:value,
            SupplierName:Finddata.SupplierName,
          }))
        }
        else{
          setSerchOptions((prev)=>({
            ...prev,
            [name]:value,
            SupplierName:'',
      
          }))
        }
        }
        if(name === 'SupplierName'){
    
          let Finddata=supplierArray.find((ele)=>ele.SupplierName === value)
          if(Finddata){
            setSerchOptions((prev)=>({
              ...prev,
              [name]:value,
              SupplierId:Finddata.id,
            }))
          }
          else{
            setSerchOptions((prev)=>({
              ...prev,
              [name]:value,
              SupplierId:'',
        
            }))
          }
          }
        else{
          setSerchOptions((prev)=>({
            ...prev,
            [name]:value
      
          }))
        }
    
    
      }


     

      const getGRNdata = useCallback(()=>{

        axios.get(`${UrlLink}Inventory/GRN_Report_Details`,{ params: SerchOptions })
        .then((res)=>{
          console.log('--ooo---',res.data);
          let Rdata=res.data
          if(Array.isArray(Rdata)){ 
            setGRNOrderList(Rdata)
          }
          else{
            setGRNOrderList([])
          }
    
        })
        .catch((err)=>{
          console.log(err);
          
        })
    
      },[UrlLink,SerchOptions])


      useEffect(()=>{
        getGRNdata()
      },[getGRNdata])

      const HandelViewdata = useCallback((fileval)=>{

        // console.log('uuu',fileval);

        if (fileval) {
            let tdata = {
                Isopen: false,
                content: null,
                type: 'image/jpg'
            };
            if (['data:image/jpeg;base64', 'data:image/jpg;base64'].includes(fileval?.split(',')[0])) {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'image/jpeg'
                };
            } else if (fileval?.split(',')[0] === 'data:image/png;base64') {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'image/png'
                };
            } else if (fileval?.split(',')[0] === 'data:application/pdf;base64') {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'application/pdf'
                };
            }

            console.log('tdata',tdata);
            
            dispatchvalue({ type: 'modelcon', value: tdata });
        } else {
            const tdata = {
                message: 'There is no file to view.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });
        }
        

    },[dispatchvalue])



    const ItemView =(row)=>{

        let Item=row.GRN_Items

        if(Item && Item.length !==0)
        {
            setselectRoom(true)        
            setItemlist(Item)
        }
        else{

            const tdata = {
                message: 'There is no data to view.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });

        }

    }


const HandelEditdata =(row)=>{

    dispatchvalue({ type:'GoodsReceiptNoteList', value: {}});
    // -----------------------------------------------
    dispatchvalue({ type:'GoodsReceiptNoteEdit', value: row});
    navigate('/Home/GoodsReceiptNote');
}

const GRNOrderListColumn =[

    {
        key:'id',
        name:'GRN Number',
        frozen: true
    },
    {
        key:'GRNDate',
        name:'GRN Date',
        frozen: true,
        renderCell: (params) => {
            const date = new Date(params.row.GRNDate);
            const formattedDate = date.toLocaleDateString('en-GB'); 
            return <>{formattedDate}</>;
          }
    },
    {
        key:'PONumber',
        name:'PO Number',
        frozen: true
    },
    {
        key:'SupplierCode',
        name:'Supplier Code',
        frozen: true
    },
    {
        key:'SupplierName',
        name:'SupplierName',
        frozen: true
    },
    {
        key:'SupplierBillfile',
        name:'Supplier Bill file',
        renderCell:(params)=>(
            <>
            <Button className="cell_btn" 
            onClick={()=>HandelViewdata(params.row.FileAttachment)}               
            >
            <VisibilityIcon className="check_box_clrr_cancell" />
            </Button>
            </>
        )
    },
    {
        key:'GRN_Items',
        name:'GRN Items List',
        renderCell:(params)=>(
            <>
            <Button className="cell_btn" 
            onClick={()=>ItemView(params.row)}  
            >
            <ListIcon className="check_box_clrr_cancell" />
            </Button>
            </>
        )
    },
    {
        key:'SupplierBillNumber',
        name:'Supplier Bill Number',
    },
    {
        key:'SupplierBillDate',
        name:'Supplier Bill Date',
        renderCell: (params) => {
            const date = new Date(params.row.SupplierBillDate);
            const formattedDate = date.toLocaleDateString('en-GB'); 
            return <>{formattedDate}</>;
        }
    },
    {
        key:'SupplierBillDueDate',
        name:'Supplier Bill Due Date',
        renderCell: (params) => {
            const date = new Date(params.row.SupplierBillDueDate);
            const formattedDate = date.toLocaleDateString('en-GB'); 
            return <>{formattedDate}</>;
        }
    },
    {
        key:'SupplierBillAmount',
        name:'Supplier Bill Amount',
    },
    {
        key:'Use_BillingLocation',
        name:'Billing Location',
    },
    {
        key:'Use_ShippingLocation',
        name:'Shipping Location',
    },
    {
        key:'ReceivingPersonName',
        name:'Receiving Person',
    },
    {
        key:'TaxableTotal',
        name:'Taxable Total',
    },
    {
        key:'TaxTotal',
        name:'Tax Total',
    },
    {
        key:'TotalAmount',
        name:'Total Amount',
    },
    {
        key:'TotalDiscountMethod',
        name:'Total Discount Method',
    },
    {
        key:'TotalDiscountType',
        name:'Total Discount Type',
    },
    {
        key:'TotalDiscount',
        name:'Total Discount',
    },
    {
        key:'FinalTaxableTotal',
        name:'Final Taxable Total',
    },
    {
        key:'FinalTaxTotal',
        name:'Final Tax Total',
    },
    {
        key:'FinalTotalAmount',
        name:'Final Total Amount',
    },
    {
        key:'RoundOff',
        name:'RoundOff',
    },
    {
        key:'NetAmount',
        name:'NetAmount',
    },
    {
        key:'GRN_Status',
        name:'Status',
    },
    {
        key:'Edit',
        name:'Edit',
        renderCell:(params)=>(
            <>
            {params.row.GRN_Status === 'Waiting For Approve'?
            <Button className="cell_btn" 
            onClick={()=>HandelEditdata(params.row)}               
            >
            <EditIcon className="check_box_clrr_cancell" />
            </Button> :
            <>No Action</>}
            </>
        )
    },
    {
        key:'Action',
        name:'Action',
        renderCell:(params)=>(
            <>
            {params.row.GRN_Status === 'Waiting For Approve'?
            <Button className="cell_btn" 
            //  onClick={()=>HandelApprovedata(params.row)}               
            >
            <CheckCircleOutlineIcon className="check_box_clrr_cancell" />
            </Button> :
            <Button className="cell_btn"            
            >
             No Action
            </Button>
            }
            </>
        )
    },
    
]



const ItemColumn=[
    {
        key:'id',
        name:'GRN Item Id',    
        frozen: true
    },
    {
        key:'ItemCode',
        name:'Item Code', 
        frozen: true
    },
    {
        key:'ItemName',
        name:'Item Name', 
        frozen: true
    },
    {
        key:'GenericName',
        name:'Generic Name',
    },
    {
        key:'CompanyName',
        name:'Company Name',
    },
    {
        key:'ProductCategories',
        name:'Product Categories',
    },
    {
        key:'SubCategory',
        name:'Sub Category',
    },
    {
        key:'Strength',
        name:'Strength',
    },
    {
        key:'UOM',
        name:'UOM',
    },
    {
        key:'HSNCode',
        name:'HSNCode',
    },
    {
        key:'PurchasePack',
        name:'Purchase Pack',
    },
    {
        key:'PackMRP',
        name:'Pack MRP',
    },
    {
        key:'PurchaseRatePerPackTaxable',
        name:'Taxable Purchase Rate',
    },
    {
        key:'TaxType',
        name:'Tax Type',
    },
    {
        key:'TaxPercentage',
        name:'Tax Percentage',
    },
    {
        key:'PurchaseRatePerPack',
        name:'Purchase Rate',
    },
    {
        key:'OrderQty',
        name:'Order Qty',
    },
    {
        key:'BillQty',
        name:'Bill Qty',
    },
    {
        key:'ReceivedQty',
        name:'Received Qty',
    },
    {
        key:'PendingQty',
        name:'Pending Qty',
    },
    {
        key:'TotalSellableQty',
        name:'Total Sellable Qty',
    },
    {
        key:'SellableQtyMRP',
        name:'Sellable Qty MRP',
    },
    {
        key:'TotalPackTaxableAmount',
        name:'Total Taxable Amount',
    },
    {
        key:'TotalTaxAmount',
        name:'Total TaxAmount',
    },
    {
        key:'TotalPackAmount',
        name:'Total Amount',
    },
    {
        key:'BatchNo',
        name:'Batch No',
    },
    {
        key:'ManufactureDate',
        name:'Manufacture Date',
        renderCell: (params) => {
            const date = new Date(params.row.ManufactureDate);
            const formattedDate = date.toLocaleDateString('en-GB'); 
            return <>{formattedDate}</>;
          }
    },
    {
        key:'ExpiryDate',
        name:'Expiry Date',
        renderCell: (params) => {
            const date = new Date(params.row.ExpiryDate);
            const formattedDate = date.toLocaleDateString('en-GB'); 
            return <>{formattedDate}</>;
          }
    },
    {
        key:'DiscountMethod',
        name:'Discount Method',
    },
    {
        key:'DiscountType',
        name:'Discount Type',
    },
    {
        key:'Discount',
        name:'Discount',
    },
    {
        key:'FinalTotalPackTaxableAmount',
        name:'Final Total Taxable Amount',
    },
    {
        key:'FinalTotalTaxAmount',
        name:'Final TotalTax Amount',
    },
    {
        key:'FinalTotalPackAmount',
        name:'Final Total Amount',
    },
    {
        key:'Action',
        name:'Action',

    },
    
    ]

  return (
    <>

        <div className="Main_container_app">
            <h3>Goods Receipt Note List</h3>
                <br/>
                <div className="RegisFormcon_1">
                    <div className="RegisForm_1">
                    <label>Status<span>:</span></label>

                    <select
                    name='StatusCheck'
                    value={SerchOptions.StatusCheck}
                    onChange={Handeleonchange}
                    >
                    <option value=''>Select</option>
                    <option value='Waiting For Approve'> Waiting For Approve </option>
                    <option value='Approved'> Approved </option>

                    </select>
                    </div>

                    <div className="RegisForm_1">
                    <label>Date Type<span>:</span></label>
                    <select
                        name='DateType'
                        value={SerchOptions.DateType}
                        onChange={Handeleonchange}
                        >
                        <option value=''>Select</option>
                        <option value='CurrentDate'>Current Date</option>
                        <option value='Customize'>Customize</option>
                        </select>
                    </div>

                    { SerchOptions.DateType === 'CurrentDate' ?
                    <div className="RegisForm_1">
                    <label>Current Date<span>:</span></label>
                    <input
                        type='date'
                        name='CurrentDate'
                        value={SerchOptions.CurrentDate}
                        onChange={Handeleonchange}
                        readOnly
                        />
                        
                    </div>
                    :
                    <>
                    <div className="RegisForm_1">
                    <label>From Date<span>:</span></label>
                    <input
                        type='date'
                        name='FromDate'
                        value={SerchOptions.FromDate}
                        onChange={Handeleonchange}
                        />
                        
                    </div>


                    <div className="RegisForm_1">
                    <label>To Date<span>:</span></label>
                    <input
                        type='date'
                        name='ToDate'
                        value={SerchOptions.ToDate}
                        onChange={Handeleonchange}
                        min={SerchOptions.FromDate}
                        />
                        
                    </div>
                    </>}

                    <div className="RegisForm_1">
                    <label>Supplier Id<span>:</span></label>
                    <input
                        type='text'
                        name='SupplierId'
                        value={SerchOptions.SupplierId}
                        onChange={Handeleonchange}
                        list='SupplierIdList'
                        />
                        <datalist id='SupplierIdList'>
                        {
                        supplierArray.map((ele,ind)=>(
                            <option key={ind+'key'} value={ele.id} ></option>
                        ))

                        }
                        </datalist>
                        
                    </div>


                    <div className="RegisForm_1">
                    <label>Supplier Name<span>:</span></label>
                    <input
                        type='text'
                        name='SupplierName'
                        value={SerchOptions.SupplierName}
                        onChange={Handeleonchange}
                        list='SupplierNameList'
                        />
                        <datalist id='SupplierNameList'>
                        {
                            supplierArray.map((ele,ind)=>(
                            <option key={ind+'key'} value={ele.SupplierName} ></option>
                            ))
                        }
                        </datalist>
                        
                    </div>
                    
                    <div className="RegisForm_1">
                    <label>Billing Location<span>:</span></label>
                    <select
                    id='BillingLocation'
                    name='BillingLocation'
                    value={SerchOptions.BillingLocation}
                    onChange={Handeleonchange}
                    >
                    <option value=''>Select</option>
                    {
                    
                    LocationData.map((ele,ind)=>(
                        <option key={ind+'key'} value={ele.id} >{ele.locationName}</option>
                    ))

                    }
                    </select>
                    </div>

                    <div className="RegisForm_1">
                    <label>delivered Location<span>:</span></label>
                    <select
                    id='deliveredLocation'
                    name='deliveredLocation'
                    value={SerchOptions.deliveredLocation}
                    onChange={Handeleonchange}
                    >
                    <option value=''>Select</option>
                    {
                    LocationData.map((ele,ind)=>(
                        <option key={ind+'key'} value={ele.id} >{ele.locationName}</option>
                    ))

                    }
                    </select>
                    </div>

                </div>

                <br/>

                <ReactGrid columns={GRNOrderListColumn} RowData={GRNOrderList} />
        </div>
        <ToastAlert Message={toast.message} Type={toast.type} />
        <ModelContainer/>

        {selectRoom && Itemlist.length !==0 &&(
            <div className="loader" onClick={() => setselectRoom(false)}>
            <div className="loader_register_roomshow"   onClick={(e) => e.stopPropagation()}>
            <br/>
            
            <div className="common_center_tag">
                <span>Item Details</span>
            </div>
            <br/>
            <br/>

                <ReactGrid columns={ItemColumn} RowData={Itemlist} />
            </div>
            </div>
            )}
    </>
  )
}

export default GoodsReceiptNoteList;
