
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGrid from "../../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import ToastAlert from "../../OtherComponent/ToastContainer/ToastAlert";
import "../../OP/TreatmentComponent.css";
import { FaTrash } from 'react-icons/fa';
import VisibilityIcon from '@mui/icons-material/Visibility';
const LabMaster = () => {
    const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const toast = useSelector((state) => state.userRecord?.toast);
    const dispatchvalue = useDispatch();
    const [TestName, setTestName] = useState({
        TestCode: "",
        TestName: "",
        Amount: "",
        Prev_Amount: "",
        Status: "Active",
    });

    const [IsLabTestNameGet, setIsLabTestNameGet] = useState(false);
    const [LabTestNames, setLabTestNames] = useState([]);

    const handleLabTestNameOnchange = (e) => {
        const { name, value } = e.target;
        setTestName((prev) => ({
            ...prev,
            [name]: value.toUpperCase().replace(/ +(?= )/g, ''), // Remove extra spaces but keep single spaces
        }));
    };

    const handleeditTeststatus = (params) => {
        const data = { ...params, TestCode: params.id, TestName: params.Test_Name, Prev_Amount: params.Prev_Amount, Amount: params.Amount, Status: params.Status === 'Active' ? 'Inactive' : 'Active' }
        axios.post(`${UrlLink}Masters/Lab_Test_Name_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                const tdata = {
                    message: mess,
                    type: typp,
                }
                dispatchvalue({ type: 'toast', value: tdata });
                setIsLabTestNameGet(prev => !prev)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const handleeditLabTestName = (params) => {
        const { id, ...rest } = params;
        setTestName({
            TestCode: id,
            TestName: params.Test_Name,
            ...rest,
        });
    };

    const handleLabTestNameSubmit = () => {
        if (TestName.TestName) {
            const data = {
                ...TestName,
                created_by: userRecord?.username || "",
                location: userRecord?.location || "",
            };
            console.log("data",data)
            axios.post(`${UrlLink}Masters/Lab_Test_Name_link`, data)
                .then((res) => {
                    const reste = res.data;
                    const typp = Object.keys(reste)[0];
                    const mess = Object.values(reste)[0];
                    const tdata = {
                        message: mess,
                        type: typp,
                    };

                    dispatchvalue({ type: "toast", value: tdata });
                    setIsLabTestNameGet((prev) => !prev);
                    setTestName({
                        TestCode: "",
                        TestName: "",
                        Prev_Amount: "",
                        Amount: "",
                        Status: "Active"
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const tdata = {
                message: "Please provide the TestName.",
                type: "warn",
            };
            dispatchvalue({ type: "toast", value: tdata });
        }
    };

    useEffect(() => {
        axios.get(`${UrlLink}Masters/Lab_Test_Name_link`)
            .then((response) => {
                setLabTestNames(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [IsLabTestNameGet, UrlLink]);

    // --------------------favorites-----------------

    const [activetestnames, setActivetestnames] = useState([]);
    console.log("activetestnames", activetestnames)

    const [FavouritesNames, setFavouritesNames] = useState({
        FavouritesCode: "",
        FavouritesName: "",
        TestName: "",
        SumOfAmount: "",
        Amount: "",
        Status: "Active"
    });

   
    useEffect(() => {
        const fetchTestNames = async () => {
            try {
                const response = await axios.get(`${UrlLink}Masters/Test_Names_link?Testgo=${FavouritesNames.TestName}`);
                setActivetestnames(response.data);
                console.log("Response Data:", response.data);
            } catch (err) {
                console.error("Error fetching test names:", err);
            }
        };
    
        fetchTestNames();
    }, [UrlLink, FavouritesNames.TestName]);
    

 
    const [FavouriteNamess, setFavouriteNamess] = useState([]);
    const [IsFavouritesGet, setIsFavouritesGet] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const handleFavouritesOnchange = (e) => {
        const { name, value } = e.target;
        setFavouritesNames((prevState) => ({
            ...prevState,
            [name]: value.toUpperCase().replace(/ +(?= )/g, ''),
        }));
    };

    const handleAddFavourite = () => {
        const selectedTest = activetestnames.find(test => test.Test_Name === FavouritesNames.TestName);
        console.log("selectedTest", selectedTest)
        setisclick(true);
      
        if (selectedTest) {
            // Check if the test is already in FavouriteNamess
            const isDuplicate = FavouriteNamess.some(favourite => favourite.TestCode === selectedTest.id);

            if (isDuplicate) {
                const tdata = {
                    message: `Test already added`,
                    type: "warn",
                };
                dispatchvalue({ type: "toast", value: tdata });
            } else {
                const newFavourite = {
                    TestCode: selectedTest.id,
                    TestName: selectedTest.Test_Name,
                    Amount: selectedTest.Amount,
                };

                setFavouriteNamess(prev => [...prev, newFavourite]);

                // Update SumOfAmount and Amount in FavouritesNames
                setFavouritesNames(prevState => ({
                    ...prevState,
                    TestName: "",
                    SumOfAmount: (parseFloat(prevState.SumOfAmount || 0) + parseFloat(newFavourite.Amount)).toFixed(2),
                    Amount: (parseFloat(prevState.Amount || 0) + parseFloat(newFavourite.Amount)).toFixed(2),
                }));

                const tdata = {
                    message: `Test added successfully`,
                    type: "success",
                };
                dispatchvalue({ type: "toast", value: tdata });
            }
        } else {
            const tdata = {
                message: `Invalid test name`,
                type: "warn",
            };
            dispatchvalue({ type: "toast", value: tdata });
        }

    };

    const handleRemoveFavourite = (row) => {
        console.log("params", row);

        // Filter out the item with matching TestCode
        const updatedFavouriteNamess = FavouriteNamess.filter(test => test.TestCode !== row.TestCode);

        // Calculate removedAmount and update SumOfAmount
        const removedItem = FavouriteNamess.find(test => test.TestCode === row.TestCode);
        const removedAmount = removedItem?.Amount || 0;
        const newSumOfAmount = parseFloat(FavouritesNames.SumOfAmount) - parseFloat(removedAmount);

        // Update state with filtered array and new SumOfAmount
        setFavouriteNamess(updatedFavouriteNamess);
        setFavouritesNames(prevState => ({
            ...prevState,
            SumOfAmount: newSumOfAmount.toFixed(2),
            Amount: newSumOfAmount.toFixed(2),
        }));

        // Dispatch success toast message
        const tdata = {
            message: `Test removed successfully`,
            type: "success",
        };
        dispatchvalue({ type: "toast", value: tdata });
    };


    const LabTestNameColumns = [
        {
            key: "id",
            name: "Test Code",
            frozen: true,
        },
        {
            key: "created_by",
            name: "Created By",
            frozen: true,
        },
        {
            key: "Test_Name",
            name: "Test Name",
        },
        {
            key: "Amount",
            name: "Amount",
            children: [
                {
                    key: "Prev_Amount",
                    name: "Prev Amount",
                },
                {
                    key: "Amount",
                    name: "Curr Amount",
                },
            ],
        },
        {
            key: "Status",
            name: "Status",
            renderCell: (params) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleeditTeststatus(params.row)}
                >
                    {params.row.Status}
                </Button>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <Button className="cell_btn" onClick={() => handleeditLabTestName(params.row)}>
                    <EditIcon className="check_box_clrr_cancell" />
                </Button>
            ),
        },
    ];

    const FavouritesColumns = [
        {
            key: "TestCode",
            name: "Test Code",
            frozen: true,
        },
        {
            key: "TestName",
            name: "Test Name",
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleRemoveFavourite(params.row)}
                >
                    <FaTrash className="check_box_clrr_cancell" />
                </Button>
            ),
        },
    ];
    console.log("sdf", FavouriteNamess)

    const handleFavouritesSubmit = () => {
        if (FavouritesNames.FavouritesName) {
            const data = {
                ...FavouritesNames,
                FavouriteNamess: FavouriteNamess,
                created_by: userRecord?.username || "",

            };
            console.log("data", data)
            axios.post(`${UrlLink}Masters/Favourite_TestNames_Details`, data)
                .then((res) => {
                    const reste = res.data;
                    const typp = Object.keys(reste)[0];
                    const mess = Object.values(reste)[0];
                    const tdata = {
                        message: mess,
                        type: typp,
                    };
                    dispatchvalue({ type: "toast", value: tdata });
                    setIsFavouritesGet((prev) => !prev);
                    setFavouritesNames({
                        FavouritesCode: "",
                        FavouritesName: "",
                        TestName: "",
                        SumOfAmount: "",
                        Amount: "",
                        Status: "Active"
                    });
                    setFavouriteNamess([]);

                    setJoy(false);
                    // setIsEditing(!isEditing);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else {
            const tdata = {
                message: "Please provide the FavouritesName .",
                type: "warn",
            };
            dispatchvalue({ type: "toast", value: tdata });
        }
    };
    const [special, setSpecial] = useState([]);
    useEffect(() => {
        axios.get(`${UrlLink}Masters/Favourite_TestNames_Details`)
            .then((response) => {
                console.log("testspecify", response.data);
                setSpecial(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [IsFavouritesGet, UrlLink]);



    const SpecialColumnns = [
        {
            key: "id",
            name: "S.No",
            frozen: true,
            width: 80,
        },
        {
            key: "FavouriteName",
            name: "Favourite Name",
        },

        {
            key: "Amount",
            name: "Amount",
            children: [
                {
                    key: "SumOfAmount",
                    name: "Sum Of Amount",
                },
                {
                    key: "Current_Amount",
                    name: "Curr Amount",
                },
                {
                    key: "Previous_Amount",
                    name: "Prev Amount",
                },
            ],
        },
        {
            key: "TestName",
            name: "View TestNames",

            renderCell: (params) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleviewFavouriteTestnames(params.row)}
                >
                    <VisibilityIcon className="check_box_clrr_cancell" />
                </Button>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleEditFavourites(params.row)}
                >
                    <EditIcon className="check_box_clrr_cancell" />
                </Button>
            ),
        },
        {
            key: "Status",
            name: "Status",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => handleeditFavouritesstatus(params.row)}
                    >
                        {params.row.Status}
                    </Button>
                </>
            ),
        },
    ];

    const handleEditFavourites = (params) => {
        console.log("params", params);
        setIsEditing(false);
        setisclick(true);
        setJoy(false);

        const { id, FavouriteName, Current_Amount, SumOfAmount, Status, TestName, ...rest } = params;
        const TestCodeNew = params.id;

        const TestRow = params.TestName;

        setFavouriteNamess(TestRow);
        setFavouritesNames((prev) => ({
            ...prev,
            FavouritesCode: TestCodeNew,
            FavouritesName: FavouriteName,
            SumOfAmount: SumOfAmount,
            Amount: Current_Amount,
            TestName:"",
            ...rest,
        }));
    };


    const handleeditFavouritesstatus = (params) => {
        const data = {
            ...params,
            FavouritesCode: params.id,
            FavouritesName: params.FavouriteName,
            SumOfAmount: params.SumOfAmount,
            Amount: params.Current_Amount,
            FavouriteNamess: params.TestName,
            Status: params.Status === 'Active' ? 'Inactive' : 'Active'
        };
        axios.post(`${UrlLink}Masters/Favourite_TestNames_Details`, data)
            .then((res) => {
                // const resres = res.data;
                // let typp = Object.keys(resres)[0]
                // let mess = Object.values(resres)[0]
                const tdata = {
                    message: "Status Updated Successfully",
                    type: "success",
                };
                dispatchvalue({ type: 'toast', value: tdata });
                setIsFavouritesGet(prev => !prev);
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const [joy, setJoy] = useState(false);
    const handleviewFavouriteTestnames = (params) => {
        console.log("paramsview", params);
        setIsEditing(true);
        setJoy(true);
        setisclick(false);
       

        const TestRow = params.TestName;

        setFavouriteNamess(TestRow);
        const { id, FavouriteName, Current_Amount, SumOfAmount, Status, ...rest } = params;
        const TestCodeNew = params.id;
        setFavouritesNames((prev) => ({
            ...prev,
            FavouritesCode: TestCodeNew,
            FavouritesName: FavouriteName,
            SumOfAmount: SumOfAmount,
            Amount: Current_Amount,
            TestName:"",
            ...rest,
        }));
    };

    const FavouritesColumnsView = [
        {
            key: "TestCode",
            name: "Test Code",
            frozen: true,
        },
        {
            key: "TestName",
            name: "Test Name",
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleRemoveFavourite(params.row)}
                >
                    <FaTrash className="check_box_clrr_cancell" />
                </Button>
            ),
        },
    ];
    const [isclic,setisclick] = useState(true);
   

    const FavouritesViewVisibility = [
        {
            key: "TestCode",
            name: "Test Code",
            frozen: true,
        },
        {
            key: "TestName",
            name: "Test Name",
        },
    
    ];

    const handleFavouritesClear = () => {
        setFavouriteNamess([]);
        setFavouritesNames({
            FavouritesCode: "",
            FavouritesName: "",
            TestName: "",
            SumOfAmount: "",
            Amount: "",
            Status: "Active"
        });
        setIsEditing(false);
        setJoy(false);
      
    };

    return (
        <>
            <div className="Main_container_app">
                <div className="common_center_tag">
                    <span>TestName</span>
                </div>
                <div className="RegisFormcon_1">
                    <div className="RegisForm_1">
                        <label>
                            Test Name <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="TestName"
                            required
                            value={TestName.TestName}
                             autoComplete="off"
                            onChange={handleLabTestNameOnchange}
                        />
                    </div>
                    <div className="RegisForm_1">
                        <label>
                            Amount <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="Amount"
                            required
                            value={TestName.Amount}
                             autoComplete="off"
                            onChange={handleLabTestNameOnchange}
                        />
                    </div>
                </div>

                <div className="Main_container_Btn">
                    <button onClick={handleLabTestNameSubmit}>
                        {TestName.TestCode ? "Update" : "Save"}
                    </button>
                </div>
                {LabTestNames.length > 0 && (
                    <ReactGrid columns={LabTestNameColumns} RowData={LabTestNames} />
                )}


                {/* ---------------favourites */}


                <div className="common_center_tag">
                    <span>Add Favourites</span>
                </div>

                <div className="RegisFormcon_1">
                    <div className="RegisForm_1">
                        <label>
                            Favourites Name <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="FavouritesName"
                            required
                            value={FavouritesNames.FavouritesName}
                             autoComplete="off"
                            onChange={handleFavouritesOnchange}
                        />
                    </div>

                    <div className="RegisForm_1">
                        <label>
                            Sum Of the Amount <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="SumOfAmount"
                            required
                            readOnly
                            value={FavouritesNames.SumOfAmount}
                            autoComplete="off"
                            onChange={handleFavouritesOnchange}
                        />
                    </div>
                    <div className="RegisForm_1">
                        <label>
                            Amount <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="Amount"
                            required
                            value={FavouritesNames.Amount}
                             autoComplete="off"
                            onChange={handleFavouritesOnchange}
                        />
                    </div>

                    {!isEditing && (
                        <>

                            <div className="RegisForm_1">
                                <label>
                                    Test Name <span>:</span>
                                </label>

                                <input
                                    type="text"
                                    list="packagenameOptions"
                                    id="TestName"
                                    name="TestName"
                                    value={FavouritesNames.TestName}
                                     autoComplete="off"
                                    onChange={handleFavouritesOnchange}
                                />
                                <datalist id="packagenameOptions">
                                    {activetestnames.map((pack, index) => (
                                        <option key={index} value={pack.Test_Name} />
                                    ))}
                                </datalist>
                            </div>


                            <button className="Addnamebtn2222" onClick={handleAddFavourite}>
                                +
                            </button>
                        </>
                    )}

                </div>


                {!joy && FavouriteNamess.length > 0 && (
                    <ReactGrid columns={FavouritesColumns} RowData={FavouriteNamess} />
                )}
                {joy && isclic && (
                    <ReactGrid columns={FavouritesColumnsView} RowData={FavouriteNamess} />
                )}
                 {!isclic &&  (
                    <ReactGrid columns={FavouritesViewVisibility} RowData={FavouriteNamess} />
                )}
               
                {joy && (
                    <div className="Main_container_Btn">
                        <button onClick={handleFavouritesClear}>
                            Clear
                        </button>
                    </div>
                )}
                {!joy && (
                    <div className="Main_container_Btn">
                        <button onClick={handleFavouritesSubmit}>
                            {FavouritesNames.FavouritesCode ? "Update" : "Save"}
                        </button>
                    </div>)}
                {special.length > 0 && (
                    <ReactGrid columns={SpecialColumnns} RowData={special} />
                )}


                <ToastAlert Message={toast.message} Type={toast.type} />
            </div>
        </>
    );
};

export default LabMaster;

