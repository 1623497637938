import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGrid from "../../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import ToastAlert from "../../OtherComponent/ToastContainer/ToastAlert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModelContainer from "../../OtherComponent/ModelContainer/ModelContainer"



const RadiologyMaster = () => {
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const toast = useSelector((state) => state.userRecord?.toast);
  const dispatchvalue = useDispatch();
  const [isEditing, setIsEditing] = useState(true);
  const [isEditing1, setIsEditing1] = useState(false);
  const [selectedrow, setSelectedRow] = useState(false);

  // ----------------------RadiologyName--------
  const [RadiologyName, setRadiologyName] = useState({
    RadiologyId: "",
    RadiologyName: "",
    Location: "",
  });
  // console.log(RadiologyName)
  const [LocationData, setLocationData] = useState([]);
  const [IsRadiologyGet, setIsRadiologyGet] = useState(false);
  const [RadiologyNames, setRadiologyNames] = useState([]);
  const [BookingFees, setBookingFees] = useState([]);
  useEffect(() => {
    axios.get(`${UrlLink}Masters/Location_Detials_link`)
      .then((res) => {
        const ress = res.data
        setLocationData(ress)
      })
      .catch((err) => {
        console.log(err);
      })
  }, [UrlLink])

  const handleRadiologyChange = (e) => {
    const { name, value } = e.target;

    if (name === 'Location') {
      // Update Location and reset RadiologyName when location changes
      setRadiologyName((prev) => ({
        ...prev,
        [name]: value,
        RadiologyName: '', // Reset RadiologyName on location change
      }));
    } else if (name === "RadiologyName") {
      // Update the relevant field, applying toUpperCase and trim
      setRadiologyName((prev) => ({
        ...prev,
        [name]: value?.toUpperCase()?.trim(),
      }));
    }
  };


  const handleeditRadiologyStatus = (params) => {

    const data = {
      RadiologyId: params.id,
      Statusedit: true,

    }
    axios.post(`${UrlLink}Masters/Radiology_Names_link`, data)
      .then((res) => {
        const resres = res.data
        let typp = Object.keys(resres)[0]
        let mess = Object.values(resres)[0]
        const tdata = {
          message: mess,
          type: typp,
        }
        dispatchvalue({ type: 'toast', value: tdata });
        setIsRadiologyGet(prev => !prev)
      })
      .catch((err) => {
        console.log(err);
      })
  };
  const RadiologyColumns = [
    {
      key: "id",
      name: "Radiology Id",
      frozen: true,
    },
    {
      key: "created_by",
      name: "Created By",
      frozen: true,
    },
    {
      key: "RadiologyName",
      name: "Radiology Name",
    },
    {
      key: "Location_Name",
      name: "Location Name",
    },
    {
      key: "Status",
      name: "Status",
      renderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={() => handleeditRadiologyStatus(params.row)}
        >
          {params.row.Status}
        </Button>
      ),
    },
    {
      key: "EditAction",
      name: "Edit",
      renderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={() => handleeditRadiology(params.row)}
        >
          <EditIcon className="check_box_clrr_cancell" />
        </Button>
      ),
    },
    {
      key: "ViewAction",
      name: "View",
      renderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={() => handleModalVisible(params.row)}
        >
          <VisibilityIcon className="check_box_clrr_cancell" />
        </Button>
      ),
    },
  ];


  const handleModalVisible = (params) => {
    let BookingFees = params.BookingFees;
    if (BookingFees && BookingFees.length !== 0) {
      setSelectedRow(true);
      setBookingFees(BookingFees)

    }
    else {

      const tdata = {
        message: 'There is no data to view.',
        type: 'warn'
      };
      dispatchvalue({ type: 'toast', value: tdata });
    }

  }


  const handleRadiologySubmit = () => {

    if (RadiologyName.RadiologyName) {
      const data = {
        ...RadiologyName,
        RadiologyName: RadiologyName.RadiologyName || "",
        Location: RadiologyName.Location || "",
        AmountArray: drainsData2 || [],
        created_by: userRecord?.username || "",
      };
      console.log(" twodata", data)
      axios
        .post(`${UrlLink}Masters/Radiology_Names_link`, data)
        .then((res) => {
          const [typp, mess] = Object.entries(res.data)[0];
          dispatchvalue({ type: "toast", value: { message: mess, type: typp } });
          setIsRadiologyGet((prev) => !prev);
          setRadiologyName({
            RadiologyId: "",
            RadiologyName: "",
            Location: "",
          });
          setDrainsData2([{ id: 1, From: "0", To: "", Amount: "" }]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatchvalue({ type: "toast", value: { message: "Please provide the Radiology Name.", type: "warn" } });
    }
  };
  const handleeditRadiology = (params) => {
    console.log("23456", params);
    const { id, ...rest } = params;
    setRadiologyName({
      RadiologyId: id,
      Location: rest?.Location_Id,
      ...rest,
    });
    if (params?.BookingFees?.length > 0) {
      const convertedata = params?.BookingFees.map((field, index) => {
        return {
          ...field,
          To: field.To.includes('above') ? '' : field.To
        }
      }

      )
      setDrainsData2(convertedata);
    }
    else {
      setDrainsData2([{ id: 1, From: "0", To: "", Amount: "" }]);
    }


  };

  useEffect(() => {
    axios
      .get(`${UrlLink}Masters/Radiology_Names_link`)
      .then((response) => {
        setRadiologyNames(response.data);
        console.log("response235999", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [IsRadiologyGet, UrlLink]);

  // ----------testname--------
  const [TestName, setTestName] = useState({
    TestNameId: "",
    RadiologyId: "",
    RadiologyName: "",
    TestName: "",
    Types: "No",
    Amount: "",
    SubTestName: "",
    TestCode: "",
    Prev_Amount: "",
    Location: "",
    Status: "Active",
  });

  const [TestNames, setTestNames] = useState([]);
  const [IsTestNameGet, setIsTestNameGet] = useState(false);
  const [issubtestedited, setissubtestedited] = useState(null)

  const [joy, setjoy] = useState(false);

  const handleeditTestNameLast = (params) => {
    setIsEditing(false); // Set editing state to false

    const { id, Curr_Amount, TestCode, Prev_Amount, Status, ...rest } = params; // Destructure params
    const TestCodeNew = params.TestCode;

    // Check if Sub_test_data is empty or not present
    if (!params.Sub_test_data || params.Sub_test_data.length === 0) {
      setTestName((prev) => ({
        ...prev,
        TestNameId: id,
        Prev_Amount: Prev_Amount || 0.00,
        Amount: Curr_Amount,
        TestCode: TestCodeNew,
        Location: rest.locationid,
        ...rest,

      }));

    } else {
      setjoy(true); // Set joy state to true to show the grid
      setIsEditing1(true);
      const TestCodeNewSub = params.TestCode;
      const StatusOld = params.Status;
      setTestName((prev) => ({
        ...prev,
        TestNameId: id,
        TestCode: TestCodeNewSub,
        Status: StatusOld,
        Location: rest.locationid,
        ...rest,
      }));

      setSelectedTestName(params.Sub_test_data.map((subTest, index) => ({
        SubTestId: subTest.SubTest_Code,
        SubTestName: subTest.SubTestName,
        Prev_Amount: subTest.Prev_Amount || 0.00, // Assign Prev_Amount from subTest data directly
        Amount: subTest.Amount,
        Status: subTest.Status,
        id: subTest.id,
        ...rest,
      })));
    }
  };



  const handleTestNameChange = (e) => {
    const { name, value } = e.target;
    if (name === "Location") {
      setTestName((prev) => ({
        ...prev,
        [name]: value,
        RadiologyName: '',
        TestName: "",
        Types: "No",
        SubTestName: "",
        Amount: "",
        // Reset RadiologyName on location change
      }));
    }
    else if (name === "RadiologyName") {
      // Clear other fields when RadiologyName changes
      setTestName((prevState) => ({
        ...prevState,
        RadiologyName: value?.toUpperCase()?.trim(),
        TestName: "",
        Types: "No",
        SubTestName: "",
      }));
      setSelectedTestName([]);
    } else if (name === "TestName") {
      // Clear SubTestName and reset Types when TestName changes
      setTestName((prevState) => ({
        ...prevState,
        TestName: value?.toUpperCase()?.trim(),
        Types: "No",
        SubTestName: "",
      }));
      setSelectedTestName([]);
    } else if (name === "Types") {
      // Just update the SubTestName value
      setTestName((prevState) => ({
        ...prevState,
        SubTestName: value?.toUpperCase()?.trim(),
      }));
      setSelectedTestName([]);
    } else {
      // Update other fields
      setTestName((prevState) => ({
        ...prevState,
        [name]: value?.toUpperCase()?.trim(),
      }));
    }
  };

  const handleYesChange = () => {
    setTestName((prevState) => ({
      ...prevState,
      Types: "Yes",
      SubTestName: "",
      Amount: "",
    }));
  };

  const handleNoChange = () => {
    setTestName((prevState) => ({
      ...prevState,
      Types: "No",
      SubTestName: "",
      Amount: "",
    }));
  };

  const handleTestNameSubmit = () => {
    // Check if all required fields for TestName are filled
    if (
      TestName.RadiologyName !== "" &&
      TestName.TestName !== "" &&
      TestName.Types !== ""
    ) {
      const RadId = RadiologyNames.find(
        (p) => p.RadiologyName === TestName.RadiologyName
      );

      const data = {
        TestNameId: TestName.TestNameId,
        RadiologyName: RadId.id,
        TestName: TestName.TestName,
        Types: TestName.Types,
        Amount: TestName.Amount,
        SubTestName: SelectedTestName || [],
        created_by: userRecord?.username || "",
        location: userRecord?.location || "",
        TestCode: TestName.TestCode,
        Prev_Amount: TestName.Prev_Amount,
        Status: "Active",
      };
      console.log("data", data);


      axios
        .post(`${UrlLink}Masters/Radiology_details_link`, data)
        .then((res) => {
          const resData = res.data;
          const type = Object.keys(resData)[0];
          const message = Object.values(resData)[0];
          const tdata = {
            message: message,
            type: type,
          };
          dispatchvalue({ type: "toast", value: tdata });
          setIsTestNameGet((prev) => !prev);
          setTestName({
            TestNameId: "",
            RadiologyId: "",
            RadiologyName: "",
            TestName: "",
            Types: "No",
            Amount: "",
            SubTestName: "",
            TestCode: "",
            Prev_Amount: "",
            Location: "",
            Status: "Active",
          });
          setIsEditing(true);
          setIsEditing1(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const tdata = {
        message: "Please provide Radiology Name, Test Name.",
        type: "warn",
      };
      dispatchvalue({ type: "toast", value: tdata });
    }
  };

  const [SelectedTestName, setSelectedTestName] = useState([]);
  const handlePlusTestname = () => {
    if (
      TestName.RadiologyName !== "" &&
      TestName.SubTestName !== "" &&
      TestName.TestName !== "" &&
      TestName.Amount !== "" &&
      TestName.Types === "Yes"
    ) {
      console.log("SelectedTestName", SelectedTestName);
      // Check if SubTestName already exists in selectedTestNames
      if (issubtestedited) {
        const updated = [...SelectedTestName];
        const indx = SelectedTestName.findIndex((p) => p.id === issubtestedited.id);

        if (indx !== -1) {
          const dattt = {
            id: issubtestedited.id,
            SubTestId: issubtestedited.SubTestId,
            SubTestName: TestName.SubTestName,
            Amount: TestName.Amount,
            Prev_Amount: issubtestedited.Prev_Amount || 0.00,
            Status: "Active",
          };

          updated[indx] = dattt;

          setSelectedTestName(updated);
          setTestName((prevState) => ({
            ...prevState,
            SubTestName: "",
            Amount: "",
            Prev_Amount: "",
            Status: "",
          }));

          setissubtestedited(null);
        }
      } else {
        const isDuplicate = SelectedTestName.some(
          (test) => test.SubTestName === TestName.SubTestName
        );

        if (isDuplicate) {
          const tdata = {
            message: `This SubTestName already exists..`,
            type: "warn",
          };
          dispatchvalue({ type: "toast", value: tdata });
          setTestName((prevState) => ({
            ...prevState,
            SubTestName: "",
            Amount: "",
            Prev_Amount: "",
            Status: "",
          }));
          setIsEditing(!isEditing);
          setIsEditing1(!isEditing1);

          setissubtestedited(null);
        } else {
          setSelectedTestName((prevTestNames) => [
            ...prevTestNames,
            {
              id: prevTestNames.length + 1,
              SubTestId: "",
              SubTestName: TestName.SubTestName,
              Amount: TestName.Amount,
              Prev_Amount: TestName.Prev_Amount || 0.00,
              Status: "Active",
            },
          ]);

          setTestName((prevState) => ({
            ...prevState,
            SubTestName: "",
            Amount: "",
            Prev_Amount: "",
            Status: "",
          }));
          setissubtestedited(null);

        }
      }
    } else {
      const tdata = {
        message: `Please Fill All Fields.`,
        type: "warn",
      };
      dispatchvalue({ type: "toast", value: tdata });
    }
  };

  const handleEditSubTestName = (row) => {
    setissubtestedited(row)
    setTestName((prev) => ({
      ...prev,
      SubTestName: row.SubTestName,
      Amount: row.Amount,
      Prev_Amount: row.Prev_Amount,
    }))
  };



  useEffect(() => {
    axios
      .get(`${UrlLink}Masters/Radiology_details_link`)
      .then((res) => {
        const ress = res.data;
        console.log("testname", ress);
        setTestNames(ress);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [IsTestNameGet, UrlLink]);

  const TestNameColumns = [
    {
      key: "id",
      name: "S.No",
      frozen: true,
    },

    {
      key: "RadiologyName",
      name: "Radiology Name",
      frozen: true,
    },
    {
      key: "TestCode",
      name: "Test Code",
    },
    {
      key: "TestName",
      name: "Test Name",
    },
    {
      key: "Amount",
      name: "Amount",
      children: [
        {
          key: "Prev_Amount",
          name: "Prev Amount",

          renderCell: (params) =>
            params.row.Types === "Yes" ? <>-</> : <>{params.row.Prev_Amount}</>,
        },
        {
          key: "Curr_Amount",
          name: "Curr Amount",
          renderCell: (params) =>
            params.row.Types === "Yes" ? <>-</> : <>{params.row.Curr_Amount}</>,
        },
      ],
    },
    {
      key: "SubTests",
      name: "Sub Test Name",
      renderCell: (params) =>
        params.row.Types === "Yes" ? (
          <>Aavilable</>

        ) : (
          <>Not Available</>
        ),
    },
    {
      key: "created_by",
      name: "Created By ",
      frozen: true,
    },
    {
      key: "Action",
      name: "Action",
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleeditTestNameLast(params.row)}
          >
            <EditIcon className="check_box_clrr_cancell" />
          </Button>
        </>
      ),
    },
  ];

  const handleeditSubTeststatus = (params) => {
    console.log("dsrty", params)
    if (params.SubTestId) {
      setSelectedTestName(prevTestNames =>
        prevTestNames.map(test =>
          test.SubTestId === params.SubTestId
            ? { ...test, Status: test.Status === 'Active' ? 'Inactive' : 'Active' }
            : test
        )
      );
    }

  };

  const baseColumns = [
    { key: "id", name: "S.No", width: 80 },
    { key: "SubTestName", name: "SubTestName" },
    {
      key: "Status",
      name: "Status",
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleeditSubTeststatus(params.row)}
          >
            {params.row.Status}
          </Button>
        </>
      ),
    },
    {
      key: "Action",
      name: "Action",
      width: 100,
      renderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={() => handleEditSubTestName(params.row)}
        >
          <EditIcon className="check_box_clrr_cancell" />
        </Button>
      ),
    },
  ];

  // Conditionally add the Amount column
  const amountColumn = joy
    ? {
      key: "Amount",
      name: "Amount",
      children: [
        {
          key: "Prev_Amount",
          name: "Prev Amount",

        },
        {
          key: "Amount",
          name: "Curr Amount",

        },
      ],
    }
    : { key: "Amount", name: "Amount" };


  const tableColumns = [
    ...baseColumns.slice(0, 2),
    amountColumn,
    ...baseColumns.slice(2),
  ];





  const [drainsData2, setDrainsData2] = useState([
    { id: 1, From: "0", To: "", Amount: "" },
  ]);


  const addRow2 = () => {
    setDrainsData2((prevData) => {
      const lastRow = prevData[prevData.length - 1]; // Get the last row

      // Check if the last row has non-empty values
      if (lastRow.From !== "" && lastRow.To !== "" && lastRow.Amount !== "") {
        // Increment the "To" value by 1 for the new row's From value
        const newFromValue = (parseFloat(lastRow.To) + 1).toString();
        // Create the new row with the updated From value
        return [...prevData, { id: lastRow.id + 1, From: newFromValue, To: "", Amount: "" }];
      } else {
        // Show an alert if any value is empty
        const tdata = {
          message: "Please ensure the current row has non-empty values for 'From', 'To', and 'Amount'.",
          type: "warn",
        };
        dispatchvalue({ type: "toast", value: tdata });
        return prevData; // Do not add a new row if the current row has empty values
      }
    });
  };


  const deleteRow2 = (index) => {
    const idss = index.id
    const updatedDrainsData2 = [...drainsData2];
    const infxx = updatedDrainsData2.findIndex(p => p.id === idss)
    updatedDrainsData2.splice(infxx, 1);

    setDrainsData2(updatedDrainsData2);
  };

  const handleChangeObstetric2 = (e, id, key) => {
    const updatedDrainsData2 = [...drainsData2];
    const inedxx = updatedDrainsData2.findIndex(p => p.id === id)
    updatedDrainsData2[inedxx][key] = e.target.value;

    // Update the From value of the next row when To is changed
    if (key === "To" && inedxx < updatedDrainsData2.length - 1) {
      const nextIndex = inedxx + 1;
      updatedDrainsData2[nextIndex]["From"] = (parseFloat(e.target.value) + 1).toString();
    }

    setDrainsData2(updatedDrainsData2);
  };








  const BasicPayColumns = [
    {
      key: "id",
      name: "S.NO",
      frozen: true,
    },
    {
      key: "From",
      name: "From Value",
    },
    {
      key: "To",
      name: "To Value",
    },
    {
      key: "Amount",
      name: "Amount",
    },

  ];


  const [isChecked, setIsChecked] = useState(true); // Set true to have it checked by default

  // Handler for checkbox change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };



  const bookingfeecolumn = [
    {
      key: "id",
      name: "S.NO",
      frozen: true,
    },
    {
      key: "From",
      name: "From Value",
    },
    {
      key: "To",
      name: "To Value",
      renderCell: (params) => (
        <input
          type="number"
          style={{ all: 'unset', width: '100px', border: '1px solid var(--ProjectColor)' }}

          value={params.row.To}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          onChange={(e) => handleChangeObstetric2(e, params.row.id, "To")}
        />
      )
    },
    {
      key: "Amount",
      name: "Amount",
      renderCell: (params) => (
        <input
          type="number"
          style={{ all: 'unset', width: '100px', border: '1px solid var(--ProjectColor)' }}
          value={params.row.Amount}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          onChange={(e) => handleChangeObstetric2(e, params.row.id, "Amount")}
        />
      )
    },
    {
      key: "Action",
      name: "Action",
      renderHeaderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={addRow2}
        >
          <AddIcon className="check_box_clrr_cancell" />
        </Button>
      ),
      renderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={() => deleteRow2(params.row)}
        >
          <RemoveIcon className="check_box_clrr_cancell" />
        </Button>
      )
    },
  ]
  return (
    <>
      <div className="Main_container_app">
        <div className="common_center_tag">
          <span>Radiology</span>
        </div>
        <div className="RegisFormcon_1">
          <div className="RegisForm_1">
            <label> Location <span>:</span> </label>

            <select
              name='Location'
              required
              disabled={Boolean(RadiologyName.RadiologyId)}
              value={RadiologyName.Location}
              onChange={handleRadiologyChange}
            >
              <option value=''>Select</option>
              {
                LocationData.map((p) => (
                  <option key={p.id} value={p.id}>{p.locationName}</option>
                ))
              }
            </select>
          </div>
          <div className="RegisForm_1">
            <label>
              Radiology Name <span>:</span>
            </label>
            <input
              type="text"
              name="RadiologyName"
              required
              value={RadiologyName.RadiologyName}
              autoComplete="off"
              onChange={handleRadiologyChange}
            />
          </div>
          <div className="RegisForm_1">
            <label>
              <input
                type="checkbox"
                style={{ width: "25px" }}
                checked={isChecked} // Checkbox is checked by default
                onChange={handleCheckboxChange}
              />
              Booking Fees
            </label>
          </div>
          {
            isChecked && (
              <ReactGrid columns={bookingfeecolumn} RowData={drainsData2} />
            )
          }

        </div>
        <div className="Main_container_Btn">
          <button onClick={handleRadiologySubmit}>
            {RadiologyName.RadiologyId ? "Update" : "Save"}
          </button>
        </div>
        {RadiologyNames.length > 0 && (
          <ReactGrid columns={RadiologyColumns} RowData={RadiologyNames} />
        )}

        {selectedrow && BookingFees.length !== 0 && (
          <div className="loader" onClick={() => setSelectedRow(false)}>
            <div className="loader_register_roomshow" onClick={(e) => e.stopPropagation()}>
              <br />

              <div className="common_center_tag">
                <span>Booking Fees Details</span>
              </div>
              <br />
              <br />
              <div className="Main_container_app">

                <ReactGrid columns={BasicPayColumns} RowData={BookingFees} />
              </div>

            </div>
          </div>
        )}







        {/*------------------TestNames--------------------- */}
        <div className="common_center_tag">
          <span>Test Name</span>
        </div>
        <div className="RegisFormcon_1">
          <div className="RegisForm_1">
            <label> Location <span>:</span> </label>

            <select
              name='Location'
              required
              disabled={TestName.TestNameId}
              value={TestName.Location}
              onChange={handleTestNameChange}
            >
              <option value=''>Select</option>
              {
                LocationData.map((p) => (
                  <option key={p.id} value={p.id}>{p.locationName}</option>
                ))
              }
            </select>
          </div>
          <div className="RegisForm_1">
            <label>
              Radiology Name <span>:</span>
            </label>
            <select
              name="RadiologyName"
              required
              value={TestName.RadiologyName}
              onChange={handleTestNameChange}
              disabled={!isEditing}
            >
              <option value="">Select</option>
              {RadiologyNames?.map((dept, indx) => (
                <option key={indx} value={dept.RadiologyName}>
                  {dept.RadiologyName}
                </option>
              ))}
            </select>
          </div>
          <div className="RegisForm_1">
            <label>
              Test Name <span>:</span>
            </label>
            <input
              type="text"
              name="TestName"
              required
              value={TestName.TestName}
              autoComplete="off"
              onChange={handleTestNameChange}
            />
          </div>

          <div className="RegisForm_1">
            <label>
              IsSubDepartment <span>:</span>
            </label>
            <input
              type="checkbox"
              name="Types"
              style={{ width: "35px" }}
              checked={TestName.Types === "Yes"}
              onChange={handleYesChange}
              disabled={!isEditing}
            />
            <label> Yes </label>
            <input
              type="checkbox"
              name="Types"
              autoComplete="off"
              style={{ width: "35px" }}
              checked={TestName.Types === "No"}
              onChange={handleNoChange}
              disabled={!isEditing}
            />
            <label> No </label>
          </div>

          {TestName.Types === "No" && (
            <div className="RegisForm_1">
              <label>
                Amount <span>:</span>
              </label>
              <input
                type="number"
                name="Amount"
                autoComplete="off"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                required
                value={TestName.Amount}
                onChange={handleTestNameChange}

              />
            </div>
          )}
        </div>
        <div className="RegisFormcon_1">
          {TestName.Types === "Yes" && (
            <>
              <div className="RegisForm_1">
                <label htmlFor="SubTestName">
                  SubTestName <span>:</span>
                </label>
                <input
                  type="text"
                  id="SubTestName"
                  name="SubTestName"
                  autoComplete="off"
                  onChange={handleTestNameChange}
                  value={TestName.SubTestName}

                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Amount <span>:</span>
                </label>
                <input
                  type="number"
                  name="Amount"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  required
                  value={TestName.Amount}
                  autoComplete="off"
                  onChange={handleTestNameChange}

                />
              </div>
              <button className="Addnamebtn2222" onClick={handlePlusTestname}>
                +
              </button>
            </>
          )}
        </div>
        {SelectedTestName.length > 0 && TestName.Types !== "No" && (
          <ReactGrid columns={tableColumns} RowData={SelectedTestName} />
        )}

        <div className="Main_container_Btn">
          <button onClick={handleTestNameSubmit}>
            {TestName.TestNameId ? "Update" : "Save"}
          </button>
        </div>
        {TestNames.length > 0 && (
          <ReactGrid columns={TestNameColumns} RowData={TestNames} />
        )}

      </div>



      <ModelContainer />
      <ToastAlert Message={toast.message} Type={toast.type} />
    </>
  );
};

export default RadiologyMaster;
