
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import { Button } from '@mui/material';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import EditIcon from "@mui/icons-material/Edit";



const InventorySubMasters = () => {

    const [RackMasterPage,setRackMasterPage]=useState('ProductType')


    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const dispatchvalue = useDispatch();


    




    const [PackTypeState, setPackTypeState] = useState({
        PackTypeID: '',
        PackTypeName: ''
    })


    const [PackTypeArray, setPackTypeArray] = useState([])

    const [ProductGroupState, setProductGroupState] = useState({
        ProductGroupID: '',
        ProductGroupName: ''
    })


    const [ProductGroupArray, setProductGroupArray] = useState([])


    const [ProductTypeState, setProductTypeState] = useState({
        ProductTypeID: '',
        ProductTypeName: ''
    })


   const [ProductTypeArray, setProductTypeArray] = useState([])


   const [GenericNameState,setGenericNameState] = useState ({
    GenericNameID:'',
    GenericName:'',
   })

   const [GenericNameArray,setGenericNameArray]= useState([])

   const [CompanyNameState,setCompanyNameState]=useState({
    CompanyNameId:'',
    CompanyName:'',
   })

   const [CompanyNameArray,setCompanyNameArray]=useState([])

    // -----------------------------------------------------------------------


    const GetPackTypedata = useCallback(() => {
        axios.get(`${UrlLink}Masters/Pack_Type_link`)
            .then((res) => {
                console.log(res.data);
                let data = res.data

                if (Array.isArray(data) && data.length !== 0) {
                    setPackTypeArray(data)
                }
                else {
                    setPackTypeArray([])
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }, [])


    useEffect(() => {
        GetPackTypedata()
    }, [GetPackTypedata])


    const HandleUpdateStatusPackType = (params) => {

        let Editdata = {
            PackTypeID: params.id,
            Statusedit: true
        }

        axios.post(`${UrlLink}Masters/Pack_Type_link`, Editdata)
            .then((res) => {
                // console.log(res.data);

                let data = res.data

                let type = Object.keys(data)[0]
                let mess = Object.values(data)[0]

                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });

                GetPackTypedata()

            })
            .catch((err) => {
                console.log(err);
            })



    }


    const HandeleEditPackType = (params) => {
        let Editdata = params

        setPackTypeState((prev) => ({
            ...prev,
            PackTypeID: Editdata.id,
            PackTypeName: Editdata.PackTypeName,
        }))

    }


    const PackTypeColumn = [
        {
            key: 'id',
            name: 'Pack Type Id',
            frozen: true
        },
        {
            key: 'PackTypeName',
            name: 'Pack Type Name',
            frozen: true
        },
        {
            key: 'Status',
            name: 'Status',
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleUpdateStatusPackType(params.row)}
                    >
                        {params.row.Status ? "ACTIVE" : "INACTIVE"}
                    </Button>
                </>
            )
        },
        {
            key: 'Action',
            name: 'Action',
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandeleEditPackType(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            )
        }
    ]



    const HandleSavePackType = () => {


        let senddata = {
            ...PackTypeState,
            created_by: userRecord?.username || ''
        }

        axios.post(`${UrlLink}Masters/Pack_Type_link`, senddata)
            .then((res) => {
                // console.log(res.data);

                let data = res.data

                let type = Object.keys(data)[0]
                let mess = Object.values(data)[0]
                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });

                setPackTypeState({
                    PackTypeID: '',
                    PackTypeName: ''
                })

                GetPackTypedata()

            })
            .catch((err) => {
                console.log(err);
            })

    }

    // -----------------------------------------------------------------------


    const GetProductGroupdata = useCallback(() => {
        axios.get(`${UrlLink}Masters/Product_Group_Link`)
            .then((res) => {
                console.log(res.data);
                let data = res.data

                if (Array.isArray(data) && data.length !== 0) {
                    setProductGroupArray(data)
                }
                else {
                    setProductGroupArray([])
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }, [])


    useEffect(() => {
        GetProductGroupdata()
    }, [GetProductGroupdata])


    const HandleUpdateStatusProduct = (params) => {

        let Editdata = {
            ProductGroupID: params.id,
            Statusedit: true
        }

        axios.post(`${UrlLink}Masters/Product_Group_Link`, Editdata)
            .then((res) => {
                // console.log(res.data);

                let data = res.data

                let type = Object.keys(data)[0]
                let mess = Object.values(data)[0]

                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });

                GetProductGroupdata()

            })
            .catch((err) => {
                console.log(err);
            })



    }


    const HandeleEditProductName = (params) => {
        let Editdata = params

        setProductGroupState((prev) => ({
            ...prev,
            ProductGroupID: Editdata.id,
            ProductGroupName: Editdata.ProductGroupName,
        }))

    }


    const ProductGroupColumn = [
        {
            key: 'id',
            name: 'Product Group Id',
            frozen: true
        },
        {
            key: 'ProductGroupName',
            name: 'Product Group Name',
            frozen: true
        },
        {
            key: 'Status',
            name: 'Status',
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleUpdateStatusProduct(params.row)}
                    >
                        {params.row.Status ? "ACTIVE" : "INACTIVE"}
                    </Button>
                </>
            )
        },
        {
            key: 'Action',
            name: 'Action',
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandeleEditProductName(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            )
        }
    ]



    const HandleSaveProductGroup = () => {


        let senddata = {
            ...ProductGroupState,
            created_by: userRecord?.username || ''
        }

        axios.post(`${UrlLink}Masters/Product_Group_Link`, senddata)
            .then((res) => {
                // console.log(res.data);

                let data = res.data

                let type = Object.keys(data)[0]
                let mess = Object.values(data)[0]
                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });

                setProductGroupState({
                    ProductGroupID: '',
                    ProductGroupName: ''
                })

                GetProductGroupdata()

            })
            .catch((err) => {
                console.log(err);
            })

    }


    // ---------------------------------------------------------------------


    const GetProductTypedata = useCallback(() => {
        axios.get(`${UrlLink}Masters/ProductType_Master_lik`)
            .then((res) => {
                console.log(res.data);
                let data = res.data

                if (Array.isArray(data) && data.length !== 0) {
                    setProductTypeArray(data)
                }
                else {
                    setProductTypeArray([])
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }, [])


    const HandeleEditProducttype = (params) => {
        let Editdata = params

        setProductTypeState((prev) => ({
            ...prev,
            ProductTypeID: Editdata.id,
            ProductTypeName: Editdata.ProductTypeName,
        }))

    }


    const HandleUpdateStatusPro = (params) => {

        let Editdata = {
            ProductTypeID: params.id,
            Statusedit: true
        }

        axios.post(`${UrlLink}Masters/ProductType_Master_lik`, Editdata)
            .then((res) => {
                // console.log(res.data);
                let data = res.data

                let type = Object.keys(data)[0]
                let mess = Object.values(data)[0]
                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });
                GetProductTypedata()
            })
            .catch((err) => {
                console.log(err);
            })


    }

    const ProductColumn = [
        {
            key: 'id',
            name: 'ProductType Id',
            frozen: true
        },
        {
            key: 'ProductTypeName',
            name: 'ProductType Name',
            frozen: true
        },
        {
            key: 'Status',
            name: 'Status',
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleUpdateStatusPro(params.row)}
                    >
                        {params.row.Status ? "ACTIVE" : "INACTIVE"}
                    </Button>
                </>
            )
        },
        {
            key: 'Action',
            name: 'Action',
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandeleEditProducttype(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            )
        }

    ]

    useEffect(() => {
        GetProductTypedata()
    }, [GetProductTypedata])




    const HandleSaveProductType = () => {

        let senddata = {
            ...ProductTypeState,
            created_by: userRecord?.username || ''
        }

        axios.post(`${UrlLink}Masters/ProductType_Master_lik`, senddata)
            .then((res) => {
                // console.log(res.data);

                let data = res.data

                let type = Object.keys(data)[0]
                let mess = Object.values(data)[0]
                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });

                setProductTypeState({
                    ProductTypeID: '',
                    ProductTypeName: ''
                })

                GetProductTypedata()


            })
            .catch((err) => {
                console.log(err);
            })

    }




// -------------------------Gentric Name -----------------




const GetGenericNamedata =useCallback (()=>{

    axios.get(`${UrlLink}Masters/GenericName_Master_Link`)
.then((res) => {
    console.log(res.data);
    let data = res.data

    if (Array.isArray(data) && data.length !== 0) {
        setGenericNameArray(data)
    }
    else {
        setGenericNameArray([])
    }

})
.catch((err) => {
    console.log(err);
})


},[])


const HandleUpdateStatusGName =(params)=>{

    let Editdata = {
        GenericNameID: params.id,
        Statusedit: true
    }

    axios.post(`${UrlLink}Masters/GenericName_Master_Link`, Editdata)
        .then((res) => {
            // console.log(res.data);
            let data = res.data

            let type = Object.keys(data)[0]
            let mess = Object.values(data)[0]
            const tdata = {
                message: mess,
                type: type,
            }
            dispatchvalue({ type: 'toast', value: tdata });
            GetGenericNamedata()
        })
        .catch((err) => {
            console.log(err);
        })

}



const HandeleEditGenericName =(params)=>{
    let Editdata = params

    setGenericNameState({
        GenericNameID:Editdata.id,
        GenericName:Editdata.GenericName,
    })

}


const GenericNameColumn =[

    {
        key: 'id',
        name: 'Generic Name Id',
        frozen: true
    },
    {
        key: 'GenericName',
        name: 'Generic Name',
        frozen: true
    },
    {
        key: 'Status',
        name: 'Status',
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandleUpdateStatusGName(params.row)}
                >
                    {params.row.Status ? "ACTIVE" : "INACTIVE"}
                </Button>
            </>
        )
    },
    {
        key: 'Action',
        name: 'Action',
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandeleEditGenericName(params.row)}
                >
                    <EditIcon className="check_box_clrr_cancell" />
                </Button>
            </>
        )
    }

]


useEffect(() => {
    GetGenericNamedata()
}, [GetGenericNamedata])


const HandleSaveGenericName =()=>{


    let senddata = {
        ...GenericNameState,
        created_by: userRecord?.username || ''
    }

    axios.post(`${UrlLink}Masters/GenericName_Master_Link`, senddata)
        .then((res) => {
            // console.log(res.data);

            let data = res.data

            let type = Object.keys(data)[0]
            let mess = Object.values(data)[0]
            const tdata = {
                message: mess,
                type: type,
            }
            dispatchvalue({ type: 'toast', value: tdata });

            setGenericNameState({
                GenericNameID:'',
                GenericName:'',
            })

            GetGenericNamedata()


        })
        .catch((err) => {
            console.log(err);
        })


}


// ---------------------------Company Name--------------------------------



const GetCompanyNamedata = useCallback (()=>{

   axios.get(`${UrlLink}Masters/CompanyName_Master_Link`) 
   .then((res) => {
    console.log(res.data);
    let data = res.data

    if (Array.isArray(data) && data.length !== 0) {
        setCompanyNameArray(data)
    }
    else {
        setCompanyNameArray([])
    }

    })
    .catch((err) => {
        console.log(err);
    })


},[])


useEffect(()=>{
  GetCompanyNamedata()
},[GetCompanyNamedata])


const HandeleEditCompanyName =(params)=>{
    let Editdata = params

    setCompanyNameState({
        CompanyNameId:Editdata.id,
        CompanyName:Editdata.CompanyName,
    })

}


const HandleUpdateStatusCompanyName =(params)=>{
    
    let Editdata = {
        CompanyNameId: params.id,
        Statusedit: true
    }

    axios.post(`${UrlLink}Masters/CompanyName_Master_Link`, Editdata)
    .then((res) => {
        // console.log(res.data);
        let data = res.data

        let type = Object.keys(data)[0]
        let mess = Object.values(data)[0]
        const tdata = {
            message: mess,
            type: type,
        }
        dispatchvalue({ type: 'toast', value: tdata });
        GetCompanyNamedata()
    })
    .catch((err) => {
        console.log(err);
    })


}


const CompanyNameColumn =[
    {
        key: 'id',
        name: 'Company Name Id',
        frozen: true
    },
    {
        key: 'CompanyName',
        name: 'Company Name',
        frozen: true
    },
    {
        key: 'Status',
        name: 'Status',
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandleUpdateStatusCompanyName(params.row)}
                >
                    {params.row.Status ? "ACTIVE" : "INACTIVE"}
                </Button>
            </>
        )
    },
    {
        key: 'Action',
        name: 'Action',
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandeleEditCompanyName(params.row)}
                >
                    <EditIcon className="check_box_clrr_cancell" />
                </Button>
            </>
        )
    }
]



const HandleSaveCompanyName =()=>{


    let senddata = {
        ...CompanyNameState,
        created_by: userRecord?.username || ''
    }

    axios.post(`${UrlLink}Masters/CompanyName_Master_Link`, senddata)
        .then((res) => {
            // console.log(res.data);

            let data = res.data

            let type = Object.keys(data)[0]
            let mess = Object.values(data)[0]
            const tdata = {
                message: mess,
                type: type,
            }
            dispatchvalue({ type: 'toast', value: tdata });

            setCompanyNameState({
                CompanyNameId:'',
                CompanyName:'',
            })

            GetCompanyNamedata()


        })
        .catch((err) => {
            console.log(err);
        })


}





  return (
    <>
     <div className="Main_container_app">
         <h3>Inventory Masters</h3>

         <br/>
        <div className="RegisterTypecon">
            <div className="RegisterType">
            
            {["ProductType", "ProductGroup","PackType","GenericName","ManufacturerName"].map((p, ind) => (
                
                <div className="registertypeval" key={ind}>
                    <input
                        type="radio"
                        id={p}
                        name="appointment_type"
                        checked={RackMasterPage === p}
                        onChange={(e) => {
                            setRackMasterPage(e.target.value)
                        }}
                        value={p}
                    />
                    <label htmlFor={p}>
                        {p}
                    </label>
                </div>
            ))}

            </div>
            </div>

           

            {RackMasterPage ==='ProductType' && <>

              
            <br />

            <div className="RegisFormcon_1">

                <div className="RegisForm_1">
                    <label>Product Type<span>:</span> </label>
                    <input
                        type="text"
                        name='ProductTypeName'
                        autoComplete='off'
                        required
                        value={ProductTypeState.ProductTypeName}
                        onChange={(e) => setProductTypeState((prev) => ({ ...prev, ProductTypeName: e.target.value.toUpperCase() }))}
                    />
                </div>
            </div>

            <div className="Main_container_Btn">
                <button onClick={HandleSaveProductType} >
                    {ProductTypeState.ProductTypeID ? "Update" : "Add"}
                </button>
            </div>

            <ReactGrid columns={ProductColumn} RowData={ProductTypeArray} />


            </>}
            
            {RackMasterPage ==='ProductGroup' && <>

                <br />
                <div className="RegisFormcon_1">

                    <div className="RegisForm_1">
                        <label> Product Group <span>:</span> </label>
                        <input
                            type="text"
                            name='ProductGroupName'
                            autoComplete='off'
                            required
                            value={ProductGroupState.ProductGroupName}
                            onChange={(e) => setProductGroupState((prev) => ({ ...prev, ProductGroupName: e.target.value.toUpperCase() }))}
                        />
                    </div>
                </div>

                <div className="Main_container_Btn">
                    <button onClick={HandleSaveProductGroup} >
                        {ProductGroupState.ProductGroupID ? "Update" : "Add"}
                    </button>
                </div>

                <ReactGrid columns={ProductGroupColumn} RowData={ProductGroupArray} />


            
            
            </>}

           

            {RackMasterPage ==='PackType' && <>
                <br />

                <div className="RegisFormcon_1">

                    <div className="RegisForm_1">
                        <label>Pack Type <span>:</span> </label>
                        <input
                            type="text"
                            name='PackTypeName'
                            autoComplete='off'
                            required
                            value={PackTypeState.PackTypeName}
                            onChange={(e) => setPackTypeState((prev) => ({ ...prev, PackTypeName: e.target.value.toUpperCase() }))}
                        />
                    </div>
                </div>

                <div className="Main_container_Btn">
                    <button onClick={HandleSavePackType} >
                        {PackTypeState.PackTypeID ? "Update" : "Add"}
                    </button>
                </div>

                <ReactGrid columns={PackTypeColumn} RowData={PackTypeArray} />

            
            
            </>}


            {RackMasterPage ==='GenericName' && <>
                <br />

                <div className="RegisFormcon_1">

                    <div className="RegisForm_1">
                        <label>Generic Name<span>:</span> </label>
                        <input
                            type="text"
                            name='GenericName'
                            autoComplete='off'
                            required
                            value={GenericNameState.GenericName}
                            onChange={(e) => setGenericNameState((prev) => ({ ...prev, GenericName: e.target.value.toUpperCase() }))}
                        />
                    </div>
                </div>

                <div className="Main_container_Btn">
                    <button 
                    onClick={HandleSaveGenericName}
                    >
                        {GenericNameState.GenericNameID ? "Update" : "Add"}
                    </button>
                </div>

                <ReactGrid columns={GenericNameColumn} RowData={GenericNameArray} />

            
            
            </>}
            

            {RackMasterPage ==='ManufacturerName' && <>
                <br />

                <div className="RegisFormcon_1">

                    <div className="RegisForm_1">
                        <label>Manufacturer Name<span>:</span> </label>
                        <input
                            type="text"
                            name='CompanyName'
                            autoComplete='off'
                            required
                            value={CompanyNameState.CompanyName}
                            onChange={(e) => setCompanyNameState((prev) => ({ ...prev, CompanyName: e.target.value.toUpperCase() }))}
                        />
                    </div>
                </div>

                <div className="Main_container_Btn">
                    <button 
                    onClick={HandleSaveCompanyName}
                    >
                        {CompanyNameState.CompanyNameId ? "Update" : "Add"}
                    </button>
                </div>

                <ReactGrid columns={CompanyNameColumn} RowData={CompanyNameArray} />

            
            
            </>}






            </div>

            <ToastAlert Message={toast.message} Type={toast.type} />
    </>
  )
}

export default InventorySubMasters;
