import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "../DoctorWorkBench/Navigation.css";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import ToastAlert from "../OtherComponent/ToastContainer/ToastAlert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from "@mui/material/Button";
import ModelContainer from "../OtherComponent/ModelContainer/ModelContainer"
import { useNavigate } from "react-router-dom";
import bgImg2 from "../Assets/bgImg2.jpg";

const LabRequest = () => {
    const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const toast = useSelector((state) => state.userRecord?.toast);
    const LabWorkbenchNavigation = useSelector(state => state.Frontoffice?.LabWorkbenchNavigation);
    const dispatchvalue = useDispatch();
    const [IsTestGet, setIsTestGet] = useState([]);
    const [checkedTests, setCheckedTests] = useState([]);
 
    const [favcheckedTests, setfavcheckedTests] = useState([]);
   
    const [reason, setReason] = useState({});

    const [amounts, setAmounts] = useState({});
    const [selectedrow,setSelectedRow] = useState(false);
    const [view,setView] = useState([]);

    const [showTextarea, setShowTextarea] = useState(false);
    console.log(showTextarea)
    const [Categorytype, setCategorytype] = useState({});
   
    const [AddressValues, setAddressValues] = useState({});
 
    const [LocationValues, setLocationValues] = useState({});
   const navigate = useNavigate();


    useEffect(() => {
        if (Object.keys(LabWorkbenchNavigation).length === 0) {
            navigate('/Home/LabQuelist')
        }
    }, [LabWorkbenchNavigation,navigate])


    const handleAddressChange = (e, testCode, FavouriteCode) => {
        const newAddress = e.target.value;
        const key = testCode || FavouriteCode;

        if (key) {
            // Update address values state
            setAddressValues(prevState => ({
                ...prevState,
                [key]: newAddress
            }));

            // Update checkedTests if testCode is provided
            if (testCode) {
                setCheckedTests(prevCheckedTests =>
                    prevCheckedTests.map(test =>
                        test.testCode === testCode
                            ? { ...test, address: newAddress } // Update the address for the specific testCode
                            : test
                    )
                );
            }

            // Update favcheckedTests if FavouriteCode is provided
            if (FavouriteCode) {
                setfavcheckedTests(prevFavCheckedTests =>
                    prevFavCheckedTests.map(test =>
                        test.FavouriteCode === FavouriteCode
                            ? { ...test, address: newAddress } // Update the address for the specific FavouriteCode
                            : test
                    )
                );
            }

            // Also update uncheckedTests if needed
            setUncheckedTests(prevUncheckedTests =>
                prevUncheckedTests.map(test =>
                    test.testCode === key || test.FavouriteCode === key
                        ? { ...test, AddressValues: newAddress }
                        : test
                )
            );
        }
    };

    const handleLocationChange = (e, testCode, FavouriteCode) => {
        const newLocation = e.target.value;
        const key = testCode || FavouriteCode;

        if (key) {
            // Update location values state
            setLocationValues(prevState => ({
                ...prevState,
                [key]: newLocation
            }));

            // Update checkedTests if testCode is provided
            if (testCode) {
                setCheckedTests(prevCheckedTests =>
                    prevCheckedTests.map(test =>
                        test.testCode === testCode
                            ? { ...test, location: newLocation } // Update the location for the specific testCode
                            : test
                    )
                );
            }

            // Update favcheckedTests if FavouriteCode is provided
            if (FavouriteCode) {
                setfavcheckedTests(prevFavCheckedTests =>
                    prevFavCheckedTests.map(test =>
                        test.FavouriteCode === FavouriteCode
                            ? { ...test, location: newLocation } // Update the location for the specific FavouriteCode
                            : test
                    )
                );
            }

            // Also update uncheckedTests if needed
            setUncheckedTests(prevUncheckedTests =>
                prevUncheckedTests.map(test =>
                    test.testCode === key || test.FavouriteCode === key
                        ? { ...test, LocationValues: newLocation }
                        : test
                )
            );
        }
    };

    const handleCategoryChange = (e, testCode, FavouriteCode) => {
        
        const { value } = e.target;
        const key = testCode || FavouriteCode;

        // Update category type state
        setCategorytype(prevState => ({
            ...prevState,
            [key]: value
        }));

        // Function to create the updated test or favourite object
        const createUpdatedEntry = (code, category) => ({
            [testCode ? 'testCode' : 'FavouriteCode']: code,
            amount: amounts[code] || '',
            category: category,
            address: '', // Clear address
            location: '' // Clear location
        });

        if (value === "Outsource") {
          
            if (testCode) {
                setCheckedTests(prevCheckedTests => {
                    const testIndex = prevCheckedTests.findIndex(test => test.testCode === testCode);

                    // Create the updated test object
                    const updatedTest = createUpdatedEntry(testCode, value);

                    // Add or update the test
                    return testIndex === -1
                        ? [...prevCheckedTests, updatedTest]
                        : prevCheckedTests.map((test, index) =>
                            index === testIndex ? updatedTest : test
                        );
                });
            } else if (FavouriteCode) {
                setfavcheckedTests(prevFavCheckedTests => {
                    const favIndex = prevFavCheckedTests.findIndex(test => test.FavouriteCode === FavouriteCode);

                    // Create the updated favourite object
                    const updatedFav = createUpdatedEntry(FavouriteCode, value);

                    // Add or update the favourite
                    return favIndex === -1
                        ? [...prevFavCheckedTests, updatedFav]
                        : prevFavCheckedTests.map((test, index) =>
                            index === favIndex ? updatedFav : test
                        );
                });
            }
        } else if (value === "InHouse") {
            if (testCode) {
                setCheckedTests(prevCheckedTests => {
                    const testIndex = prevCheckedTests.findIndex(test => test.testCode === testCode);

                    // Create the updated test object
                    const updatedTest = createUpdatedEntry(testCode, value);

                    // Add or update the test
                    return testIndex === -1
                        ? [...prevCheckedTests, updatedTest]
                        : prevCheckedTests.map((test, index) =>
                            index === testIndex ? updatedTest : test
                        );
                });
            } else if (FavouriteCode) {
                setfavcheckedTests(prevFavCheckedTests => {
                    const favIndex = prevFavCheckedTests.findIndex(test => test.FavouriteCode === FavouriteCode);

                    // Create the updated favourite object
                    const updatedFav = createUpdatedEntry(FavouriteCode, value);

                    // Add or update the favourite
                    return favIndex === -1
                        ? [...prevFavCheckedTests, updatedFav]
                        : prevFavCheckedTests.map((test, index) =>
                            index === favIndex ? updatedFav : test
                        );
                });
            }
        }
    };






    useEffect(() => {
        const params = {
            Register_Id: LabWorkbenchNavigation?.params?.RegistrationId,
            Patient_Id: LabWorkbenchNavigation?.params?.PatientId,
            RegisterType:LabWorkbenchNavigation?.params?.RegisterType
        };

        axios.get(`${UrlLink}OP/Lab_Request_TestDetails`, { params })
            .then((res) => {
                const ress = res.data;
              
                const IndividualRequestsarr = ress?.IndividualRequests;
                // Initialize checkedTests with all tests checked
                const initialCheckedTests = IndividualRequestsarr.map(test => ({ testCode: test.TestCode, amount: '' }));
                setCheckedTests(initialCheckedTests);
                const favcheckedTestsarr = ress?.FavouritesRequests;
                const favCheckedTestss = favcheckedTestsarr.map(test => ({ FavouriteCode: test.FavouriteCode, amount: '' }));
                setfavcheckedTests(favCheckedTestss);

                setIsTestGet(ress);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [UrlLink, LabWorkbenchNavigation?.params?.RegistrationId, LabWorkbenchNavigation?.params?.RegisterType,LabWorkbenchNavigation?.params?.PatientId]);










    const FavmodelIndivitualTestNameColumns = [
        {
            key: "Id",
            name: "S.NO",
            frozen: true,

        },
        {
            key: "TestName",
            name: "Test Name",


        },


    ];



    const IndivitualTestNameColumns = [
        {
            key: "Id",
            name: "S.NO",
            frozen: true,

        },
        {
            key: "TestName",
            name: "Test Name",
          

            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', padding: '5px', gap: '30px' }}>
                    <input
                        type="checkbox"
                        name='TestName'
                        id={`TestName-${params.row.TestCode}`}  // Unique ID based on TestCode
                        checked={checkedTests.some(test => test.testCode === params.row.TestCode)}
                        style={{ cursor: 'pointer' }}
                        onChange={handleInputChange}
                    />
                    <span>{params.row.TestName}</span>
                </div>
            ),
        },
        {
            key: "Amount",
            name: "Amount",
        },
        {
            key: "EnterAmount",
            name: "Enter Amount",
            width:300,
           
            renderCell: (params) => {
                // const isChecked = checkedTests.some(test => test.testCode === params.row.TestCode);
                return (
                    <div className="RegisForm_1" style={{ padding: '5px' }}>
                        <input
                            type="number"
                            name='EnterAmount'
                            id={`EnterAmount-${params.row.TestCode}`}  // Unique ID based on TestCode
                            value={amounts[params.row.TestCode] || ''}
                            onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                            }
                            checked={checkedTests.some(test => test.testCode === params.row.TestCode)}
                            onChange={handleInputChange}
                            style={{ width: '100px' }}
                            disabled={!checkedTests.some(test => test.testCode === params.row.TestCode)}
                        />
                    </div>
                );
            },
        },
        {
            key: "Category",
            name: "Category",
            width:300,
           
            renderCell: (params) => {
                return (
                    <div className="RegisForm_1">

                        <input
                            type="radio"
                            id={`inhouse-${params.row.TestCode}`}  // Unique ID based on TestCode
                            name={`category-${params.row.TestCode}`}  // Unique name for each row
                            value="InHouse"
                            style={{ width: '15px' }}
                            checked={
                                Categorytype[params.row.TestCode] === "InHouse" ||
                                Categorytype[params.row.TestCode] === undefined  // Default to InHouse if not set
                            }
                            onChange={(e) => handleCategoryChange(e, params.row.TestCode)}
                        />
                        <label htmlFor={`inhouse-${params.row.TestCode}`}>InHouse</label>


                        <input
                            type="radio"
                            id={`outsource-${params.row.TestCode}`}  // Unique ID based on TestCode
                            name={`category-${params.row.TestCode}`}  // Unique name for each row
                            value="Outsource"
                            style={{ width: '15px' }}
                            checked={Categorytype[params.row.TestCode] === "Outsource"}
                            onChange={(e) => handleCategoryChange(e, params.row.TestCode)}
                        />
                        <label htmlFor={`outsource-${params.row.TestCode}`}>Outsource</label>

                    </div>
                );
            },
        },
        {
            key: "Address",
            name: "Address",
            width:300,
           
            renderCell: (params) => {
                return (
                    <div className="RegisForm_1">
                        {Categorytype[params.row.TestCode] === "Outsource" && (
                            <textarea
                                className="textarea"
                                value={AddressValues[params.row.TestCode] || ''}
                                onChange={(e) => handleAddressChange(e, params.row.TestCode)}
                                placeholder="Enter address"
                            />
                        )}
                    </div>
                );
            }
        },
        {
            key: "Location",
            name: "Location",
            width:300,
            
            renderCell: (params) => {
                return (
                    <div className="RegisForm_1">
                        {Categorytype[params.row.TestCode] === "Outsource" && (
                            <input
                                type="text"
                                value={LocationValues[params.row.TestCode] || ''}
                                onChange={(e) => handleLocationChange(e, params.row.TestCode)}
                                placeholder="Enter location"
                            />
                        )}
                    </div>
                );
            }
        }




    ];
  


    
    const handleModalVisible = (params) => {
        console.log("dd",params.TestNames);
        if(IsTestGet?.FavouritesRequests[0]?.TestNames){
          setSelectedRow(true); 
          setView(params.TestNames);
    
        }
        else{
          
          const tdata = {
            message: 'There is no data to view.',
            type: 'warn'
        };
        dispatchvalue({ type: 'toast', value: tdata });
        }
           
    
      }
    
    
    const FavouritesTestNameColumns = [
        {
            key: "Id",
            name: "S.NO",
            frozen: true,
        },
        {
            key: "FavouriteName",
            name: "Favourite Name",
        
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', padding: '5px', gap: '30px', marginRight: '30px' }}>
                    <input
                        type="checkbox"
                        name='FavouriteName'
                        id={`FavouriteName-${params.row.FavouriteCode}`}  // Unique ID based on FavouriteCode
                        checked={favcheckedTests.some(test => test.FavouriteCode === params?.row?.FavouriteCode)}
                        style={{ cursor: 'pointer' }}
                        onChange={handleInputChangeFavourites}
                    />
                    <span>{params?.row?.FavouriteName}</span>
                </div>
            ),
        },
        {
            key: "Amount",
            name: "Amount",
            
        },
        {
            key: "EnterAmount",
            name: "Enter Amount",
            width:300,
            renderCell: (params) => (
                <div className="RegisForm_1" style={{ padding: '5px' }}>
                    <input
                        name="EnterAmount"
                        id={`EnterAmount-${params?.row?.FavouriteCode}`}  // Unique ID based on FavouriteCode
                        value={amounts[params?.row?.FavouriteCode] || ''}  // Set the input value based on the FavouriteCode
                        onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()  // Prevent certain keys (e, E, +, -) from being entered
                        }
                        onChange={handleInputChangeFavourites}  // Handle the change event with your defined function
                        style={{ width: '100px' }}  // Set the width of the input field
                        disabled={!favcheckedTests.some(test => test.FavouriteCode === params.row.FavouriteCode)}  // Disable input if the checkbox is not checked
                    />
                </div>
            ),
        },
        {
            key: "Category",
            name: "Category",
            width:300,
        
            renderCell: (params) => (
                <div className="RegisForm_1">
                    <input
                        type="radio"
                        id={`inhouse-${params.row.FavouriteCode}`}  // Unique ID based on FavouriteCode
                        name={`category-${params.row.FavouriteCode}`}  // Unique name for each row
                        value="InHouse"
                        style={{ width: '15px' }}
                        checked={
                            Categorytype[params.row.FavouriteCode] === "InHouse" ||
                            Categorytype[params.row.FavouriteCode] === undefined  // Default to InHouse if not set
                        }
                        onChange={(e) => handleCategoryChange(e, null, params.row.FavouriteCode)}
                    />
                    <label htmlFor={`inhouse-${params.row.FavouriteCode}`}>InHouse</label>

                    <input
                        type="radio"
                        id={`outsource-${params.row.FavouriteCode}`}  // Unique ID based on FavouriteCode
                        name={`category-${params.row.FavouriteCode}`}  // Unique name for each row
                        value="Outsource"
                        style={{ width: '15px' }}
                        checked={Categorytype[params.row.FavouriteCode] === "Outsource"}
                        onChange={(e) => handleCategoryChange(e, null, params.row.FavouriteCode)}
                    />
                    <label htmlFor={`outsource-${params.row.FavouriteCode}`}>Outsource</label>
                </div>
            ),
        },
        {
            key: "Address",
            name: "Address",
            width:300,
            renderCell: (params) => (
                <div className="RegisForm_1">
                    {Categorytype[params.row.FavouriteCode] === "Outsource" && (
                        <textarea
                            className="textarea"
                            value={AddressValues[params.row.FavouriteCode] || ''}
                            onChange={(e) => handleAddressChange(e, null, params.row.FavouriteCode)}
                        />
                    )}
                </div>
            ),
        },
        {
            key: "Location",
            name: "Location",
            width:300,
            renderCell: (params) => (
                <div className="RegisForm_1">
                    {Categorytype[params.row.FavouriteCode] === "Outsource" && (
                        <input
                            type="text"
                            value={LocationValues[params.row.FavouriteCode] || ''}
                            onChange={(e) => handleLocationChange(e, null, params.row.FavouriteCode)}
                        />
                    )}
                </div>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleModalVisible(params.row)}
                >
                    <VisibilityIcon className="check_box_clrr_cancell" />
                </Button>
            ),
        },
    ];







    const handleSubmitRequest = () => {
        const data = {
            RegistrationId: LabWorkbenchNavigation?.params?.RegistrationId,
            RegisterType: LabWorkbenchNavigation?.params?.RegisterType,
            created_by: userRecord?.username || "",
            FavouritesArr: favcheckedTests || [],
            IndividualArr: checkedTests || [],
            uncheckedTestsArr: uncheckedTests || [],
        };

        console.log("12345", data);

        // Function to check if an amount is valid (greater than 0)
        const isValidAmount = (test) => test.amount > 0;

        // Check for invalid amounts in checkedTests and favcheckedTests
        const invalidCheckedTests = checkedTests.filter(test => !isValidAmount(test));
        const invalidFavCheckedTests = favcheckedTests.filter(test => !isValidAmount(test));
        if (IsTestGet?.IndividualRequests.length === checkedTests.length && IsTestGet?.FavouritesRequests.length === favcheckedTests.length) {
            setUncheckedTests([]);
        }
        if (invalidCheckedTests.length > 0 || invalidFavCheckedTests.length > 0) {
            dispatchvalue({
                type: "toast",
                value: { message: "Please enter a valid amount for all checked tests and favorite tests.", type: "warn" }
            });
        } else {
            console.log("postdata", data);
            axios.post(`${UrlLink}OP/Lab_SelectedTest_Detailslink`, data)
                .then((res) => {
                    const { data } = res;
                    const type = Object.keys(data)[0];
                    const message = Object.values(data)[0];
                    dispatchvalue({ type: "toast", value: { message, type } });
                    setCheckedTests([]);
                    setfavcheckedTests([]);
                    setUncheckedTests([]);
                    setShowTextarea(false);
                    setAddressValues({});
                    setLocationValues({});
                    setCategorytype("");
                })
                .catch((err) => {
                    console.error("Error during request:", err);
                    dispatchvalue({
                        type: "toast",
                        value: { message: "Error during request.", type: "warn" }
                    });
                });
        }
    };



    const [uncheckedTests, setUncheckedTests] = useState([]);
    console.log("uncheckedTests", uncheckedTests) // State to store unchecked tests and reasons

    const handleInputChangeFavourites = (e) => {
        const { name, id, value, checked } = e.target;
       
        const FavouriteCode = +id.split('-')[1];
      

        if (name === 'FavouriteName') {
            setfavcheckedTests(prevfavCheckedTests => {
                const updatedfavCheckedTests = [...prevfavCheckedTests];
                const testIndex = updatedfavCheckedTests.findIndex(test => test.FavouriteCode === FavouriteCode);

                if (checked) {
                    if (testIndex === -1) {
                        updatedfavCheckedTests.push({ FavouriteCode, amount: amounts[FavouriteCode] || '' });
                        uncheckedTests.splice(testIndex, 1);
                    }
                } else {
                    if (testIndex > -1) {
                        updatedfavCheckedTests.splice(testIndex, 1);
                        setAmounts(prevAmounts => ({
                            ...prevAmounts,
                            [FavouriteCode]: ""
                        }));

                        setUncheckedTests(prevUncheckedTests => [
                            ...prevUncheckedTests,
                            { FavouriteCode, reason: '' }
                        ]);
                    }

                }


                return updatedfavCheckedTests;
            });
        }

        if (name === 'EnterAmount') {
            setAmounts(prevAmounts => ({
                ...prevAmounts,
                [FavouriteCode]: value
            }));

            setfavcheckedTests(prevfavCheckedTests => {
                return prevfavCheckedTests.map(test =>
                    test.FavouriteCode === FavouriteCode ? { ...test, amount: value } : test
                );
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, id, value, checked } = e.target;
        const testCode = id.split('-')[1];

        if (name === 'TestName') {
            setCheckedTests(prevCheckedTests => {
                let updatedCheckedTests = [...prevCheckedTests];
                const testIndex = updatedCheckedTests.findIndex(test => test.testCode === testCode);

                if (checked) {
                    if (testIndex === -1) {
                        updatedCheckedTests.push({ testCode, amount: amounts[testCode] || '' });
                        uncheckedTests.splice(testIndex, 1);
                    }
                } else {
                    if (testIndex > -1) {
                        updatedCheckedTests.splice(testIndex, 1);
                        setAmounts(prevAmounts => ({
                            ...prevAmounts,
                            [testCode]: ""
                        }));
                    }
                    setShowTextarea(true);
                    setUncheckedTests(prevUncheckedTests => [
                        ...prevUncheckedTests,
                        { testCode, reason: '' }
                    ]);
                }


                return updatedCheckedTests;
            });


        }




        if (name === 'EnterAmount') {
            setAmounts(prevAmounts => ({
                ...prevAmounts,
                [testCode]: value
            }));

            setCheckedTests(prevCheckedTests => {
                return prevCheckedTests.map(test =>
                    test.testCode === testCode ? { ...test, amount: value } : test
                );
            });
        }
    };

    const handleReasonChange = (e, testCode) => {
        const newReason = e.target.value;


        // Update the reason in the state object
        setReason(prevReason => ({
            ...prevReason,
            [testCode]: newReason
        }));

        // Update the uncheckedTests array with the new reason
        setUncheckedTests(prevUncheckedTests =>
            prevUncheckedTests.map(test =>
                test.testCode === testCode ? { ...test, reason: newReason } : test
            )
        );




    };



    const handleReasonChange1 = (e, FavouriteCode) => {
        const newReason = e.target.value;


        // Updatthe reason in the state object
        setReason(prevReason => ({
            ...prevReason,
            [FavouriteCode]: newReason
        }));

        // Update the uncheckedTests array with the new reason
        setUncheckedTests(prevUncheckedTests =>
            prevUncheckedTests.map(test =>
                test.FavouriteCode === FavouriteCode ? { ...test, reason: newReason } : test
            )
        );



    };


    // Example of how to display the textarea for the current test code

    const getReason = () => {
        const test = uncheckedTests[0];
        if (test) {
            return reason[test.testCode] || reason[test.FavouriteCode] || '';
        }
        return '';
    };

    // Determine the correct key for the reason update
    const handleChange = (e) => {
        const test = uncheckedTests[0];
        if (test) {
            if (test.testCode) {
                handleReasonChange(e, test.testCode);
            } else if (test.FavouriteCode) {
                handleReasonChange1(e, test.FavouriteCode);
            }
        }
    };
    useEffect(() => {
        // Update showTextarea based on the length of uncheckedTests
        setShowTextarea(uncheckedTests.length > 0);
    }, [uncheckedTests]);





    useEffect(() => {
        // Determine if all tests are unchecked to set showTextarea
        const allTests = [...checkedTests, ...favcheckedTests];
        const allUnchecked = allTests.every(test => test.amount === '');
        setShowTextarea(allUnchecked);
    }, [checkedTests, favcheckedTests]);

    return (
        <>
            <div className="Main_container_app">
              
                <div className="new-patient-registration-form">
                    <br />
                    <div className="dctr_info_up_head">
                    <div className="RegisFormcon ">
              <div className="dctr_info_up_head22">

              <img src={bgImg2} alt="Patient Profile" />

                <label>Profile</label>
              </div>
            </div>
                        <div className="RegisFormcon_1">
                            <div className="RegisForm_1 ">
                                <label htmlFor="PatientID">
                                    Patient ID <span>:</span>
                                </label>
                                <span className="dctr_wrbvh_pice" htmlFor="PatientID">
                                    {LabWorkbenchNavigation?.params?.PatientId}
                                </span>
                            </div>
                            <div className="RegisForm_1 ">
                                <label htmlFor="PatientName">
                                    Patient Name <span>:</span>{" "}
                                </label>
                                <span className="dctr_wrbvh_pice" htmlFor="PatientName">
                                    {LabWorkbenchNavigation?.params?.PatientName}
                                </span>
                            </div>
                            <div className="RegisForm_1 ">
                                <label htmlFor="Age">
                                    Age <span>:</span>{" "}
                                </label>
                                <span className="dctr_wrbvh_pice" htmlFor="Age">
                                    {IsTestGet?.PatientAge}
                                </span>
                            </div>
                            <div className="RegisForm_1 ">
                                <label htmlFor="Gender">
                                    Gender <span>:</span>{" "}
                                </label>
                                <span className="dctr_wrbvh_pice" htmlFor="Gender">
                                    {IsTestGet?.PatientGender}
                                </span>
                            </div>
                            <div className="RegisForm_1 ">
                                <label htmlFor="PhoneNumber">
                                    Phone Number<span>:</span>{" "}
                                </label>
                                <span className="dctr_wrbvh_pice" htmlFor="PhoneNumber">
                                    {LabWorkbenchNavigation?.params?.PhoneNumber}
                                </span>
                            </div>
                            <div className="RegisForm_1 ">
                                <label htmlFor="DoctorShortName">
                                    Doctor Name<span>:</span>{" "}
                                </label>
                                <span className="dctr_wrbvh_pice" htmlFor="DoctorShortName">
                                    {LabWorkbenchNavigation?.params?.DoctorShortName}
                                </span>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <div className="new-navigation">
                 <div className="common_center_tag">
                    <span>Lab Request</span>
                </div>
                </div> 
                <br />
              

                {IsTestGet.IndividualRequests && (
                    <div className="common_center_tag">
                        <span>Individual Test Names</span>
                    </div>
                )}
                

                {IsTestGet.IndividualRequests && IsTestGet.IndividualRequests.length > 0 && (
                    <ReactGrid columns={IndivitualTestNameColumns} RowData={IsTestGet.IndividualRequests} />
                )}
                  <br />

                {IsTestGet.FavouritesRequests && (
                    <div className="common_center_tag">
                        <span>Favourites Names</span>
                    </div>
                )}


                {IsTestGet.FavouritesRequests && IsTestGet.FavouritesRequests.length > 0 && (
                    <ReactGrid columns={FavouritesTestNameColumns} RowData={IsTestGet.FavouritesRequests} />
                )}

{ selectedrow && view.length !==0 &&(
            <div className="loader" onClick={() => setSelectedRow(false)}>
            <div className="loader_register_roomshow"   onClick={(e) => e.stopPropagation()}>
            <br/>
            
            <div className="common_center_tag">
                <span>TestNames</span>
            </div>
            <br/>
            <br/>

            <div className="Main_container_app">
            <ReactGrid columns={FavmodelIndivitualTestNameColumns} RowData={view} />
            </div>
              
            </div>
            </div>
            )}




                {uncheckedTests.length > 0 && (
                    <div className="treatcon_body_1">
                        <label htmlFor="reason">Reason for not Selecting Test</label>
                        <span>:</span>
                        <textarea
                            className="treatcon_body_1 textarea"
                            id="reason"
                            name="reason"
                            value={getReason()} // Display the reason for the first unchecked test css add treatmentcomponent line 85
                            onChange={handleChange}
                        />
                    </div>
                )}





                <div className="Main_container_Btn">
                    <button
                        onClick={handleSubmitRequest}

                    >
                        Save
                    </button>
                </div>





                <ToastAlert Message={toast.message} Type={toast.type} />

                <ModelContainer />

            </div>
        </>
    );
};


export default LabRequest;


