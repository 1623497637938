
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';



const PurchaseOrder = () => {

    const dispatchvalue = useDispatch();
    const navigate = useNavigate();

    const UrlLink = useSelector(state => state.userRecord?.UrlLink);

    const toast = useSelector(state => state.userRecord?.toast);

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const EdittData = useSelector(state => state.Inventorydata?.PurchaseOrderList);


    const [LocationData, setLocationData] = useState([]);

    const [CategoryArray, setCategoryArray] = useState([])

    const [SubCategoryArray, setSubCategoryArray] = useState([])

    const [ProductArray, setProductArray] = useState([])

    const[supplierArray,setsupplierArray]=useState([])
    
    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];


    const [PurchaseOrder,setPurchaseOrder]=useState({
        SupplierId:'',
        SupplierName:'',
        SupplierMailId:'',
        SupplierContactNumber:'',
        SupplierContactPersion:'',
        OrderDate:currentDate,
        DeliveryExpectedDate:'',
        BillingLocation:'',
        BillingAddress:'',
        ShippingLocation:'',
        ShippingAddress:'',            
        TotalOrderValue:'',
    })

    
    const [ItemDetails,SetItemDetails]=useState({        
        ProductCategory:'',
        SubCategory:"", 
        ItemCode:'',
        ItemName:'',      
        PurchaseQty:'',
        TotalAmount:'',
    })

    const [POItemArrays,setPOItemArrays]=useState({})
    const [ProductListColumn, setProductListColumn] = useState({})


    // console.log('000------0000009999',POItemArrays,Object.keys(POItemArrays).length);
    
    const ClearItemState =()=>{
        SetItemDetails({        
            ProductCategory:'',
            SubCategory:"", 
            ItemCode:'',
            ItemName:'',      
            PurchaseQty:'',
            TotalAmount:'',
        })
    }

    const formatLabel = (label) => {

        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
        } else {
            return label;
        }
        };
    
    const getInputType = (name) => {
        if (['SupplierMailId'].includes(name)) return 'email';
        if(['OrderDate','DeliveryExpectedDate'].includes(name)) return 'date';
        if (['SupplierContactNumber', 'TotalOrderValue','PurchaseQty','TotalAmount'].includes(name)) return 'number';
        return 'text';
        };


        useEffect(() => {
            const updatePurchaseOrder = async () => {
              if (EdittData && Object.keys(EdittData).length !== 0) {
                console.log('?????????', EdittData);
          
                const {
                  id,
                  Use_BillingLocation,
                  Use_ShippingLocation,
                  Item_Details,
                  BillingLocation,
                  ShippingLocation,
                  PO_Status,
                  ...rest
                } = EdittData;
          
                let billAdd = await GetAddressbylocation(BillingLocation);

                let ShipAdd = await GetAddressbylocation(ShippingLocation);

          
                setPurchaseOrder((prev) => ({
                  PurchaseOrderNumber: id,
                  ...prev,
                  ...rest,
                  BillingLocation: BillingLocation,
                  BillingAddress:billAdd,
                  ShippingLocation: ShippingLocation,
                  ShippingAddress:ShipAdd,
                }));


                if(Object.keys(Item_Details).length !==0){

                    console.log('000',Item_Details);
                    
                    const RemoveDetailes =Object.keys(Item_Details).reduce((acc,key)=>{

                        acc[key]=Item_Details[key].map(item => {

                           const {
                            Reason,ReceivedQty,
                            BalanceQty,
                            ItemStatus,
                            ...rest} = item;
                            return rest;
                        })
                        return acc;
                    },{})

                    setPOItemArrays(RemoveDetailes)

                }
              }
            };
          
            updatePurchaseOrder();
          }, [EdittData]);
          

        useEffect(()=>{
            axios.get(`${UrlLink}Masters/Location_Detials_link`)
            .then((res) => {
                const ress = res.data
                setLocationData(ress)
            })
            .catch((err) => {
                console.log(err);
            })


        },[UrlLink])

// -----------------------------------


      
// -------------------------------------

        useEffect(()=>{

            axios.get(`${UrlLink}Inventory/Supplier_Data_Get?SupplierTwo=${true}`)
            .then((res)=>{
                // console.log('pppp----',res.data);
                let Rdata=res.data
                if(Array.isArray(Rdata)){                       
                    setsupplierArray(Rdata)
               
                }
            })
            .catch((err)=>{
                console.log(err);
                
            })
    
    },[UrlLink])


        useEffect(()=>{
            if(PurchaseOrder.SupplierId !== ''){
                axios.get(`${UrlLink}Inventory/Supplier_Product_Category_link?SupplierId=${PurchaseOrder.SupplierId}`)
                .then((res)=>{
                    let data=res.data
                    if(Array.isArray(data)){
                        setCategoryArray(data)
                    }
                })
                .catch((err)=>{
                    console.log(err);
                    
                })
            }
            else{
                setCategoryArray([])
            }
                
        },[PurchaseOrder.SupplierId])

       
        useEffect(() => {

            if(PurchaseOrder.SupplierId !=='' && ItemDetails.ProductCategory !==''){              
    
            axios.get(`${UrlLink}Inventory/Supplier_Product_Category_link?ProductCategory=${ItemDetails.ProductCategory}&SupplierId=${PurchaseOrder.SupplierId}`)
                .then((res) => {
                    console.log(res.data,'kkkkk');                    
                    setSubCategoryArray(Array.isArray(res.data) ? res.data : [])
                })
                .catch((err) => {
                    console.log(err);
    
                })
            }
    
        }, [UrlLink,PurchaseOrder.SupplierId, ItemDetails.ProductCategory])



        
        useEffect(() => {

            if(PurchaseOrder.SupplierId !=='' && ItemDetails.ProductCategory !=='' && ItemDetails.SubCategory !==''){
        
                axios.get(`${UrlLink}Inventory/Supplier_Product_Category_link?ProductCategory=${ItemDetails.ProductCategory}&SubCategory=${ItemDetails.SubCategory}&SupplierId=${PurchaseOrder.SupplierId}`)
                .then((res) => {
                    let data = res.data

                    if (Array.isArray(data) && data.length !== 0) {
                        setProductArray(data)
                    }
                    else {
                        setProductArray([])
                    }
        
                })
                .catch((err) => {
                    console.log(err)
                })
            }
            
        
        }, [UrlLink, ItemDetails,PurchaseOrder])



        useEffect(()=>{
            if(Object.keys(POItemArrays).length !==0){
                // console.log('POItemArrays',POItemArrays);

                let totalAmountFin=0;

                Object.keys(POItemArrays).forEach((categorykey)=>{
                    const categoryTotal = POItemArrays[categorykey].reduce((sum,item)=>{
                        return sum + (+item.TotalAmount || 0)
                    },0)

                    totalAmountFin += categoryTotal;
                })


                if(totalAmountFin){
                    setPurchaseOrder((prev)=>({
                        ...prev,
                        TotalOrderValue:totalAmountFin,
                    }))
                }                

            }
            else{

            setPurchaseOrder((prev)=>({
                ...prev,
                TotalOrderValue:'',
            }))
             
            }
        },[POItemArrays])



        const GetAddressbylocation = async (location) => {
            try {
                const res = await axios.get(`${UrlLink}Masters/Clinic_Detials_link?location=${+location}`);
                const result = res.data;
                
                if (result && Object.values(result).length !== 0) {
                    console.log('result-----', result.merged_address);
                    return result.merged_address; 
                }
                return '';  
            } catch (err) {
                console.log(err);
                return '';  
            }
        }


        const calculateDeliveryDate = (orderDate, leadTime) => {
             // Ensure orderDate is properly converted to a Date object
            const orderDateObj = new window.Date(orderDate);

            // Create a new Date object for deliveryDate
            const deliveryDate = new window.Date(orderDateObj);

            // Add the leadTime to the orderDate
            deliveryDate.setDate(orderDateObj.getDate() + leadTime);

            // Format the new DeliveryDate to "yyyy-MM-dd"
            const formattedDeliveryDate = deliveryDate.toISOString().split('T')[0];

            return formattedDeliveryDate;
        }


  const Getsinglesupp =async (SupplierId) => {

        try {
        const res = await axios.get(`${UrlLink}Inventory/Supplier_Data_Get?SupplierId=${SupplierId}`);
        const result = res.data;
        if (result && Object.values(result).length !== 0) {
        // console.log('result-----', result);
        return result;
        }

        }
        catch (err) {
        console.log(err);
        return '';  
        }

    }




    const HadelonchageSupplier = async (e)=>{

    const {name,value}=e.target

    if(name === 'SupplierId'){

    let find = supplierArray.find((ele)=>ele.id === value)

    if(find){

        let getobj = await Getsinglesupp(value)

        const dateCalulation= calculateDeliveryDate(currentDate,getobj?.LeadTime)


        setPurchaseOrder((prev)=>({
            ...prev,
            [name]:value,
            SupplierName:find.SupplierName,
            SupplierMailId:getobj?.EmailAddress || '',
            SupplierContactNumber:getobj?.ContactNumber || '',
            SupplierContactPersion:getobj?.ContactPerson || '', 
            DeliveryExpectedDate:dateCalulation || '',
            BillingLocation:'',
            BillingAddress:'',
            ShippingLocation:'',
            ShippingAddress:'',            
            TotalOrderValue:'',
        }))


    }
    else{
        setPurchaseOrder((prev)=>({
            ...prev,
            [name]:value,
            SupplierName:'',
            SupplierMailId:'',
            SupplierContactNumber:'',
            SupplierContactPersion:'',
            DeliveryExpectedDate:'',
            BillingLocation:'',
            BillingAddress:'',
            ShippingLocation:'',
            ShippingAddress:'',            
            TotalOrderValue:'',
        }))
    }


    }
    else if(name === 'SupplierName'){


    let find = supplierArray.find((ele)=>ele.SupplierName === value)

    if(find){

        let getobj = await Getsinglesupp(find?.id)

        const dateCalulation= calculateDeliveryDate(currentDate,getobj?.LeadTime)


        setPurchaseOrder((prev)=>({
            ...prev,
            [name]:value,
            SupplierId:find.id,
            SupplierName:find.SupplierName,
            SupplierMailId:getobj?.EmailAddress || '',
            SupplierContactNumber:getobj?.ContactNumber || '',
            SupplierContactPersion:getobj?.ContactPerson || '', 
            DeliveryExpectedDate:dateCalulation || '',
            BillingLocation:'',
            BillingAddress:'',
            ShippingLocation:'',
            ShippingAddress:'',            
            TotalOrderValue:'',
        }))


    }
    else{
        setPurchaseOrder((prev)=>({
            ...prev,
            [name]:value,
            SupplierId:'',
            SupplierMailId:'',
            SupplierContactNumber:'',
            SupplierContactPersion:'',
            DeliveryExpectedDate:'',
            BillingLocation:'',
            BillingAddress:'',
            ShippingLocation:'',
            ShippingAddress:'',            
            TotalOrderValue:'',
        }))
    }



    }    
    else if(name === 'BillingLocation'){

    const Adddata = await GetAddressbylocation(value);                

    if(Adddata){

    setPurchaseOrder((prev)=>({
        ...prev,
        [name]:value,
        BillingAddress:Adddata,
    }))

    }
    else{
        setPurchaseOrder((prev)=>({
            ...prev,
            [name]:value,
            BillingAddress:'',
        }))
    }
        

    }
    else if(name === 'ShippingLocation'){

    const Adddata = await GetAddressbylocation(value);                

    if(Adddata){

    setPurchaseOrder((prev)=>({
        ...prev,
        [name]:value,
        ShippingAddress:Adddata,
    }))

    }
    else{
        setPurchaseOrder((prev)=>({
            ...prev,
            [name]:value,
            ShippingAddress:'',
        }))
    }
        

    }
    else{
    setPurchaseOrder((prev)=>({
        ...prev,
        [name]:value
    })) 
    }         


    }



const HandleItemChange =(e)=>{

    const{name,value}=e.target


    if(name === 'ItemCode'){

    let finddata = ProductArray.find((ele)=>ele.id === value)
      
        if(finddata){
            const {ItemName,id,...rest }=finddata;
            SetItemDetails((prev)=>({
                ProductCategory:prev.ProductCategory,
                SubCategory:prev.SubCategory,
                [name]:value,
                ItemName:finddata?.ItemName || '',
                ...rest,
                PurchaseQty:'',
                TotalAmount: '',
            }))
        }
        else{

            SetItemDetails((prev)=>({
                ProductCategory:prev.ProductCategory,
                SubCategory:prev.SubCategory,
                [name]:value,
                ItemName:'',
                PurchaseQty:'',
                TotalAmount: '',
            }))

        }

    }
    else if(name === 'ItemName'){

    let finddata = ProductArray.find((ele)=>ele.ItemName === value)

            if(finddata){

                const {ItemName,id,...rest }=finddata;

                SetItemDetails((prev)=>({
                    ProductCategory:prev.ProductCategory,
                    SubCategory:prev.SubCategory,
                    ItemCode:finddata?.id || '',
                    [name]:value,
                    ...rest,
                    PurchaseQty:'',
                    TotalAmount: '',
                }))
            }
            else{

                SetItemDetails((prev)=>({
                    ProductCategory:prev.ProductCategory,
                    SubCategory:prev.SubCategory,                    
                    ItemCode:'',
                    [name]:value,
                    PurchaseQty:'',
                    TotalAmount: '',
                }))

            }

    }
    else if (name === 'PurchaseQty'){

        let TotalAm = +ItemDetails.PurchaseRateAfterGST * +value

        SetItemDetails((prev)=>({
            ...prev,
            [name]:value,
            TotalAmount:TotalAm.toFixed(2),
        }))
    }
    else if (name === 'ProductCategory'){

        SetItemDetails((prev)=>({
            [name]:value,
            SubCategory:"", 
            ItemCode:'',
            ItemName:'',      
            PurchaseQty:'',
            TotalAmount:'',
        }))
    

    }
    else if (name === 'SubCategory'){

        SetItemDetails((prev)=>({
            ProductCategory:prev.ProductCategory,
            [name]:value,
            ItemCode:'',
            ItemName:'',      
            PurchaseQty:'',
            TotalAmount:'',
        }))
    

    }
    else{
    
    SetItemDetails((prev)=>({
        ...prev,
        [name]:value,
    }))

    }

}


const handelsavePOProduct = () => {
    // Check for missing required fields
    const missingFields = [
        'ProductCategory',
        'SubCategory',
        'ItemCode',
        'ItemName',
        'PurchaseQty',
        'TotalAmount',
    ].filter(field => !ItemDetails[field]);

    if (missingFields.length !== 0) {
        const tdata = {
            message: `Please fill out all required fields: ${missingFields.join(', ')}`,
            type: 'warn',
        };
        dispatchvalue({ type: 'toast', value: tdata });
        return;
    }

    // Find the product category and subcategory
    const NamePC = CategoryArray.find((ele) => +ele.id === +ItemDetails.ProductCategory);
    const NameSUPC = SubCategoryArray.find((ele) => +ele.id === +ItemDetails.SubCategory);

    if (!NamePC) {
        const tdata = {
            message: `Select a valid product category`,
            type: 'warn',
        };
        dispatchvalue({ type: 'toast', value: tdata });
        return;
    }

    // Check if editing an existing item
    if (ItemDetails.id) {
        setPOItemArrays((prev) => {
            const categoryKey = NamePC.ProductCategoryName;
            const existingItems = prev[categoryKey] || [];
            const itemIndex = existingItems.findIndex((item) => item.id === ItemDetails.id);

            if (itemIndex !== -1) {
                const updatedItems = [...existingItems];
                updatedItems[itemIndex] = {
                    ...updatedItems[itemIndex],
                    ItemProductCategory: NamePC.ProductCategoryName,
                    ItemSubCategory: NameSUPC.SubCategoryName,
                    ...ItemDetails,
                };

                return {
                    ...prev,
                    [categoryKey]: updatedItems,
                };
            }

            return prev;  
        });

        ClearItemState();  
    } else {
        // Check for duplicate items
        const existingItems = POItemArrays[NamePC.ProductCategoryName] || [];
        const isDuplicate = existingItems.some((item) => item.ItemCode === ItemDetails.ItemCode);

        if (isDuplicate) {
            const tdata = {
                message: `Duplicate Entry`,
                type: 'warn',
            };
            dispatchvalue({ type: 'toast', value: tdata });
            return;
        }

        setPOItemArrays((prev) => {
            const categoryKey = NamePC.ProductCategoryName;
            const existingItems = prev[categoryKey] || [];

            const nextId = existingItems.length > 0
                ? Math.max(...existingItems.map(item => item.id)) + 1
                : 1;

            return {
                ...prev,
                [categoryKey]: [
                    ...existingItems,
                    {
                        id: nextId,
                        ItemProductCategory: NamePC.ProductCategoryName,
                        ItemSubCategory: NameSUPC.SubCategoryName,
                        ...ItemDetails,
                    }
                ]
            };
        });

        ClearItemState();  
    }
};






const HandelEditdata =(row)=>{
    console.log('row',row)

    const {ItemProductCategory,ItemSubCategory,...rest}= row ;

    SetItemDetails({
        ...rest, 
    })


}


const handleDeleteItem =(row)=>{

    const Categorykey = row.ItemProductCategory

    console.log('Categorykey',Categorykey);


    setPOItemArrays((prev)=>{
       
        const UpdateItems = prev[Categorykey].filter((ele)=>ele.id !== row.id);
        
        console.log('UpdateItems',UpdateItems);
        
        if (UpdateItems.length === 0){
            const updatedPOItemArrays = { ...prev };
            delete updatedPOItemArrays[Categorykey];
            return updatedPOItemArrays;
        }

        return{
            ...prev,
            [Categorykey]:UpdateItems,
        }
    })

    const tdata = {
        message: `${row.ItemName} has been deleted successfully.`,
        type: 'success'
    };
    dispatchvalue({ type: 'toast', value: tdata });

}

useEffect(() => {
    if (Object.keys(POItemArrays).length !== 0) {
        let columns = {};

        Object.keys(POItemArrays).forEach((keys) => {
            const ddd = POItemArrays[keys][0];
            const coll = [
                ...Object.keys(ddd ?? {}).filter(f => !['ProductCategory', 'SubCategory'].includes(f)).map((field) => {
                    return {
                        key: field,  
                        name: field === 'id' ? 'S.No' : field,  
                    };
                }),
                {
                    key: 'Action',
                    name: 'Action',
                    renderCell: (params) => (
                        <>
                        <Button className="cell_btn" onClick={() => HandelEditdata(params.row)}>
                            <EditIcon className="check_box_clrr_cancell" />
                        </Button>
                        <Button className="cell_btn" onClick={() => handleDeleteItem(params.row)}>
                        <DeleteOutlineIcon className="check_box_clrr_cancell" />
                        </Button>
                        </>
                    )
                }
            ];

            columns[keys] = coll;
        });

        setProductListColumn(columns);
    }
}, [POItemArrays]);




const SavePurchaseOrder =()=>{

    let requiredFields =[
        'SupplierName',
        'SupplierMailId',
        'SupplierContactNumber',
        'SupplierContactPersion',
        'OrderDate',
        'DeliveryExpectedDate',
        'BillingLocation',
        'BillingAddress',
        'ShippingLocation',
        'ShippingAddress',
        'TotalOrderValue'
    ]

    let missingFields =requiredFields.filter(
        (field)=>!PurchaseOrder[field]
    )
    if(missingFields.length !==0){

        const tdata={
          message: `Please fill out all required fields: ${missingFields.join(", ")}`,
          type: 'warn',
        }
        dispatchvalue({ type: 'toast', value: tdata });
    
    }
    else
    {

        let Senddata={
            ...PurchaseOrder,
            POItemArrays:POItemArrays,
            Create_by:userRecord?.username,
        }

        console.log('Senddata',Senddata);


        axios.post(`${UrlLink}Inventory/PurchaseOrder_Link`,Senddata)
        .then((res)=>{
            console.log(res.data);
            
            let resdata=res.data
            let type = Object.keys(resdata)[0]
            let mess = Object.values(resdata)[0]
            const tdata = {
                message: mess,
                type: type,
            }
            dispatchvalue({ type: 'toast', value: tdata });
            if(type ==='success'){
                navigate('/Home/PurchaseOrderList');
                dispatchvalue({ type:'PurchaseOrderList', value: {} })
            }
        })
        .catch((err)=>{
            console.log(err);            
        })
        

    }

}

  return (
    <>

<div className="Main_container_app">
        <h3>Purchase Order</h3>

        <br/>

        <div className="RegisFormcon_1">

        {
            Object.keys(PurchaseOrder).map((StateName,Index)=>{
                           
                return(
                    <div className="RegisForm_1" key={Index+'key'}>
                        
                        <label htmlFor={StateName}>{formatLabel(StateName)} :</label>
                        
                        {['BillingLocation','ShippingLocation'].includes(StateName) ?
                        
                        <select
                        type={getInputType(StateName)}
                        id={StateName}
                        name={StateName}
                        value={PurchaseOrder[StateName]}
                        onChange={HadelonchageSupplier}
                        >
                        <option value=''>Select</option>
                        {
                        LocationData.map((ele,ind)=>(
                        <option key={ind+'key'} value={ele.id} >{ele.locationName}</option>
                        ))
                        }
                        </select>
                        :                        
                        ['BillingAddress','ShippingAddress'].includes(StateName) ?
                        <textarea
                        type={getInputType(StateName)}
                        id={StateName}
                        name={StateName}
                        value={PurchaseOrder[StateName]}
                        onChange={HadelonchageSupplier}
                        disabled
                        >
                        </textarea>
                        :
                        <input
                         type={getInputType(StateName)}
                         id={StateName}
                         name={StateName}
                         value={PurchaseOrder[StateName]}
                         onChange={HadelonchageSupplier}
                         list={['SupplierId', 'SupplierName'].includes(StateName) ? StateName + 'List' : undefined}
                         min={StateName === 'DeliveryExpectedDate' ? currentDate :''}
                         disabled={!['DeliveryExpectedDate','SupplierId','SupplierName','ShippingLocation'].includes(StateName)}
                        />
                        }
                        {['SupplierId', 'SupplierName'].includes(StateName) && (
                        <datalist id={StateName + 'List'}>
                            {supplierArray.map((option, index) => (
                            <option key={index + 'key'} value={StateName === 'SupplierId' ? option.id : option.SupplierName} />
                            ))}
                        </datalist>
                        )}
                    </div>
                )
            })
        }
        

        </div>

        <br/>

        <div className="common_center_tag">
            <span>Item Details</span>
        </div>

        <br/>

        <div className="RegisFormcon_1">

        {
            Object.keys(ItemDetails).filter((ele)=>ele !== 'id').map((StateName2,Index)=>{
                return(
                    <div className="RegisForm_1" key={Index+'key'}>

                    <label htmlFor={StateName2}>{formatLabel(StateName2)} :</label>


                    {['ProductCategory','SubCategory'].includes(StateName2)?
                    
                    <select
                    type={getInputType(StateName2)}
                    id={StateName2}
                    name={StateName2}
                    value={ItemDetails[StateName2]}
                    onChange={HandleItemChange}
                    disabled={PurchaseOrder.SupplierId === ''}
                    >
                    <option value='' >Select</option>
                    {StateName2 === 'ProductCategory' ?
                        CategoryArray.map((ele, ind) => (
                            <option key={ind} value={ele.id}>{ele.ProductCategoryName}</option>
                        ))
                        :
                        SubCategoryArray.map((ele, ind) => (
                        <option key={ind} value={ele.id}>{ele.SubCategoryName}</option>
                        ))
                    }

                    </select>
                    
                    :
                    <>
                    <input
                     type={getInputType(StateName2)}
                     id={StateName2}
                     name={StateName2}
                     value={ItemDetails[StateName2]}
                     list={StateName2+'List'}
                     disabled={(!['PurchaseQty','ItemCode','ItemName'].includes(StateName2)) || 
                        (ItemDetails.SubCategory === '' ? ['ItemCode','ItemName','PurchaseQty'].includes(StateName2) :'')
                     }

                     onChange={HandleItemChange}
                    />

                    { ['ItemCode','ItemName'].includes(StateName2) ?
                    <datalist
                    id={StateName2+'List'}
                    >                    
                    {
                    ProductArray.map((option,index)=>(
                    <option key={index+'key'} value={StateName2 === 'ItemCode' ? option.id : option.ItemName}>                         
                    </option>
                    ))
                    } 
                    </datalist>
                    :
                    <></>
                    }
                    </>
                    
                    }
                    
                    </div>
                )
            })
        }

        </div>
    
      <br/>
      <div className="Main_container_Btn">
        <button onClick={handelsavePOProduct}>
            {ItemDetails.id ? 'Update' : 'Add' }
        </button>
        </div>
        <br/>

        {
            Object.keys(POItemArrays).map((keys, indx) => (
                POItemArrays[keys].length !== 0 &&
                <React.Fragment key={indx}>
                    <div className="common_center_tag">
                        <span> {keys} </span>
                    </div>
                    <br />
                    <ReactGrid columns={ProductListColumn[keys]} RowData={POItemArrays[keys]} />
                    <br />
                </React.Fragment>
            ))
        }

       <br/>
        {Object.keys(POItemArrays).length !== 0 &&
        <div className="Main_container_Btn">
            <button onClick={SavePurchaseOrder}>
                {PurchaseOrder.PurchaseOrderNumber ? 'Update' : 'Save' }
            </button>
            </div>
            }
        <br/>
       
    </div>



    <ToastAlert Message={toast.message} Type={toast.type} />
    </>
  )
}

export default PurchaseOrder;