import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import ListIcon from '@mui/icons-material/List';




const LocationMaster = () => {
  
  
    const UrlLink = useSelector(state => state.userRecord?.UrlLink);

    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const toast = useSelector(state => state.userRecord?.toast);
    const dispatchvalue = useDispatch();
    const [LocationData, setLocationData] = useState([]);

    const [LocationPage,setLocationPage]=useState('Locations')



      // ---------------location master

    const [LocationName, setLocationName] = useState({
        locationId: '',
        locationName: '',
        bedCount: '',


    });


   


    const [Locations, setLocations] = useState([])
    const [IsLocationget, setIsLocationget] = useState(false)

    const LocationsColumns = [
        {
            key: "id",
            name: "Location Id",
            frozen: true
        },
        {
            key: "created_by",
            name: "Created By ",
            frozen: true
        },
        {
            key: "locationName",
            name: "Location Name",
        },
        {
            key: "bedCount",
            name: "Bed Count",
        },
        {
            key: "Status",
            name: "Status",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => handleeditLocationstatus(params.row)}
                    >
                        {params.row.Status}
                    </Button>
                </>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => handleeditLocation(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            ),
        }


    ]

    const handleeditLocationstatus = (params) => {
        const data = {
            locationId: params.id,
            Statusedit: true
        }
        axios.post(`${UrlLink}Masters/Location_Detials_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                const tdata = {
                    message: mess,
                    type: typp,
                }

                dispatchvalue({ type: 'toast', value: tdata });
                setIsLocationget(prev => !prev)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    const handleeditLocation = (params) => {
        const { id, ...rest } = params
        setLocationName((prev) => ({
            ...prev,
            locationId: id,
            ...rest
        }))
    }

    const handleLocationsubmit = () => {
        if (LocationName.locationName && LocationName.bedCount) {
            const data = {
                ...LocationName,
                created_by: userRecord?.username || ''

                // created_by: LocationName.locationId ? LocationName.created_by : userRecord?.username || '',
            }
            axios.post(`${UrlLink}Masters/Location_Detials_link`, data)
                .then((res) => {
                    const resData = res.data;
                    const mess = Object.values(resData)[0];
                    const typp = Object.keys(resData)[0];
                    const tdata = {
                        message: mess,
                        type: typp,
                    }



                    dispatchvalue({ type: 'toast', value: tdata })
                    setIsLocationget(prev => !prev)
                    setLocationName({
                        locationId: '',
                        locationName: '',
                        bedCount: '',


                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            const tdata = {
                message: `Please provide both Location Name and Bed Count.`,
                type: 'warn'
            }
            dispatchvalue({ type: 'toast', value: tdata });
        }



    };


    useEffect(() => {
        axios.get(`${UrlLink}Masters/Location_Detials_link`)
            .then((res) => {
                const ress = res.data
                setLocations(ress)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [IsLocationget, UrlLink])



    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setLocationName((prev) => ({
    //         ...prev,
    //         [name]: name === 'locationName' ? value.toUpperCase() : value
    //     }));
    // };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocationName((prevState) => ({
            ...prevState,
            [name]: value?.toUpperCase()?.trim(),
        }));
    };

{/* -----------------------------Building------------------------------------------ */}



    const formatLabel = (label) => {
        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
        } else {
            return label;
        }
    };
    useEffect(() => {
        axios.get(`${UrlLink}Masters/Location_Detials_link`)
            .then((res) => {
                const ress = res.data
                setLocationData(ress)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [UrlLink])


     // Building name 
     const [BuildingName, setBuildingName] = useState({
        BuildingId: '',
        BuildingName: '',
        Location: '',
    })
    const [BuildingData, setBuildingData] = useState([])
    const [IsBuildingGet, setIsBuildingGet] = useState(false)
    const BuildingColumns = [
        {
            key: "id",
            name: "Building Id",
            frozen: true
        },
        {
            key: "Location_Name",
            name: "Location",
            frozen: true
        },
        {
            key: "BuildingName",
            name: "Building Name",
        },

        {
            key: "Status",
            name: "Status",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleEditBuildingStatus(params.row)}
                    >
                        {params.row.Status}
                    </Button>
                </>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleEditBuilding(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            ),
        }
    ]

    const HandleEditBuildingStatus = (params) => {
        const data = {
            BuildingId: params.id,
            Statusedit: true
        }
        const confirmation = window.confirm('Are you sure you want to update the status? All the children Block, Floor, Store, RoomType, room and bed statuses will be changed.');
        if (confirmation) {
        axios.post(`${UrlLink}Masters/Building_Master_Detials_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                const tdata = {
                    message: mess,
                    type: typp,
                }

                dispatchvalue({ type: 'toast', value: tdata });
                setIsBuildingGet(prev => !prev)
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }
    const HandleEditBuilding = (params) => {
        const { id, Location_Id, BuildingName } = params
        setBuildingName({
            BuildingId: id,
            BuildingName: BuildingName,
            Location: Location_Id,
        })
    }

    const HandleSaveBuilding = () => {
        if (BuildingName.BuildingName && BuildingName.Location) {


            const data = {
                ...BuildingName,
                created_by: userRecord?.username || ''
            }
            axios.post(`${UrlLink}Masters/Building_Master_Detials_link`, data)
                .then((res) => {
                    const resres = res.data
                    let typp = Object.keys(resres)[0]
                    let mess = Object.values(resres)[0]
                    const tdata = {
                        message: mess,
                        type: typp,
                    }

                    dispatchvalue({ type: 'toast', value: tdata });
                    setIsBuildingGet(prev => !prev)
                    setBuildingName({
                        BuildingId: '',
                        BuildingName: '',
                        Location: '',

                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            const tdata = {
                message: `Please provide both Store Name and Location.`,
                type: 'warn'
            }
            dispatchvalue({ type: 'toast', value: tdata });
        }
    }

    useEffect(() => {
        axios.get(`${UrlLink}Masters/Building_Master_Detials_link`)
            .then((res) => {
                const ress = res.data
                setBuildingData(ress)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [IsBuildingGet, UrlLink])


    //Block Name
    const [BlockName, setBlockName] = useState({
        BlockId: '',
        BuildingName: '',
        BlockName: '',
        Location: '',
    })
    const [BlockData, setBlockData] = useState([])
    const [Buildingby_loc, setBuildingby_loc] = useState([])
    useEffect(() => {
        if (BlockName.Location) {
            axios.get(`${UrlLink}Masters/get_building_Data_by_location?Location=${BlockName.Location}`)
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setBuildingby_loc(res.data)
                    } else {
                        setBuildingby_loc([])
                    }
                })
                .catch(err => {
                    setBuildingby_loc([])
                    console.log(err);
                })
        }

    }, [BlockName.Location, UrlLink])

    const handlechangeBlock = (e) => {
        const { name, value } = e.target
        if (name === 'Location') {
            setBlockName((prev) => ({
                ...prev,
                [name]: value,
                BuildingName: '',
                BlockName: '',
            }))
        } else if (name === 'BuildingName') {
            setBlockName((prev) => ({
                ...prev,
                [name]: value,
                BlockName: '',
            }))
        } else {
            setBlockName((prev) => ({
                ...prev,
                [name]: value.toUpperCase()
            }))
        }
    }
    const BlockColumns = [
        {
            key: "id",
            name: "Block Id",
            frozen: true
        },
        {
            key: "Location_Name",
            name: "Location",
            frozen: true
        },
        {
            key: "BuildingName",
            name: "Building Name",
        },
        {
            key: "BlockName",
            name: "Block Name",
        },

        {
            key: "Status",
            name: "Status",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleEditBlockStatus(params.row)}
                    >
                        {params.row.Status}
                    </Button>
                </>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleEditBlock(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            ),
        }
    ]

    const HandleEditBlockStatus = (params) => {
        const data = {
            BlockId: params.id,
            Statusedit: true
        }
        const confirmation = window.confirm('Are you sure you want to update the status? All the children Floor, Store, RoomType, room and bed statuses will be changed.');
        if (confirmation) {
        axios.post(`${UrlLink}Masters/Block_Master_Detials_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                console.log(resres);
                const tdata = {
                    message: mess,
                    type: typp,
                }

                dispatchvalue({ type: 'toast', value: tdata });
                setIsBuildingGet(prev => !prev)
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }
    const HandleEditBlock = (params) => {
        const { id, Location_Id, BuildingId, BlockName } = params
        setBlockName({
            BlockId: id,
            BlockName: BlockName,
            BuildingName: BuildingId,
            Location: Location_Id,
        })
    }

    const HandleSaveBlock = () => {
        if (BlockName.Location && BlockName.BuildingName && BlockName.BlockName) {


            const data = {
                ...BlockName,
                created_by: userRecord?.username || ''
            }
            axios.post(`${UrlLink}Masters/Block_Master_Detials_link`, data)
                .then((res) => {
                    const resres = res.data
                    let typp = Object.keys(resres)[0]
                    let mess = Object.values(resres)[0]
                    const tdata = {
                        message: mess,
                        type: typp,
                    }

                    dispatchvalue({ type: 'toast', value: tdata });
                    setIsBuildingGet(prev => !prev)
                    setBlockName(
                        {
                            BlockId: '',
                            BlockName: '',
                            BuildingName: '',
                            Location: '',

                        })
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            const tdata = {
                message: `Please provide both Building Name, Block Name and Location.`,
                type: 'warn'
            }
            dispatchvalue({ type: 'toast', value: tdata });
        }
    }

    useEffect(() => {
        axios.get(`${UrlLink}Masters/Block_Master_Detials_link`)
            .then((res) => {
                const ress = res.data
                setBlockData(ress)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [IsBuildingGet, UrlLink])



    //Floor Name
    const [FloorName, setFloorName] = useState({
        FloorId: '',
        BuildingName: '',
        BlockName: '',
        FloorName: '',
        Location: '',
    })
    const [FloorData, setFloorData] = useState([])
    const [BLockby_Building_loc, setBLockby_Building_loc] = useState([])
    const [BLockby_Building, setBLockby_Building] = useState([])
    useEffect(() => {
        if (FloorName.Location) {
            axios.get(`${UrlLink}Masters/get_building_Data_by_location?Location=${FloorName.Location}`)
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setBLockby_Building_loc(res.data)
                    } else {
                        setBLockby_Building_loc([])
                    }
                })
                .catch(err => {
                    setBLockby_Building_loc([])
                    console.log(err);
                })
        }

    }, [FloorName.Location, UrlLink])

    useEffect(() => {
        if (FloorName.BuildingName) {
            const data = {
                Building: FloorName.BuildingName,
            }
            axios.get(`${UrlLink}Masters/get_block_Data_by_Building`, { params: data })
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setBLockby_Building(res.data)
                    } else {
                        setBLockby_Building([])
                    }
                })
                .catch(err => {
                    setBLockby_Building([])
                    console.log(err);
                })
        }

    }, [FloorName.BuildingName, UrlLink])

    const handlechangeFloor = (e) => {
        const { name, value } = e.target
        if (name === 'Location') {
            setFloorName((prev) => ({
                ...prev,
                [name]: value,
                BuildingName: '',
                BlockName: '',
                FloorName: '',
            }))
        } else if (name === 'BuildingName') {
            setFloorName((prev) => ({
                ...prev,
                [name]: value,
                BlockName: '',
                FloorName: '',
            }))
        } else if (name === 'BlockName') {
            setFloorName((prev) => ({
                ...prev,
                [name]: value,
                FloorName: '',
            }))
        } else {
            setFloorName((prev) => ({
                ...prev,
                [name]: value.toUpperCase()
            }))
        }

    }
    const FloorColumns = [
        {
            key: "id",
            name: "Floor Id",
            frozen: true
        },
        {
            key: "Location_Name",
            name: "Location",
            frozen: true
        },
        {
            key: "BuildingName",
            name: "Building Name",
        },
        {
            key: "BlockName",
            name: "Block Name",
        },
        {
            key: "FloorName",
            name: "Floor Name",
        },

        {
            key: "Status",
            name: "Status",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleEditFloorStatus(params.row)}
                    >
                        {params.row.Status}
                    </Button>
                </>
            ),
        },
        {
            key: "Action",
            name: "Action",
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => HandleEditFloor(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            ),
        }
    ]

    const HandleEditFloorStatus = (params) => {
        const data = {
            FloorId: params.id,
            Statusedit: true
        }
        const confirmation = window.confirm('Are you sure you want to update the status? All the children Store and RoomType and room and bed statuses will be changed.');
        if (confirmation) {
        axios.post(`${UrlLink}Masters/Floor_Master_Detials_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                console.log(resres);
                const tdata = {
                    message: mess,
                    type: typp,
                }

                dispatchvalue({ type: 'toast', value: tdata });
                setIsBuildingGet(prev => !prev)
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    const HandleEditFloor = (params) => {
        const { id, Location_Id, BlockId, BuildingId, FloorName } = params
        setFloorName({
            FloorId: id,
            BlockName: BlockId,
            BuildingName: BuildingId,
            FloorName: FloorName,
            Location: Location_Id,
        })
    }

    const HandleSaveFloor = () => {
        const exist = Object.keys(FloorName).filter(p => p !== 'FloorId').filter((field) => !FloorName[field])
        if (exist.length === 0) {


            const data = {
                ...FloorName,
                created_by: userRecord?.username || ''
            }
            axios.post(`${UrlLink}Masters/Floor_Master_Detials_link`, data)
                .then((res) => {
                    const resres = res.data
                    let typp = Object.keys(resres)[0]
                    let mess = Object.values(resres)[0]
                    const tdata = {
                        message: mess,
                        type: typp,
                    }

                    dispatchvalue({ type: 'toast', value: tdata });
                    setIsBuildingGet(prev => !prev)
                    setFloorName({
                        FloorId: '',
                        BuildingName: '',
                        BlockName: '',
                        FloorName: '',
                        Location: '',

                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            const tdata = {
                message: `Please provide ${exist.join(' and ')}`,
                type: 'warn'
            }
            dispatchvalue({ type: 'toast', value: tdata });
        }
    }

    useEffect(() => {
        axios.get(`${UrlLink}Masters/Floor_Master_Detials_link`)
            .then((res) => {
                const ress = res.data
                setFloorData(ress)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [IsBuildingGet, UrlLink])


// -------------------------------------- ward Name


// ward name ------------

const [WardName, setWardName] = useState({
    Location: '',
    BuildingName: '',
    BlockName: '',
    FloorName: '',
    WardName: '',
    StorageZone:false,
    WardId: '',
})
const [WardData, setWardData] = useState([])
const [ward_Building_by__loc, setward_Building_by__loc] = useState([])
const [ward_block_by_Building, setward_block_by_Building] = useState([])
const [ward_Floor_by_Block, setward_Floor_by_Block] = useState([])

console.log('WardName',WardName);

const [StorageZoneConditions, setStorageZoneConditions] = useState([
    {
      id: 'raiseApprove',
      label: 'Indent Raise Approve',
      exists: true,
    },
    {
        id: 'issueApprove',
        label: 'Indent Issue Approve',
        exists: true,
    },
    {
        id: 'receiveApprove',
        label: 'Indent Receive Approve',
        exists: true,
    },
    {
        id: 'returnApprove',
        label: 'Indent Return Approve',
        exists: true,
    },
  ]);



  const handleCheckbox = (index) => {
    const updatedConditions = StorageZoneConditions.map((item, idx) => {
      
      if (idx === index) {
        const newExistsState = !item.exists;

        return {
          ...item,
          exists: newExistsState,
        };
      }
  
      return item;
    });
  
    setStorageZoneConditions(updatedConditions);
  };
  
  
  

useEffect(() => {
    if (WardName.Location) {
        axios.get(`${UrlLink}Masters/get_building_Data_by_location?Location=${WardName.Location}`)
            .then(res => {
                if (Array.isArray(res.data)) {
                    setward_Building_by__loc(res.data)
                } else {
                    setward_Building_by__loc([])
                }
            })
            .catch(err => {
                setward_Building_by__loc([])
                console.log(err);
            })
    }

}, [WardName.Location, UrlLink])

useEffect(() => {
    if (WardName.BuildingName) {
        const data = {
            Building: WardName.BuildingName,
        }
        axios.get(`${UrlLink}Masters/get_block_Data_by_Building`, { params: data })
            .then(res => {
                if (Array.isArray(res.data)) {
                    setward_block_by_Building(res.data)
                } else {
                    setward_block_by_Building([])
                }
            })
            .catch(err => {
                setward_block_by_Building([])
                console.log(err);
            })
    }

}, [WardName.BuildingName, UrlLink])

useEffect(() => {
    if (WardName.BlockName) {
        const data = {
            Block: WardName.BlockName,
        }
        axios.get(`${UrlLink}Masters/get_Floor_Data_by_Building_block_loc`, { params: data })
            .then(res => {
                if (Array.isArray(res.data)) {
                    setward_Floor_by_Block(res.data)
                } else {
                    setward_Floor_by_Block([])
                }

            })
            .catch(err => {
                setward_Floor_by_Block([])
                console.log(err);
            })
    }

}, [WardName.BlockName, UrlLink])


const handlechangeWard = (e) => {
    const { name, value } = e.target
    if (name === 'Location') {
        setWardName((prev) => ({
            ...prev,
            [name]: value,
            BuildingName: '',
            BlockName: '',
            FloorName: '',
            WardName: '',
            StorageZone:false,
        }))
    } else if (name === 'BuildingName') {
        setWardName((prev) => ({
            ...prev,
            [name]: value,
            BlockName: '',
            FloorName: '',
            WardName: '',
            StorageZone:false,
        }))
    } else if (name === 'BlockName') {
        setWardName((prev) => ({
            ...prev,
            [name]: value,
            FloorName: '',
            WardName: '',
            StorageZone:false,
        }))
    } else if (name === 'FloorName') {
        setWardName((prev) => ({
            ...prev,
            [name]: value,
            WardName: '',
            StorageZone:false,
        }))
    } else {
        setWardName((prev) => ({
            ...prev,
            [name]: value.toUpperCase()
        }))
    }

}

const WardColumns = [
    {
        key: "id",
        name: "Ward Id",
        frozen: true
    },
    {
        key: "Location_Name",
        name: "Location",
        frozen: true
    },
    {
        key: "BuildingName",
        name: "Building Name",
    },
    {
        key: "BlockName",
        name: "Block Name",
    },
    {
        key: "FloorName",
        name: "Floor Name",
    },
    {
        key: "WardName",
        name: "Ward Name",
    },

    {
        key:"Storage Access",
        name:"Storage Access",
        renderCell:(params)=> (
            <>
             <Button className="cell_btn"
                onClick={() => ItemView(params.row)}
                title='Add new Products'
            >
                <ListIcon className="check_box_clrr_cancell" />
            </Button>
            </>
        )
    },

    {
        key: "Status",
        name: "Status",
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandleEditWardStatus(params.row)}
                >
                    {params.row.Status}
                </Button>
            </>
        ),
    },
    {
        key: "Action",
        name: "Action",
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandleEditWard(params.row)}
                >
                    <EditIcon className="check_box_clrr_cancell" />
                </Button>
            </>
        ),
    }
]

const HandleEditWardStatus = (params) => {
    const data = {
        WardId: params.id,
        Statusedit: true
    }
    const confirmation = window.confirm('Are you sure you want to update the status? All the children RoomType and room and bed statuses will be changed.');
    if (confirmation) {
    axios.post(`${UrlLink}Masters/Ward_Master_Detials_link`, data)
        .then((res) => {
            const resres = res.data
            let typp = Object.keys(resres)[0]
            let mess = Object.values(resres)[0]
            const tdata = {
                message: mess,
                type: typp,
            }

            dispatchvalue({ type: 'toast', value: tdata });
            setIsBuildingGet(prev => !prev)
        })
        .catch((err) => {
            console.log(err);
        })
    }
}
const HandleEditWard = (params) => {
    const { id, StorageZone , BuildingId, BlockId, FloorId, Location_Id, WardName,StorageZoneConditions } = params
    setWardName({
        Location: Location_Id,
        BuildingName: BuildingId,
        BlockName: BlockId,
        FloorName: FloorId,
        WardName: WardName,
        StorageZone:StorageZone,
        WardId: id,
    })

    setStorageZoneConditions(StorageZoneConditions)
}

const HandleSaveWard = () => {
    const exist = Object.keys(WardName).filter(p => p !== 'WardId' && p !=='StorageZone').filter((field) => !WardName[field])
    if (exist.length === 0) {


        const data = {
            ...WardName,
            conditions:StorageZoneConditions,
            created_by: userRecord?.username || ''
        }
        axios.post(`${UrlLink}Masters/Ward_Master_Detials_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                const tdata = {
                    message: mess,
                    type: typp,
                }

                dispatchvalue({ type: 'toast', value: tdata });
                setIsBuildingGet(prev => !prev)
                setWardName({
                    Location: '',
                    BuildingName: '',
                    BlockName: '',
                    FloorName: '',
                    WardName: '',
                    StorageZone:false,
                    WardId: '',

                })
            })
            .catch((err) => {
                console.log(err);
            })
    } else {
        const tdata = {
            message: `Please provide ${exist.join(' and ')}`,
            type: 'warn'
        }
        dispatchvalue({ type: 'toast', value: tdata });
    }
}

useEffect(() => {
    axios.get(`${UrlLink}Masters/Ward_Master_Detials_link`)
        .then((res) => {
            const ress = res.data
            setWardData(ress)
        })
        .catch((err) => {
            console.log(err);
        })
}, [IsBuildingGet, UrlLink])





// -------------------------------------------------IN-Location-------

const [StoreName, setStoreName] = useState({
    Location: '',
    BuildingName: '',
    BlockName: '',
    FloorName: '',
    StoreType:'',
    StoreName: '', 
    StoreId: '',
})

const [approvalConditions, setApprovalConditions] = useState([
    { 
      id: 'PurchaseOrder', 
      label: 'Purchase Order', 
      exists: false, 
      additionalOptions: [
        { id: 'PurchaseOrderApprove', label: 'Purchase Order Approve', checked: false }
      ] 
    },
    { 
      id: 'GoodsReceivedNote', 
      label: 'Goods Received Note', 
      exists: false, 
      additionalOptions: [
        { id: 'GoodsReceivedNoteApprove', label: 'Goods Received Note Approve', checked: false }
      ] 
    },
    { 
        id: 'QuickGoodsReceivedNote', 
        label: 'Quick Goods Received Note', 
        exists: false, 
        additionalOptions: [
            { id: 'QuickGoodsReceivedNoteApprove', label: 'Quick Goods Received Note Approve', checked: false },
        ] 
    },
    { 
        id: 'PurchaseReturn', 
        label: 'Purchase Return', 
        exists: false, 
        additionalOptions: [
          { id: 'PurchaseReturnApprove', label: 'Purchase Return Approve', checked: false }
        ] 
    },
    {
      id: 'Indent',
      label: 'Indent',
      exists: false,
      additionalOptions: [
        { id: 'raiseApprove', label: 'Raise Approve', checked: false },
        { id: 'issueApprove', label: 'Issue Approve', checked: false },
        { id: 'receiveApprove', label: 'Receive Approve', checked: false },
        {id: 'returnApprove',label: 'Return Approve', checked: false },
      ],
    },
  ]);

const [StoreData, setStoreData] = useState([])
const [Store_Building_by__loc, setStore_Building_by__loc] = useState([])
const [Store_block_by_Building, setStore_block_by_Building] = useState([])
const [Store_Floor_by_Block, setStore_Floor_by_Block] = useState([])

const [ViewSelectoption,setViewSelectoption]=useState([])
const [selectRoom, setselectRoom] = useState(false);


useEffect(() => {
    if (StoreName.Location) {
        axios.get(`${UrlLink}Masters/get_building_Data_by_location?Location=${StoreName.Location}`)
            .then(res => {
                if (Array.isArray(res.data)) {
                    
                    setStore_Building_by__loc(res.data)
                } else {
                    setStore_Building_by__loc([])
                }
            })
            .catch(err => {
                setStore_Building_by__loc([])
                console.log(err);
            })
    }

}, [StoreName.Location, UrlLink])

useEffect(() => {
    if (StoreName.BuildingName) {
        const data = {
            Building: StoreName.BuildingName,
        }
        axios.get(`${UrlLink}Masters/get_block_Data_by_Building`, { params: data })
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStore_block_by_Building(res.data)
                } else {
                    setStore_block_by_Building([])
                }
            })
            .catch(err => {
                setStore_block_by_Building([])
                console.log(err);
            })
    }

}, [StoreName.BuildingName, UrlLink])

useEffect(() => {
    if (StoreName.BlockName) {
        const data = {
            Block: StoreName.BlockName,
        }
        axios.get(`${UrlLink}Masters/get_Floor_Data_by_Building_block_loc`, { params: data })
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStore_Floor_by_Block(res.data)
                } else {
                    setStore_Floor_by_Block([])
                }

            })
            .catch(err => {
                setStore_Floor_by_Block([])
                console.log(err);
            })
    }

}, [StoreName.BlockName, UrlLink])


const handlechangeStore = (e) => {

    const { name, value } = e.target
    if (name === 'Location') {
        setStoreName((prev) => ({
            ...prev,
            [name]: value,
            BuildingName: '',
            BlockName: '',
            FloorName: '',
            StoreType:'',
            StoreName: '',
        }))
    } else if (name === 'BuildingName') {
        setStoreName((prev) => ({
            ...prev,
            [name]: value,
            BlockName: '',
            FloorName: '',
            StoreType:'',
            StoreName: '',
        }))
    } else if (name === 'BlockName') {
        setStoreName((prev) => ({
            ...prev,
            [name]: value,
            FloorName: '',
            StoreType:'',
            StoreName: '',
        }))
    } else if (name === 'FloorName') {
        setStoreName((prev) => ({
            ...prev,
            [name]: value,
            StoreType:'',
            StoreName: '',
        }))
    } 
    else if (name === 'StoreType') {
        setStoreName((prev) => ({
            ...prev,
            [name]: value,
            StoreName: '',
        }))
    } 
    
    else {

        setStoreName((prev) => ({
            ...prev,
            [name]: value
        }))
    }

}


const ItemView =(row)=>{

console.log('row',row.getSingledata);

let select = row?.getSingledata

if(select && select.length !==0){
 setViewSelectoption(select)
 setselectRoom(true)
}
else {
    const tdata = {
        message: 'There is no select List to view.',
        type: 'warn'
    };
    dispatchvalue({ type: 'toast', value: tdata });

}


}

const StoreColumns = [
    {
        key: "id",
        name: "Store Id",
        frozen: true
    },
    {
        key: "Location_Name",
        name: "Location",
        frozen: true
    },
    {
        key: "BuildingName",
        name: "Building Name",
    },
    {
        key: "BlockName",
        name: "Block Name",
    },
    {
        key: "FloorName",
        name: "Floor Name",
    },
    
    {
        key: "StoreType",
        name: "Store Type",
    },
    {
        key: "StoreName",
        name: "Store Name",
    },
    {
        key:"Inventory Access",
        name:"Inventory Access",
        renderCell:(params)=> (
            <>
             <Button className="cell_btn"
                onClick={() => ItemView(params.row)}
                title='Add new Products'
            >
                <ListIcon className="check_box_clrr_cancell" />
            </Button>
            </>
        )
    },

    {
        key: "Status",
        name: "Status",
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandleEditStoreStatus(params.row)}
                >
                    {params.row.Status}
                </Button>
            </>
        ),
    },
    {
        key: "Action",
        name: "Action",
        renderCell: (params) => (
            <>
                <Button
                    className="cell_btn"
                    onClick={() => HandleEditStore(params.row)}
                >
                    <EditIcon className="check_box_clrr_cancell" />
                </Button>
            </>
        ),
    }
]

const HandleEditStoreStatus = (params) => {
    const data = {
        StoreId: params.id,
        Statusedit: true
    }
    const confirmation = window.confirm('Are you sure you want to update the status? ');
    if (confirmation) {
    axios.post(`${UrlLink}Masters/Inventory_Master_Detials_link`, data)
        .then((res) => {
            const resres = res.data
            let typp = Object.keys(resres)[0]
            let mess = Object.values(resres)[0]
            const tdata = {
                message: mess,
                type: typp,
            }

            dispatchvalue({ type: 'toast', value: tdata });
            setIsBuildingGet(prev => !prev)
        })
        .catch((err) => {
            console.log(err);
        })
    }
}
const HandleEditStore = (params) => {
    const { id, BuildingId, BlockId, FloorId, Location_Id,StoreType,StoreName ,approvalConditions} = params
    setStoreName({
        Location: Location_Id,
        BuildingName: BuildingId,
        BlockName: BlockId,
        FloorName: FloorId,
        StoreType:StoreType,
        StoreName: StoreName,
        StoreId: id,
    })

    setApprovalConditions(approvalConditions)
}

const HandleSaveStore = () => {
    const exist = Object.keys(StoreName).filter(p => p !== 'StoreId').filter((field) => !StoreName[field])

    const OPtionscheck = approvalConditions.some((ele)=>ele.exists === true)
    

    if (exist.length === 0 && OPtionscheck) {        

        const data = {
            ...StoreName,
            StoreName:StoreName.StoreName.toUpperCase(),
            created_by: userRecord?.username || '',
            approvalConditions:approvalConditions,
        }
        axios.post(`${UrlLink}Masters/Inventory_Master_Detials_link`, data)
            .then((res) => {
                const resres = res.data
                let typp = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                const tdata = {
                    message: mess,
                    type: typp,
                }

                dispatchvalue({ type: 'toast', value: tdata });
                setIsBuildingGet(prev => !prev)
                if(typp ===  'success'){
                setStoreName({
                    Location: '',
                    BuildingName: '',
                    BlockName: '',
                    FloorName: '',
                    StoreType:'',
                    StoreName: '',
                    StoreId: '',

                })
            }
            })
            .catch((err) => {
                console.log(err);
            })
    } 

    else if(!OPtionscheck){
        const tdata = {
            message: `Please Select The Inventory Access Options`,
            type: 'warn'
        }
        dispatchvalue({ type: 'toast', value: tdata });
    }
    
    else {
        const tdata = {
            message: `Please provide ${exist.join(' and ')}`,
            type: 'warn'
        }
        dispatchvalue({ type: 'toast', value: tdata });
    }
}

useEffect(() => {
    axios.get(`${UrlLink}Masters/Inventory_Master_Detials_link`)
        .then((res) => {
            const ress = res.data
            console.log(res.data,'234567');

            setStoreData(ress)
        })
        .catch((err) => {
            console.log(err);
        })
}, [IsBuildingGet, UrlLink])



// --------------------------------------------------------------






  const handleCheckboxChange = (index) => {
    const updatedConditions = approvalConditions.map((item, idx) => {
      
      if (idx === index) {
        const newExistsState = !item.exists;

        if (item.id === 'PurchaseOrder') {
            const goodsReceivedNote = approvalConditions.find(cond => cond.id === 'GoodsReceivedNote');
            if (goodsReceivedNote) {
              goodsReceivedNote.exists = newExistsState
              goodsReceivedNote.additionalOptions = goodsReceivedNote.additionalOptions.map(option => ({
                ...option,
                checked: newExistsState,
              }));
            }
          }
          if (item.id === 'GoodsReceivedNote') {
            const goodsReceivedNote = approvalConditions.find(cond => cond.id === 'PurchaseOrder');
            if (goodsReceivedNote) {
              goodsReceivedNote.exists = newExistsState
              goodsReceivedNote.additionalOptions = goodsReceivedNote.additionalOptions.map(option => ({
                ...option,
                checked: newExistsState,
              }));
            }
          }

        return {
          ...item,
          exists: newExistsState,
          additionalOptions: item.additionalOptions.map(option => ({
            ...option,
            checked: newExistsState, // Set to true if exists is checked
          })),
        };
      }
  
      return item;
    });
  
    setApprovalConditions(updatedConditions);
  };
  

  // Handle nested checkbox toggle for additional options
  const handleNestedCheckboxChange = (index, nestedIndex) => {
    const updatedConditions = approvalConditions.map((item, idx) => {
        
      if (idx === index) {
        const updatedOptions = item.additionalOptions.map((opt, nIdx) =>
          nIdx === nestedIndex ? { ...opt, checked: !opt.checked } : opt
        );
        return { ...item, additionalOptions: updatedOptions };
      }
      return item;
    });
    setApprovalConditions(updatedConditions);
  };
  
  
  return (
    <>
      <div className="Main_container_app">
      <h3>Locations Masters</h3>

      <div className="RegisterTypecon">
            <div className="RegisterType">
                {["Locations", "Building", "Block","Floor",'Ward',"Inventory location"].map((p, ind) => (
                    <div className="registertypeval" key={ind}>
                        <input
                            type="radio"
                            id={p}
                            name="appointment_type"
                            checked={LocationPage === p}
                            onChange={(e) => {
                                setLocationPage(e.target.value)
                            }}
                            value={p}
                        />
                        <label htmlFor={p}>
                            {p}
                        </label>
                    </div>
                ))}
            </div>
            
        </div>
        
        <br/>

      {/*---------------Location-----------------------------*/}
       {LocationPage === 'Locations' && <>
       <div className="common_center_tag">
       <span>Location Name</span>
        </div>
        <br/>
        <div className="RegisFormcon_1">
            <div className="RegisForm_1">
                <label> Location Name <span>:</span> </label>
                <input
                    type="text"
                    placeholder='Enter Location Name'
                    name='locationName'
                    required
                    value={LocationName.locationName}
                    onChange={handleInputChange}
                />
            </div>
            <div className="RegisForm_1">
                <label> Bed Count <span>:</span> </label>
                <input
                    type="number"
                    placeholder='Enter Bed Count'
                    name='bedCount'
                    required
                    value={LocationName.bedCount}
                    onChange={handleInputChange}
                />
            </div>
        </div>
        <br/>
        <div className="Main_container_Btn">
            <button onClick={handleLocationsubmit}>
                {LocationName.locationId ? 'Update' : 'Save'}
            </button>
        </div>
        <br/>
        {Locations.length > 0 &&
            <ReactGrid columns={LocationsColumns} RowData={Locations} />}
        </> }

         {/* ------------Building------------ */}
        {LocationPage === 'Building' && <>
        
        <div className="common_center_tag">
          <span>Building Name</span>
        </div>
        <br />
        <div className="RegisFormcon_1">
            <div className="RegisForm_1">
                <label> Location <span>:</span> </label>

                <select
                    name='Location'
                    required
                    disabled={BuildingName.BuildingId}
                    value={BuildingName.Location}
                    onChange={(e) => setBuildingName((prev) => ({ ...prev, Location: e.target.value, BuildingName: '' }))}
                >
                    <option value=''>Select</option>
                    {
                        LocationData.map((p, index) => (
                            <option key={index} value={p.id}>{p.locationName}</option>
                        ))
                    }
                </select>
            </div>
            <div className="RegisForm_1">
                <label> Building Name <span>:</span> </label>
                <input
                    type="text"
                    name='BuildingName'
                    autoComplete='off'
                    required
                    value={BuildingName.BuildingName}
                    onChange={(e) => setBuildingName((prev) => ({ ...prev, BuildingName: e.target.value.toUpperCase() }))}
                />
            </div>

        </div>
        <br />
        <div className="Main_container_Btn">
            <button onClick={HandleSaveBuilding}>
                {BuildingName.BuildingId ? "Update" : "Add"}
            </button>
        </div>
        <br />
        

        <ReactGrid columns={BuildingColumns} RowData={BuildingData} />
         
         </> }

         {/*-----------------------------Block Name-----------------------------------------------------------------------*/}

        {LocationPage === 'Block' && <>
         <div className="common_center_tag">
                    <span>Block Name</span>
        </div>
        <br />
        <div className="RegisFormcon_1">
            <div className="RegisForm_1">
                <label> Location <span>:</span> </label>

                <select
                    name='Location'
                    required
                    disabled={BlockName.BlockId}
                    value={BlockName.Location}
                    onChange={handlechangeBlock}
                >
                    <option value=''>Select</option>
                    {
                        LocationData.map((p, index) => (
                            <option key={index} value={p.id}>{p.locationName}</option>
                        ))
                    }
                </select>
            </div>

            <div className="RegisForm_1">
                <label> Building Name <span>:</span> </label>

                <select
                    name='BuildingName'
                    required
                    disabled={BlockName.BlockId}
                    value={BlockName.BuildingName}
                    onChange={handlechangeBlock}
                >
                    <option value=''>Select</option>
                    {
                        Buildingby_loc.map((p, index) => (
                            <option key={index} value={p.id}>{p.BuildingName}</option>
                        ))
                    }
                </select>
            </div>

            <div className="RegisForm_1">
                <label> Block Name <span>:</span> </label>
                <input
                    type="text"
                    name='BlockName'
                    autoComplete='off'
                    required
                    value={BlockName.BlockName}
                    onChange={handlechangeBlock}
                />
            </div>

        </div>
        <br />
        <div className="Main_container_Btn">
            <button onClick={HandleSaveBlock}>
                {BlockName.BlockId ? "Update" : "Add"}
            </button>
        </div>
        <br />
        <ReactGrid columns={BlockColumns} RowData={BlockData} />

        </> }
        {/*-----------------------------Floor Name-----------------------------------------------------------------------*/}

        {LocationPage === 'Floor' && <>
        <div className="common_center_tag">
            <span>Floor Name</span>
        </div>
        <br />
        <div className="RegisFormcon_1">
            <div className="RegisForm_1">
                <label> Location <span>:</span> </label>

                <select
                    name='Location'
                    required
                    disabled={FloorName.FloorId}
                    value={FloorName.Location}
                    onChange={handlechangeFloor}
                >
                    <option value=''>Select</option>
                    {
                        LocationData.map((p, index) => (
                            <option key={index} value={p.id}>{p.locationName}</option>
                        ))
                    }
                </select>
            </div>

            <div className="RegisForm_1">
                <label> Building Name <span>:</span> </label>

                <select
                    name='BuildingName'
                    required
                    disabled={FloorName.FloorId}
                    value={FloorName.BuildingName}
                    onChange={handlechangeFloor}
                >
                    <option value=''>Select</option>
                    {
                        BLockby_Building_loc.map((p, index) => (
                            <option key={index} value={p.id}>{p.BuildingName}</option>
                        ))
                    }
                </select>
            </div>
            <div className="RegisForm_1">
                <label> Block Name <span>:</span> </label>

                <select
                    name='BlockName'
                    required
                    disabled={FloorName.FloorId}
                    value={FloorName.BlockName}
                    onChange={handlechangeFloor}
                >
                    <option value=''>Select</option>
                    {
                        BLockby_Building.map((p, index) => (
                            <option key={index} value={p.id}>{p.BlockName}</option>
                        ))
                    }
                </select>
            </div>

            <div className="RegisForm_1">
                <label> Floor Name <span>:</span> </label>
                <input
                    type="text"
                    name='FloorName'
                    autoComplete='off'
                    required
                    value={FloorName.FloorName}
                    onChange={handlechangeFloor}
                />
            </div>

        </div>
        <br />
        <div className="Main_container_Btn">
            <button onClick={HandleSaveFloor}>
                {FloorName.FloorId ? "Update" : "Add"}
            </button>
        </div>
        <br />
        <ReactGrid columns={FloorColumns} RowData={FloorData} />
        </> }

    {/*-----------------------------Ward Name-----------------------------------------------------------------------*/}

         {LocationPage === 'Ward' &&
                <>
                <div className="common_center_tag">
                    <span>Ward Name</span>
                </div>
                <br/>
                <div className="RegisFormcon_1">
                    {Object.keys(WardName).filter(p => p !== 'WardId').map((field, indx) => (
                        <div className="RegisForm_1" key={indx}>
                            <label> {formatLabel(field)} <span>:</span> </label>
                            {
                                field === 'WardName' ?
                                    <input
                                        type="text"
                                        name={field}
                                        autoComplete='off'
                                        required
                                        value={WardName[field]}
                                        onChange={handlechangeWard}
                                    />
                                    :
                                    field === 'StorageZone' ?

                                    (<div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', width: '150px' }}>
                                        <label style={{ width: 'auto' }} htmlFor={`${field}_yes`}>
                                            <input
                                                required
                                                id={`${field}_yes`}
                                                type="radio"
                                                name={field}
                                                style={{ width: '15px' }}
                                                checked={WardName[field]}                                                
                                                disabled={WardName.WardName === ''}
                                                onChange={(e) => {
                                                    setWardName(prev => ({
                                                        ...prev,
                                                        [field]: true,
                                                    }))
                                                }}
                                            />
                                            Yes
                                        </label>
                                        <label style={{ width: 'auto' }} htmlFor={`${field}_No`}>
                                            <input
                                                required
                                                id={`${field}_No`}
                                                type="radio"
                                                name={field}
                                                style={{ width: '15px' }}
                                                checked={!WardName[field]}
                                                onChange={(e) => {
                                                    setWardName(prev => ({
                                                        ...prev,
                                                        [field]: false,
                                                    }))
                                                }}
                                            />
                                            No
                                        </label>
                                    </div>
                                    )

                                    :
                                    <select
                                        name={field}
                                        required
                                        disabled={WardName.WardId}
                                        value={WardName[field]}
                                        onChange={handlechangeWard}
                                    >
                                        <option value=''>Select</option>
                                        {field === 'BuildingName' &&
                                            ward_Building_by__loc.map((p, index) => (
                                                <option key={index} value={p.id}>{p.BuildingName}</option>
                                            ))
                                        }
                                        {field === 'BlockName' &&
                                            ward_block_by_Building.map((p, index) => (
                                                <option key={index} value={p.id}>{p.BlockName}</option>
                                            ))
                                        }
                                        {field === 'FloorName' &&
                                            ward_Floor_by_Block.map((p, index) => (
                                                <option key={index} value={p.id}>{p.FloorName}</option>
                                            ))
                                        }
                                        {field === 'Location' &&
                                            LocationData.map((p, index) => (
                                                <option key={index} value={p.id}>{p.locationName}</option>
                                            ))
                                        }
                                    </select>
                            }
                        </div>
                    ))}


                { WardName.StorageZone === true &&  
                        <>
                        
                        <div className="DivCenter_container">
                            Inventory Access
                        </div>
                        <br/>
                        <div className='displayuseraccess'>
                        
                        {StorageZoneConditions.map((condition, index) => (
                            <div key={condition.id}>
                            <label >
                            <input
                                type="checkbox"
                                checked={condition.exists}
                                onChange={() => handleCheckbox(index)}
                                disabled={WardName.StorageZone === false}
                            />
                            {condition.label}
                            </label>

                            <br />
                        </div>
                        ))}


                        </div>

                        </>      
                        
                    }
                </div>
                <br/>

                <div className="Main_container_Btn">
                    <button onClick={HandleSaveWard}>
                        {WardName.WardId ? "Update" : "Add"}
                    </button>
                </div>
                <br/>


                <ReactGrid columns={WardColumns} RowData={WardData} />
                </>
                }

        {LocationPage === 'Inventory location' && <>
        <div className="common_center_tag">
            <span>Store location</span>
        </div>
        <br />

        <div className="RegisFormcon_1">
                    {Object.keys(StoreName).filter(p => p !== 'StoreId').map((field, indx) => (
                        <div className="RegisForm_1" key={indx}>
                            <label> {formatLabel(field)} <span>:</span> </label>
                            {
                                field === 'StoreName' ?
                                    <input
                                    type="text"
                                    name={field}
                                    autoComplete='off'
                                    required
                                    value={StoreName[field]}
                                    onChange={handlechangeStore}
                                    />
                                    :
                                    <select
                                        name={field}
                                        required
                                        disabled={StoreName.FloorName === '' && field === 'StoreType'}
                                        value={StoreName[field]}
                                        onChange={handlechangeStore}
                                    >
                                        <option value=''>Select</option>
                                        {field === 'BuildingName' &&
                                            Store_Building_by__loc.map((p, index) => (
                                                <option key={index} value={p.id}>{p.BuildingName}</option>
                                            ))
                                        }
                                        {field === 'BlockName' &&
                                            Store_block_by_Building.map((p, index) => (
                                                <option key={index} value={p.id}>{p.BlockName}</option>
                                            ))
                                        }
                                        {field === 'FloorName' &&
                                            Store_Floor_by_Block.map((p, index) => (
                                                <option key={index} value={p.id}>{p.FloorName}</option>
                                            ))
                                        }
                                        {field === 'Location' &&
                                            LocationData.map((p, index) => (
                                                <option key={index} value={p.id}>{p.locationName}</option>
                                            ))
                                        }
                                        {field === 'StoreType' &&
                                            <>
                                            <option value='CENTRALSTORE'>CENTRAL STORE</option>
                                            <option value='SUBCENTRALSTORE'>SUB CENTRAL STORE</option>
                                            <option value='SUBSTORE'>SUB STORE</option>
                                            <option value='PHARMACY'>PHARMACY</option>
                                            </>
                                        }
                                    </select>
                            }
                        </div>
                    ))}
                </div>
                <br/>
               

            { StoreName.StoreType !== '' &&  <>
               
                <div className="DivCenter_container">
                    Inventory Access
                </div>
                <br/>
                <div className='displayuseraccess'>
                
                {approvalConditions.map((condition, index) => (
                    <div key={condition.id}  >
                    <label >
                    <input
                        type="checkbox"
                        checked={condition.exists}
                        onChange={() => handleCheckboxChange(index)}
                        disabled={StoreName.StoreName === ''}
                    />
                    {condition.label}
                    </label>

                    {condition.exists && condition.additionalOptions.length > 0 && (
                    <div style={{ marginLeft: '5px', marginTop: '5px' }}>
                        {condition.additionalOptions.map((option, nestedIndex) => (
                        <div key={option.id} style={{ marginLeft: '10px', marginTop: '5px' }}>
                            <label>
                            <input
                                type="checkbox"
                                checked={option.checked}
                                onChange={() => handleNestedCheckboxChange(index, nestedIndex)}
                            />
                            {option.label}
                            </label>
                        </div>
                        ))}
                    </div>
                    )}

                    <br />
                </div>
                ))}


                </div>

                </>      
                
            }
    

                <br/>
                <div className="Main_container_Btn">
                    <button onClick={HandleSaveStore}>
                        {StoreName.StoreId ? "Update" : "Add"}
                    </button>
                </div>
                
        <ReactGrid columns={StoreColumns} RowData={StoreData} />

        </> }



     </div>
        <ToastAlert Message={toast.message} Type={toast.type} />
    
        {selectRoom && ViewSelectoption.length !== 0 && (
                <div className="loader" onClick={() => setselectRoom(false)}>
                    <div className="loader_register_roomshow" onClick={(e) => e.stopPropagation()}>
                        <br />

                        <div className="common_center_tag">
                            <span>Inventory Access</span>
                        </div>
                        <br />
                        <div className='displayuseraccess'>
                        {
                            ViewSelectoption.map((keys,indx)=>(
                            
                            <div style={{marginLeft:'20px'}}  key={indx}>
                            <label
                                htmlFor={`${indx}_${keys}`}
                                className='par_acc_lab'
                            >
                                {`${indx + 1}. ${keys}`}
                            </label>
                            </div>
                                                                  
                            ))
                        }
                        </div>

                       

                    </div>
                </div>
            )}
    
    </>
  )
}

export default LocationMaster;
