
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModelContainer from '../../OtherComponent/ModelContainer/ModelContainer';
import axios from 'axios';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import { useNavigate } from 'react-router-dom';




const GoodsReceiptNote = () => {

  const dispatchvalue = useDispatch();
  const UrlLink = useSelector(state => state.userRecord?.UrlLink);
  const toast = useSelector(state => state.userRecord?.toast);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  
  const navigate = useNavigate();

  const today = new Date();
  const currentDate = today.toISOString().split('T')[0];
    
  const [LocationData, setLocationData] = useState([]);

  const [StoreData, setStoreData] = useState([])


  const POdata = useSelector(state => state.Inventorydata?.GoodsReceiptNoteList);

  const Editdata = useSelector(state => state.Inventorydata?.GoodsReceiptNoteEdit);


  const [selectRoom2, setselectRoom2] = useState(false);
  
  const [SingleSupplier,setSingleSupplier]=useState({})

  const [POItemArrays,setPOItemArrays]=useState({})
  const [ProductListColumn, setProductListColumn] = useState({})
  const [selectedKey, setSelectedKey] = useState("All");


  const [GRNItemlist,setGRNItemlist]=useState([])
  

  // console.log('POItemArrays----',POItemArrays);
  

  const [GRNState,setGRNState]=useState({
    GRNDate:currentDate,
    PONumber:'',
    SupplierCode:'',
    SupplierName:'',
    SupplierBillNumber:'',
    SupplierBillDate:'',
    SupplierBillDueDate:'',
    SupplierBillAmount:'',
    SupplierBillLocation:'',
    GRNLocation:'',
    StorageLocation:'',
    ReceivingPersonName:'',
  })

  const[SupplierBillfile,setSupplierBillfile]=useState(null)

  const [Amountstate,setAmountstate]=useState({
    TaxableTotal:'',
    TaxTotal:'',
    TotalAmount:'',
    TotalDiscountMethod:'',
    TotalDiscountType:'',
    TotalDiscount:'',
    FinalTaxableTotal:'',
    FinalTaxTotal:'',
    FinalTotalAmount:'',
    RoundOff:'',
    NetAmount:'',
  })


const [ItemFulldetailes,setItemFulldetailes]=useState({}) 


const [GRNItemState,setGRNItemState]=useState({  
  GRNMRP:'',  
  PurchaseRateTaxable:'',   
  TaxType:'',
  TaxPercentage:'',
  PurchaseRateWithTax:'',
  OrderQty:'',
  BillQty:'',
  ReceivedQty:'',  
  PendingQty:'',   
  TotalSellableQty:'',
  SellableQtyMRP:'',
  TotalPackTaxableAmount:'',
  TotalTaxAmount:'',
  TotalPackAmount:'',
  BatchNo:'',
  ManufactureDate:'',
  ExpiryDate:'',
  DiscountMethod:'',
  DiscountType:'',
  Discount:'',
  FinalTotalPackTaxableAmount:'',
  FinalTotalTaxAmount:'',
  FinalTotalPackAmount:'',
})




const formatLabel = (label) => {

  if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, (str) => str.toUpperCase());
  } else {
      return label;
  }
};



const getInputType = (name) => {
  if(['GRNDate','SupplierBillDate','SupplierBillDueDate'].includes(name)) return 'date';
  if (['SupplierBillAmount'].includes(name)) return 'number';
  return 'text';
  };

// -------------------------useEffect LOcation datas

useEffect(()=>{
  axios.get(`${UrlLink}Masters/Location_Detials_link`)
        .then((res) => {
            const ress = res.data
            setLocationData(ress)
        })
        .catch((err) => {
            console.log(err);
        })


    
},[UrlLink])


useEffect(()=>{

  if(GRNState.GRNLocation !==''){
    axios.get(`${UrlLink}Masters/Inventory_Master_Detials_link?SearchLocation=${GRNState.GRNLocation}`)
    .then((res) => {
        const ress = res.data
        // console.log('vvvv',res.data);
        
        setStoreData(ress)
    })
    .catch((err) => {
        console.log(err);
    })
   }

},[GRNState.GRNLocation])




// ----------------------------PO Item Data Grit in useEffect



const handleRadioChange = (e, key) => {
  setSelectedKey(key); 
};


const HandelEditdata =(row)=>{

  console.log('1111',row);

  const { id, 
     ItemStatus,
     ProductCategory,
     SubCategory, 
     Reason , 
     ItemProductCategory,
     ItemSubCategory,
     ReceivedQty,
     BalanceQty,
     ...rest} = row ;

  setItemFulldetailes({
    ProductCategory:ItemProductCategory,
    SubCategory:ItemSubCategory,
    ...rest
  })


  setGRNItemState((prev) => ({
    ...prev,
    GRNMRP: rest.MRP,
    PurchaseRateTaxable: rest.PurchaseRateBeforeGST,
    TaxPercentage: rest.GST,
    PurchaseRateWithTax: rest.PurchaseRateAfterGST,
    OrderQty: rest.PurchaseQty,
    BillQty: rest.PurchaseQty,
  }));



  

}


useEffect(() => {
  if (Object.keys(POItemArrays).length !== 0) {
      let columns = {};

      Object.keys(POItemArrays).forEach((keys) => {
          const ddd = POItemArrays[keys][0];
          const coll = [
              ...Object.keys(ddd ?? {}).filter(f => !['ProductCategory', 'SubCategory'].includes(f)).map((field) => {
                  return {
                      key: field,  
                      name: field === 'id' ? 'S.No' : field,  
                  };
              }),
              {
                  key: 'Action',
                  name: 'Action',
                  renderCell: (params) => (
                      <>
                      <Button className="cell_btn" 
                      onClick={() => HandelEditdata(params.row)}
                      >
                          <EditIcon className="check_box_clrr_cancell" />
                      </Button>
                      <Button className="cell_btn" 
                      // onClick={() => handleDeleteItem(params.row)}
                      >
                      <DeleteOutlineIcon className="check_box_clrr_cancell" />
                      </Button>
                      </>
                  )
              }
          ];

          columns[keys] = coll;
      });

      setProductListColumn(columns);
  }
}, [POItemArrays]);




// -------------------File Onchange and viwe



const handlefileOnchange =(e)=>{

  const { name, value, files } = e.target


  if (files && files.length > 0) {
    let formattedValue = files[0];

    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    const maxSize = 5 * 1024 * 1024; // Example max size of 5MB
    if (!allowedTypes.includes(formattedValue.type) || formattedValue.type === '') {
  
        const tdata = {
            message: 'Invalid file type. Please upload a PDF, JPEG, or PNG file.',
            type: 'warn'
        };
        dispatchvalue({ type: 'toast', value: tdata });

    } else {
        if (formattedValue.size > maxSize) {
            const tdata = {
                message: 'File size exceeds the limit of 5MB.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });

        } else {
            const reader = new FileReader();
            reader.onload = () => {
              setSupplierBillfile(reader.result)
               
            };
            reader.readAsDataURL(formattedValue);




        }
    }

} else {
    const tdata = {
        message: 'No file selected. Please choose a file to upload.',
        type: 'warn'
    };
    dispatchvalue({ type: 'toast', value: tdata });
}

}


const Selectedfileview = (fileval) => {
  // console.log('fileval',fileval);
  
  if (fileval) {
      let tdata = {
          Isopen: false,
          content: null,
          type: 'image/jpg'
      };
      if (['data:image/jpeg;base64', 'data:image/jpg;base64'].includes(fileval?.split(',')[0])) {
          tdata = {
              Isopen: true,
              content: fileval,
              type: 'image/jpeg'
          };
      } else if (fileval?.split(',')[0] === 'data:image/png;base64') {
          tdata = {
              Isopen: true,
              content: fileval,
              type: 'image/png'
          };
      } else if (fileval?.split(',')[0] === 'data:application/pdf;base64') {
          tdata = {
              Isopen: true,
              content: fileval,
              type: 'application/pdf'
          };
      }

      dispatchvalue({ type: 'modelcon', value: tdata });
  } else {
      const tdata = {
          message: 'There is no file to view.',
          type: 'warn'
      };
      dispatchvalue({ type: 'toast', value: tdata });
  }
}








// -----------------------GRN Head Detailes



useEffect(()=>{

  if(POdata && Object.keys(POdata).length !==0 && Object.keys(Editdata).length === 0)
  {

    // console.log('POdata----',POdata);
    

    axios.get(`${UrlLink}Inventory/Supplier_Master_Link?SupplierId=${POdata.SupplierId}`)
    .then((res)=>{
        // console.log('pppp',res.data);
        let Objdata=res.data

        if(Objdata && Object.keys(Objdata).length > 3)
        {
          setSingleSupplier(Objdata)
        }

        if (Objdata){

          const paymentTermsDays = Objdata.PaymentTerms;  
          const supplierBillDate = new Date(GRNState.GRNDate);
          const dueDate = new Date(supplierBillDate);
          dueDate.setDate(supplierBillDate.getDate() + paymentTermsDays);

          const formattedDueDate = dueDate.toISOString().split('T')[0];

          setGRNState((prev)=>({
            ...prev,
            PONumber:POdata.id,
            SupplierCode:POdata.SupplierId,
            SupplierName:POdata.SupplierName,     
            SupplierBillDueDate: formattedDueDate,
            SupplierBillLocation:POdata.BillingLocation,   
            GRNLocation:POdata.ShippingLocation,
          }))
        }

        if(Object.keys(POdata.Item_Details).length !==0){
          setPOItemArrays(POdata.Item_Details)
        }

    })
    .catch((err)=>{
        console.log(err);
        
    })

    

   

  }

},[POdata,Editdata])




const HandeleOnchange=(e)=>{

  const{name,value}=e.target

  if(name === 'SupplierBillNumber'){
    setGRNState((prev)=>({
      ...prev,
      [name]:value.toUpperCase(),
    }))
  }
  else if(name === 'SupplierBillDate') {

    setGRNState((prev) => ({
        ...prev,
        [name]: value,
    }));
  }
  else
  {
    setGRNState((prev)=>({
      ...prev,
      [name]:value,
    }))

  }

}



// ------------------------Item Entry Detailes




const HandelItemstate = (e) => {
  
  const { name, value, type, checked } = e.target;

  if (name === 'ReceivedQty') {
   
    if (+GRNItemState.BillQty < +value) {
      dispatchvalue({
        type: 'toast',
        value: { message: 'Received quantity cannot exceed the ordered quantity.', type: 'warn' },
      });
      return;
    }

    const PenQty = +GRNItemState.BillQty - +value;
    
    const TotalQty = GRNItemState.SellablePack === GRNItemState.PurchasePack
      ? +value
      : +GRNItemState.PackingQty * +value;

    const TotalPackTaxableAmount = GRNItemState.PurchaseRatePerPackTaxable * +value;
    const TaxAmount = TotalPackTaxableAmount * (GRNItemState.TaxPercentage / 100);
    const TotalPackAmount = GRNItemState.PurchaseRatePerPack * +value;

    setGRNItemState((prev) => ({
      ...prev,
      [name]: value,
      PendingQty: value ? PenQty : '',
      TotalSellableQty: value ? TotalQty : '',
      TotalPackTaxableAmount: value ? TotalPackTaxableAmount.toFixed(2) : '',
      TotalTaxAmount: value ? TaxAmount.toFixed(2) : '',
      TotalPackAmount: value ? TotalPackAmount.toFixed(2) : '',
      FinalTotalPackTaxableAmount:value ? TotalPackTaxableAmount.toFixed(2) : '',
      FinalTotalTaxAmount:value ? TaxAmount.toFixed(2) : '',
      FinalTotalPackAmount:value ? TotalPackAmount.toFixed(2) : '',
    }));

    return;
  }

  if (name === 'PackMRP') {
    const SellableQtyMRP = GRNItemState.SellablePack === GRNItemState.PurchasePack
      ? +value || ''
      : value ? (+value / +GRNItemState.PackingQty).toFixed(2) : '';

    setGRNItemState((prev) => ({
      ...prev,
      [name]: value,
      SellableQtyMRP,
    }));

    return;
  }

  if (name === 'PurchaseRatePerPackTaxable') {
    setGRNItemState((prev) => ({
      ...prev,
      [name]: value,
      TaxType: '',
      TaxPercentage: '',
      PurchaseRatePerPack: '',
      TotalPackTaxableAmount: '',
      TotalTaxAmount: '',
      TotalPackAmount: '',
      ReceivedQty: '',  
      PendingQty: '',  
    }));

    return;
  }

  if (name === 'TaxPercentage') {
    const TaxAmount = +GRNItemState.PurchaseRatePerPackTaxable * (value / 100);
    const TotalPackAmount = +GRNItemState.PurchaseRatePerPackTaxable + TaxAmount;

    setGRNItemState((prev) => ({
      ...prev,
      [name]: value,
      PurchaseRatePerPack: TotalPackAmount.toFixed(2),
      TotalPackTaxableAmount: '',
      TotalTaxAmount: '',
      TotalPackAmount: '',
      ReceivedQty: '',  
      PendingQty: '',  
    }));

    return;
  }

  if (name === 'BatchNo') {

    setGRNItemState((prev) => ({
      ...prev,
      [name]: value.toUpperCase(),
    }));

    return;
  }

  if (name === 'DiscountMethod') {

    setGRNItemState((prev) => ({
      ...prev,
      [name]: value,
      DiscountType:'',
      Discount:'',
      FinalTotalPackTaxableAmount: +GRNItemState.TotalPackTaxableAmount || '',
      FinalTotalTaxAmount: +GRNItemState.TotalTaxAmount || '',
      FinalTotalPackAmount:+GRNItemState.TotalPackAmount || '',
    }));


  return;
  }

  if (name === 'DiscountType') {

    setGRNItemState((prev) => ({
      ...prev,
      [name]: value,
      Discount:'',
      FinalTotalPackTaxableAmount: +GRNItemState.TotalPackTaxableAmount || '',
      FinalTotalTaxAmount: +GRNItemState.TotalTaxAmount || '',
      FinalTotalPackAmount: +GRNItemState.TotalPackAmount || '',
    }));


  return;
  }



 if (name === 'Discount') {

  
    if (GRNItemState.DiscountMethod === 'BeforeTax') {
      let TotalPackAm = +GRNItemState.TotalPackTaxableAmount;

      if (GRNItemState.DiscountType === 'Cash') {
        TotalPackAm -= +value;
      } else if (GRNItemState.DiscountType === 'Percentage') {
        TotalPackAm -= TotalPackAm * (+value / 100);
      }

      const TaxAmount = TotalPackAm * (GRNItemState.TaxPercentage / 100);
      const TotalPackAmount = TotalPackAm + TaxAmount;

      setGRNItemState((prev) => ({
        ...prev,
        [name]: value,
        FinalTotalPackTaxableAmount: TotalPackAm.toFixed(2) || '',
        FinalTotalTaxAmount: TaxAmount.toFixed(2) || '',
        FinalTotalPackAmount: TotalPackAmount.toFixed(2) || '',
      }));
    }
    else if(GRNItemState.DiscountMethod === 'AfterTax'){

      let TotalPackAm = +GRNItemState.TotalPackAmount 

      if (GRNItemState.DiscountType === 'Cash') {
        TotalPackAm -= +value;
      } else if (GRNItemState.DiscountType === 'Percentage') {
        TotalPackAm -= TotalPackAm * (+value / 100);
      }

      setGRNItemState((prev) => ({
        ...prev,
        [name]: value,
        FinalTotalPackTaxableAmount: +GRNItemState.TotalPackTaxableAmount || '',
        FinalTotalTaxAmount: +GRNItemState.TotalTaxAmount || '',
        FinalTotalPackAmount: TotalPackAm.toFixed(2) || '',
      }));

    }
  
  return;
  } 
  
  setGRNItemState((prev) => ({
    ...prev,
    [name]: type === 'checkbox' ? checked : value,
  }));
  
};










  return (
    <>
    <div className="Main_container_app">
        <h3>Goods Receipt Note</h3>

        <br/>

        <div className="RegisFormcon_1">

          {
            Object.keys(GRNState).map((StateName,Index)=>{
              return(
                <div className="RegisForm_1" key={Index+'key'}>

                  <label htmlFor={StateName}>{formatLabel(StateName)} <sapn>:</sapn></label>


                  {
                   ['GRNLocation','SupplierBillLocation','StorageLocation'].includes(StateName) ?

                  <select
                    type={getInputType(StateName)}
                    id={StateName}
                    name={StateName}
                    value={GRNState[StateName]}
                    onChange={HandeleOnchange}
                    disabled={['SupplierBillLocation','GRNLocation'].includes(StateName)}
                  >
                  <option value=''>Select</option>
                  {
                  StateName === 'StorageLocation' ?  
                  StoreData.map((ele,ind)=>(
                      <option key={ind+'key'} value={ele.id} >{ele.StoreName}</option>
                    ))
                    :
                    LocationData.map((ele,ind)=>(
                      <option key={ind+'key'} value={ele.id} >{ele.locationName}</option>
                    ))
                  }
                  </select>
                  :
                  <input
                    type={getInputType(StateName)}
                    id={StateName}
                    name={StateName}
                    value={GRNState[StateName]}
                    onChange={HandeleOnchange}
                    max={StateName === 'SupplierBillDate' ? currentDate:'' }
                    disabled={['SupplierBillDueDate', 'GRNDate', 'PONumber', 'SupplierCode', 'SupplierName'].includes(StateName)} 
                  />
                  }
                </div>

              )})
          }

        <>
        <div className="RegisForm_1">
        <label htmlFor='SupplierBillfile'>File Attachment :</label>
          <input
              type='file'
              name='SupplierBillfile'
              accept='image/jpeg,image/png,application/pdf'
              required
              id='SupplierBillfile'
              autoComplete='off'
              onChange={handlefileOnchange}
              style={{ display: 'none' }}
          />
          <div style={{ width: '150px', display: 'flex', justifyContent: 'space-around' }}>
              <label
                  htmlFor='SupplierBillfile'
                  className="RegisterForm_1_btns choose_file_update"
              >
                  Choose File
              </label>
              <button className='fileviewbtn' 
              onClick={() => Selectedfileview(SupplierBillfile)}
              >view</button>

            </div>
          </div>
        </>
        </div>
        
        <br/>
        <div className="common_center_tag">
          <span>PO ITEMS LIST</span>
        </div>
        <br/>
        <div className="RegisterTypecon">
        <div className="RegisterType">

        <div className="registertypeval">
            <input 
                id="All"
                type="radio" 
                name="product-options" 
                value="All" 
                checked={selectedKey === "All"} 
                onChange={(e) => handleRadioChange(e, "All")} 
            />
            <label htmlFor="All">All Categories</label>
        </div>
        {Object.keys(POItemArrays).map((keys, indx) => (
              POItemArrays[keys].length !== 0 && (
                  <div div className="registertypeval" key={indx}>
                      <input 
                          id={keys}
                          type="radio" 
                          name="product-options" 
                          value={keys} 
                          checked={selectedKey === keys} 
                          onChange={(e) => handleRadioChange(e, keys)} 
                      />
                       <label htmlFor={keys}>
                          {keys}
                      </label>
                  </div>
              )
          ))}
        </div>
        </div>

        <br/>
        {selectedKey === "All" ? (  
          Object.keys(POItemArrays).map((keys, indx) => (
            POItemArrays[keys].length !== 0 && (
                <React.Fragment key={indx}>
                    <div className="common_center_tag">
                    <span>  {keys}</span>
                    </div>
                    <ReactGrid 
                        columns={ProductListColumn[keys]} 
                        RowData={POItemArrays[keys]} 
                    />
                     <br />
                </React.Fragment>
            )
        ))
        ) :
        Object.keys(POItemArrays).map((keys, indx) => (
                  POItemArrays[keys].length !== 0 && (
                  <React.Fragment key={indx}>
                      {selectedKey === keys && (
                          <ReactGrid 
                              columns={ProductListColumn[keys]} 
                              RowData={POItemArrays[keys]} 
                          />
                      )}
                  </React.Fragment>
              )
          ))}   

          <br/>
          <div className="common_center_tag">
           <span>PO ITEM DETAILES</span>
          </div> 

          {/* ItemFulldetailes */}
          <br/>
          <div className="RegisFormcon_1">

            {
              Object.keys(ItemFulldetailes).length !==0 && Object.keys(ItemFulldetailes).map((StateName,Index)=>{
                return(
                  <div className="RegisForm_1" key={Index+'key'}>
                    <label htmlFor={StateName}>{formatLabel(StateName)} <sapn>:</sapn></label>
                    <input 
                    type={getInputType(StateName)}
                    id={StateName}
                    name={StateName}
                    value={ItemFulldetailes[StateName]}
                    disabled
                    />
                  </div>
                )
              })
            }

          </div>
          <br/>
          <div className="common_center_tag">
           <span>GRN ENTRY DETAILES</span>
          </div> 
          <br/>
          <div className="RegisFormcon_1">

            {
              Object.keys(ItemFulldetailes).length !==0 && Object.keys(GRNItemState).map((StateName,Index)=>{
                return(
                  <div className="RegisForm_1" key={Index+'key'}>
                    <label htmlFor={StateName}>{formatLabel(StateName)} <sapn>:</sapn></label>
                    
                    {['TaxType','TaxPercentage','DiscountMethod','DiscountType'].includes(StateName)?

                    <select
                    type={getInputType(StateName)}
                    id={StateName}
                    name={StateName}
                    value={GRNItemState[StateName]} 
                    onChange={HandelItemstate}   
                    // disabled={(GRNItemState.DiscountMethod === '' && StateName === 'DiscountType') ||
                    // (GRNItemState.TotalPackTaxableAmount === '' && StateName === 'DiscountMethod') || (Amountstate.TotalDiscountMethod !== "" && StateName === 'DiscountMethod')}
                    
                    >

                    <option value=''>Select</option>
                    { StateName === 'TaxType' ?
                    <>
                    <option value='GST'>GST</option>
                    <option value='IGST'>IGST</option>
                    </>
                    :

                    StateName === 'TaxPercentage' ?
                    <>
                    <option value='Nill'>Nill</option>
                    <option value='5'>5%</option>
                    <option value='12'>12%</option>
                    <option value='18'>18%</option>
                    <option value='28'>28%</option>
                    </>
                    :
                    StateName === 'DiscountMethod' ?
                    <>
                    <option value='BeforeTax'>Before Tax</option>
                    <option value='AfterTax'>After Tax</option>
                    </>
                    :
                    <>
                    <option value='Cash'>Cash</option>
                    <option value='Percentage'>Percentage</option>
                    </>
                    }
                    </select>

                    :
                    
                    <input 
                    type={getInputType(StateName)}
                    id={StateName}
                    name={StateName}
                    value={GRNItemState[StateName]}
                    onChange={HandelItemstate}   
                    />
                    }
                  </div>
                
                )
              })
            }

          </div>
                

        </div>
      
        
        


      <ToastAlert Message={toast.message} Type={toast.type} />
      <ModelContainer />

    </>
  )
}

export default GoodsReceiptNote;