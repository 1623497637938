
const NumberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
        return "Zero Rupees Only";
    }

    const units = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
    ];
    const teens = [
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
    ];
    const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
    ];

    const convert = (num) => {
        if (num <= 10 && num !== 0) return units[num];
        if (num < 20) return teens[num - 11];
        if (num < 100)
            return (
                tens[Math.floor(num / 10)] +
                (num % 10 !== 0 ? " " + units[num % 10] : "")
            );
        if (num < 1000)
            return (
                units[Math.floor(num / 100)] +
                " Hundred" +
                (num % 100 !== 0 ? " and " + convert(num % 100) : "")
            );
        if (num < 100000)
            return (
                convert(Math.floor(num / 1000)) +
                " Thousand" +
                (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
            );
        if (num < 10000000)
            return (
                convert(Math.floor(num / 100000)) +
                " Lakh" +
                (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
            );
    };

    return convert(num) + " Rupees  Only";
};





const BlockInvalidcharecternumber = (e) => {
    if (['e', 'E', '+', '-', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.preventDefault()
    }
}



const formatLabel = (label) => {

    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
        return label
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/^./, (str) => str.toUpperCase());
    } else {
        return label;
    }
};
const formatunderscoreLabel = (label) => {

    return label
        .replace(/_/g, ' ')            // Replace underscores with spaces
        .replace(/\b\w/g, (str) => str.toUpperCase()); // Capitalize first letter of each word

};


// Export both functions together
export { NumberToWords, BlockInvalidcharecternumber, formatLabel,formatunderscoreLabel };





